import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../../services/globals';
import { Utility } from '../../../services/utility';
import { Api } from '../../../services/api';



@Component({
    selector: 'custom-text-editor',
    templateUrl: './custom-text-editor.component.html',
    styleUrls: ['./custom-text-editor.component.scss']
})

export class CustomTextEditorComponent implements OnInit {
    @Input()
    public customText: any = null;
    @Input()
        tag: string;
    @Input()
        inputType: 'TEXT' | 'MULTILINE' | 'WYSIWYG';

    @Output()
        contentChanged = new EventEmitter();
    @Output()
        contentLoaded = new EventEmitter();

    saveTimeout = null;
    saving: boolean = false;
    modified: boolean = false;
    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility) {

    }

    async onChange(text) {
        this.modified = true;
        if (this.saveTimeout)
            clearTimeout(this.saveTimeout);
        this.saveTimeout = setTimeout(async () => {
            if (!this.saving) {
                this.saving = true;
                await this.api.client().patch<any>(`/manage/custom-texts`, this.customText);
                this.saving = false;
                this.modified = false;
                this.saveTimeout = null;
            }
        }, 2000);
        this.contentChanged.emit(text);
    }

    async setType(type: string) {
        this.customText.type = type;
        this.updateInputType();
        await this.api.client().patch<any>(`/manage/custom-texts`, this.customText);
    }

    updateInputType() {
        switch (this.customText.type) {
        case 'WYSIWYG':
            this.inputType = 'WYSIWYG';
            break;
        case 'PLAIN':
            this.inputType = 'MULTILINE';
            break;
        }
    }

    async ngOnInit() {
        if (this.tag)
            this.customText = await this.api.client().get<any>(`/manage/custom-texts/by-tag/${this.tag}`);
        else {
            if (!this.customText.type)
                this.customText.type = 'WYSIWYG';
            this.updateInputType();
        }
        this.contentLoaded.emit(this.customText);
    }

}
