import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Globals } from 'projects/client-app/src/app/services/globals';
import { Utility } from 'projects/client-app/src/app/services/utility';
import { Currency } from 'projects/client-app/src/app/services/currency';
import { Api } from 'projects/client-app/src/app/services/api';
import { ChartDataset, ChartOptions, LegendOptions, ChartType, TooltipItem, ChartTypeRegistry } from 'chart.js';
import { colorFromSequence } from 'projects/client-app/src/app/helpers/utility';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';


@Component({
    selector: 'sales-report-chart',
    templateUrl: './sales-report-chart.component.html',
    styleUrls: ['./sales-report-chart.component.scss']
})

export class SalesReportChartComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
      startDate: Date = moment().subtract(1, 'month').toDate();
  @Input()
      endDate: Date = moment().toDate();
  @Input()
      searchMode: string;
  bookingData: any;
  resourceBookingData: any;
  giftCardData: any;
  groupGraphData = true;
  include = {
      packages: true,
      extraActivities: true,
      addOns: true,
      promoCodes: true,
      giftCards: true,
      resourceBookings: true
  };
  loading: boolean = false;

  graphData: {
    datasets: ChartDataset[],
    labels: string[],
    options: ChartOptions,
    legend?: any,
    chartType: ChartType
  };


  constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility, public currency: Currency, private translate: TranslateService) {

      this.graphData = {
          chartType: 'line',
          datasets: [],
          labels: [],
          options: {
              elements: {
                  line: {
                      //tension: 0
                  }
              },
              responsive: true,
              plugins: {
                  tooltip: {
                      mode: 'index',
                      callbacks: {
                          label: (item) => {
                              var label = item.dataset.label || '';
                              if (label) {
                                  label += ': ';
                              }
                              label += this.currency.format(item.parsed.y);
                              return label;
                          }
                      }
                  },
              },
              hover: {
                  mode: 'index'
              },
              scales: {
                  x: {
                      stacked: true,
                      ticks: {
                          autoSkip: true,
                          maxTicksLimit: 10
                      }
                  },
                  y: {
                      stacked: true
                  }
              }

          }
      };
  }

  updateGraphData() {

      this.graphData.labels = [];

      this.graphData.datasets = [];

      let addData = (name, data) => {
          let color = colorFromSequence(this.graphData.datasets.length);
          this.graphData.datasets.push({
              label: name,
              borderColor: color,
              //borderWidth: 0,
              backgroundColor: color,
              pointBackgroundColor: color,
              data: data
          });
      };

      let bookingDates: any[] = Object.values(this.bookingData.packages);
      let resourceBookingDates: any = this.resourceBookingData.summary.dates;
      let dates: any = {};
      if (bookingDates.length) {
          dates = Object.assign(dates, bookingDates[0].dates);
      }
      if (Object.keys(resourceBookingDates).length) {
          dates = Object.assign(dates, resourceBookingDates);
          console.log('Dates:');
          console.log(dates);
      }
      this.graphData.labels = Object.keys(dates);

      if (this.groupGraphData) {

          if (this.include.packages) {
              let packageData: { [date: string]: any };
              for (let packageId in this.bookingData.packages) {
                  let _package = this.bookingData.packages[packageId];

                  if (!packageData)
                      packageData = cloneDeep(_package.dates);
                  else {
                      for (let date in _package.dates) {
                          packageData[date].total.price.withVat += _package.dates[date].total.price.withVat;
                      }
                  }
              }
              if (packageData)
                  addData(this.translate.instant('PACKAGES'), Object.values(packageData).map((data: any) => data.total.price.withVat));
          }

          if (this.include.addOns) {
              let addOnData: { [date: string]: any };
              for (let addOnId in this.bookingData.addOns) {
                  let addOn = this.bookingData.addOns[addOnId];
                  if (!this.graphData.labels.length)
                      this.graphData.labels = Object.keys(addOn.dates);

                  if (!addOnData)
                      addOnData = cloneDeep(addOn.dates);
                  else {
                      for (let date in addOn.dates) {
                          addOnData[date].total.price.withVat += addOn.dates[date].total.price.withVat;
                      }
                  }
              }
              if (addOnData)
                  addData(this.translate.instant('ADD_ONS'), Object.values(addOnData).map((data: any) => data.total.price.withVat));
          }


          if (this.include.extraActivities) {
              let extraActivityData: { [date: string]: any };
              for (let extraActivityId in this.bookingData.extraActivities) {
                  let extraActivity = this.bookingData.extraActivities[extraActivityId];
                  if (!this.graphData.labels.length)
                      this.graphData.labels = Object.keys(extraActivity.dates);

                  if (!extraActivityData)
                      extraActivityData = cloneDeep(extraActivity.dates);
                  else {
                      for (let date in extraActivity.dates) {
                          extraActivityData[date].total.price.withVat += extraActivity.dates[date].total.price.withVat;
                      }
                  }
              }
              if (extraActivityData)
                  addData(this.translate.instant('EXTRA_ACTIVITIES'), Object.values(extraActivityData).map((data: any) => data.total.price.withVat));
          }

          if (this.include.giftCards) {
              let giftCardData: { [date: string]: any };
              for (let giftCardTemplateId in this.giftCardData) {
                  let giftCardTemplate = this.giftCardData[giftCardTemplateId];
                  if (!this.graphData.labels.length)
                      this.graphData.labels = Object.keys(giftCardTemplate.dates);

                  if (!giftCardData)
                      giftCardData = cloneDeep(giftCardTemplate.dates);
                  else {
                      for (let date in giftCardTemplate.dates) {
                          giftCardData[date].total.withVat += giftCardTemplate.dates[date].total.withVat;
                      }
                  }
              }
              if (giftCardData)
                  addData(this.translate.instant('GIFT_CARDS'), Object.values(giftCardData).map((data: any) => data.total.withVat));
          }
      }
      else {
          if (this.include.packages) {
              for (let packageId in this.bookingData.packages) {
                  let _package = this.bookingData.packages[packageId];
                  addData(_package.name, Object.values(_package.dates).map((data: any) => data.total.price.withVat));
              }
          }

          if (this.include.addOns) {
              for (let addOnId in this.bookingData.addOns) {
                  let addOn = this.bookingData.addOns[addOnId];
                  addData(addOn.name, Object.values(addOn.dates).map((data: any) => data.total.price.withVat));
              }
          }


          if (this.include.extraActivities) {
              for (let extraActivityId in this.bookingData.extraActivities) {
                  let extraActivity = this.bookingData.extraActivities[extraActivityId];
                  addData(extraActivity.name, Object.values(extraActivity.dates).map((data: any) => data.total.price.withVat));
              }
          }

          if (this.include.giftCards) {
              for (let giftCardTemplateId in this.giftCardData) {
                  let giftCardTemplate = this.giftCardData[giftCardTemplateId];
                  addData(giftCardTemplate.name, Object.values(giftCardTemplate.dates).map((data: any) => data.total.withVat));
              }
          }
      }

      if (this.include.resourceBookings) {
          addData(this.translate.instant('RESOURCE_BOOKINGS'), Object.values(this.resourceBookingData.summary.dates).map((data: any) => data.total.price.withVat));
      }

      if (this.include.promoCodes) {
          addData(this.translate.instant('PROMO_CODES'), Object.values(this.bookingData.promoCodes.dates).map((data: any) => data.total.price.withVat));
      }


  }


  async fetchBookingData() {
      this.bookingData = await this.api.client().get<any>(`/reports/sales/bookings/graph-data?startDate=${moment(this.startDate).startOf('day').toISOString()}&endDate=${moment(this.endDate).endOf('day').toISOString()}&searchMode=${this.searchMode}`);
  }

  async fetchGiftCardData() {
      this.giftCardData = await this.api.client().get<any>(`/reports/sales/gift-cards/graph-data?startDate=${moment(this.startDate).startOf('day').toISOString()}&endDate=${moment(this.endDate).endOf('day').toISOString()}`);
  }

  async fetchResourceBookingData() {
      this.resourceBookingData = await this.api.client().get<any>(`/reports/sales/resource-bookings/graph-data?startDate=${moment(this.startDate).startOf('day').toISOString()}&endDate=${moment(this.endDate).endOf('day').toISOString()}&searchMode=${this.searchMode}`);
  }


  async ngOnInit() {
      // this.fetchBookingData();
      await this.reload();
  }

  async reload() {
      if (!this.loading) {
          this.loading = true;
          await this.fetchBookingData();
          await this.fetchGiftCardData();
          await this.fetchResourceBookingData();
          this.updateGraphData();
          this.loading = false;
      }
  }

  async ngOnChanges(changes: SimpleChanges) {
      // if (!this.loading) {
      //     this.loading = true;
      //     await this.fetchBookingData();
      //     await this.fetchGiftCardData();
      //     await this.fetchResourceBookingData();
      //     this.updateGraphData();
      //     this.loading = false;
      // }
  }

  async ngOnDestroy() {

  }
}
