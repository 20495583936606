import { Injectable } from '@angular/core';
import { IPackage, IPriceSettings } from '../interfaces/package';
import { TranslateService } from '@ngx-translate/core';
import { v4 } from 'uuid';
import { IBooking } from 'src/app/interfaces/booking';
import { IResourceBooking } from 'src/app/interfaces/resource-booking';
import { IEvent } from '../../../../../../../../common/common-interfaces/event';
import { IEventBooking } from '../../../../../../../../common/common-interfaces/event-booking';
import { colors } from '../../../../../../../../common/common-helpers/utility';
import { IBookingBase } from '../../../../../src/app/interfaces/booking-base';
import * as moment from 'moment';
import { PaymentProviderName } from '../../../../../../../../common/common-interfaces/payment-info';



export type SeasonalTheme = 'NEW_YEAR' | 'EASTER' | 'CHRISTMAS' | 'MIDSUMMER' | 'HALLOWEEN' | 'VALENTINES' | 'APRIL_FOOLS';

@Injectable()
export class Utility {
    constructor(private translate: TranslateService) {

    }

    public randomColor() {
        return colors[Math.floor(colors.length * Math.random())];
    }

    public getTotalPriceFromPriceSettings(priceSetting: IPriceSettings) {
        return priceSetting.price.withVat + (priceSetting.extraOrderRows ? priceSetting.extraOrderRows.reduce((sum, row) => sum + row.price.withVat, 0) : 0);
    }

    public padded(number: number) {
        if (number == 0)
            return '00';
        return (number < 10) ? `0${number}` : `${number}`;
    }

    public uuid() {
        return v4();
    }

    public getBookingIconsHtml(data) {
        let booking: IBooking = data.booking;
        let resourceBooking: IResourceBooking = data.resourceBooking;
        let eventBooking: IResourceBooking = data.eventBooking;
        let bookingBase: IBookingBase;
        if (booking)
            bookingBase = booking;
        else if (eventBooking)
            bookingBase = eventBooking;
        else if (resourceBooking)
            bookingBase = resourceBooking;

        let event: IEvent = data.event;
        let _package = data.package;
        let allocation = data.allocation;
        let fa = (icon: string, color: string, title: string) => `<i class="fa fa-${icon} ${color}" title="${title}"></i>`;
        let fas = (icon: string, color: string, title: string) => `<i class="fas fa-${icon} ${color}" title="${title}"></i>`;
        let mat = (icon: string, color: string, title: string) => `<i class="mat-icon material-icons ${color}" title="${title}">${icon}</i>`;
        let custom = (icon: string, color: string, title: string) => `<i class="custom-icon ${icon} ${color}" title="${title}"></i>`
        let text = text => this.translate.instant(text);
        let html = '';

        // REGULAR BOOKING
        if (booking) {
            // Has arrived
            html += fas('users', booking.hasArrived ? 'green' : 'red', booking.hasArrived ? text('HAS_ARRIVED') : text('HAS_NOT_ARRIVED'));

            // Number of persons
            if (allocation) {
                if (!booking.hasArrived && booking.metadata && booking.metadata.arrivedPersons) {
                    html += `<span class="persons">${booking.metadata.arrivedPersons}/${allocation.persons}</span>`;
                } else {
                    html += `<span class="persons">${allocation.persons}</span>`;
                }
            }

            // Is paid
            if (booking.isPaid) {
                // Fully paid and recurring accepted
                if (booking.simplifiedPaymentData && booking.simplifiedPaymentData.recurringPaymentAccepted) {
                    html += fas('money-bill-alt', 'green', text('HAS_PAID'));
                    html += mat('add_card', 'green recurring-payment-icon', text('RECURRING_PAYMENT_ACCEPTED'));
                }

                // Fully paid without recurring
                else {
                    html += fas('money-bill-alt', 'green', text('HAS_PAID'));
                }

            }
            // Not fully paid
            else {

                // Partly paid
                if (booking.simplifiedPaymentData && booking.simplifiedPaymentData.prepaidAmount > 0) {

                    // Partly paid and recurring accepted
                    if (booking.simplifiedPaymentData.recurringPaymentAccepted) {
                        html += fas('money-bill-alt', 'yellow', text('NOT_FULLY_PAID'));
                        html += mat('add_card', 'yellow recurring-payment-icon', text('RECURRING_PAYMENT_ACCEPTED'));
                    }

                    // Partly paid and recurring not accepted
                    else {
                        html += fas('money-bill-alt', 'yellow', text('NOT_FULLY_PAID'));
                    }

                }

                // No payment
                else {
                    html += fas('money-bill-alt', 'red', text('HAS_NOT_PAID'));
                }
            }

            // html += fas('money-bill-alt', booking.isPaid ? 'green' : 'red', booking.isPaid ? text('HAS_PAID') : text('HAS_NOT_PAID'));

            // Has add-ons
            if (_package && _package.addOns && _package.addOns.length)
                html += mat('fastfood', 'neutral', text('HAS_ADD_ONS'));

            // Has birthday info
            if (booking.birthdayInfo)
                html += mat('cake', 'neutral', `${text('BIRTHDAY_CHILD')}: ${booking.birthdayInfo.persons.map(p => p.name).join(', ')}`);

            // Has promo code
            if (booking.appliedPromoCodes && booking.appliedPromoCodes.length)
                html += mat('discount', 'neutral', `${text('PROMO_CODE')}: ${booking.appliedPromoCodes[0].code}`);

            // Has gift card
            if (booking.appliedGiftCards && booking.appliedGiftCards.length)
                html += mat('card_giftcard', 'neutral', `${text('GIFT_CARD')}: ${booking.appliedGiftCards[0].code}`);

            // Has both customer comment and staff comment
            if (booking.customer.extraInfo && booking.staffComment) {
                if (booking.staffComment)
                    html += fas('comments', 'neutral', `${text('STAFF_COMMENT')}: ${booking.staffComment} \r\n${text('CUSTOMER_COMMENT')}: ${booking.customer.extraInfo} `);
            }
            else {
                // Has customer comment
                if (booking.customer.extraInfo)
                    html += fas('comment', 'neutral', `${text('CUSTOMER_COMMENT')}: ${booking.customer.extraInfo}`);

                // Has staff comment
                if (booking.staffComment)
                    html += fas('comment-dots', 'neutral', `${text('STAFF_COMMENT')}: ${booking.staffComment}`);
            }

            // Prison Island customer edited
            if (booking.gameIntegrationData && booking.gameIntegrationData.prisonIslandData)
                html += custom('icon-prison-island', `neutral`, `Prison Island`);



        }
        // RESOURCE BOOKING
        else if (resourceBooking && !event) {
            // Has arrived
            html += fas('users', resourceBooking.hasArrived ? 'green' : 'red', resourceBooking.hasArrived ? text('HAS_ARRIVED') : text('HAS_NOT_ARRIVED'));

            // Persons
            html += `<span class="persons">${resourceBooking.persons}</span>`;

            // Is paid
            html += fas('money-bill-alt', resourceBooking.isPaid ? 'green' : 'red', resourceBooking.isPaid ? text('HAS_PAID') : text('HAS_NOT_PAID'));

            // Has staff comment
            if (resourceBooking.staffComment)
                html += fas('comment-dots', 'neutral', `${text('STAFF_COMMENT')}: ${resourceBooking.staffComment}`);

            // If allocation is locked
            if (allocation && allocation.isLocked)
                html += mat('lock', 'red', `${text('BLOCKED_TIMESLOT')}`);
        }
        // EVENT RESOURCE BOOKING
        else if (resourceBooking && event) {
            // Has arrived
            html += fas('users', 'neutral', '');

            // Persons
            html += `<span class="persons">${event.occupiedSlots ? event.occupiedSlots : 0}</span>`;

            // If allocation is locked
            if (allocation && allocation.isLocked)
                html += mat('lock', 'red', `${text('BLOCKED_TIMESLOT')}`);
        }

        if (bookingBase) {

            // Viking booking
            if (bookingBase.gameIntegrationData
                && bookingBase.gameIntegrationData.isVbsBooking
                && bookingBase.gameIntegrationData.vbsData
                && bookingBase.gameIntegrationData.vbsData.externalId) {
                if (bookingBase.gameIntegrationData.vbsData.allocationError) {
                    html += fas('bowling-ball', 'negative', `Viking booking ID: ${bookingBase.gameIntegrationData.vbsData.externalId}`);
                }
                else {
                    html += fas('bowling-ball', 'neutral', `Viking booking ID: ${bookingBase.gameIntegrationData.vbsData.externalId}`);
                }
            }

            // Bowlit booking
            if (bookingBase.gameIntegrationData
                && bookingBase.gameIntegrationData.isBowlitBooking
                && bookingBase.gameIntegrationData.bowlitData
                && bookingBase.gameIntegrationData.bowlitData.bowlitBooking
                && bookingBase.gameIntegrationData.bowlitData.bowlitBooking.BookId) {
                html += fas('bowling-ball', 'neutral', `Bowlit booking ID: ${bookingBase.gameIntegrationData.bowlitData.bowlitBooking.BookId}`);
            }
            else if (bookingBase.gameIntegrationData
                && bookingBase.gameIntegrationData.isBowlitBooking
                && bookingBase.gameIntegrationData.bowlitData) {
                html += fas('bowling-ball', 'negative', `Faulty Bowlit booking`);
            }

            // Social Gaming booking
            if (bookingBase.gameIntegrationData
                && bookingBase.gameIntegrationData.isSocialGamingBooking
                && bookingBase.gameIntegrationData.socialGamingData) {
                html += fas('trophy', 'neutral', `Social Gaming booking`);
            }
        }


        return html;
    }

    numberFormat(value: number) {
        if (typeof (value) == 'undefined' || value === null)
            return '-';
        let split = (value: string) => {
            let split = value.split(',');
            if (split.length == 3)
                return `${split[0]} ${split[1]}`;
            if (split.length == 4)
                return `${split[0]} ${split[1]} ${split[2]}`;
            if (split.length == 5)
                return `${split[0]} ${split[1]} ${split[2]} ${split[3]}`;
            else
                return split[0];
        };

        let formattedValue = value.toLocaleString('en', { minimumFractionDigits: 2 }).replace(',', ' ').replace('.', ',');
        return split(formattedValue);
    }

    copyTextToClipboard(text) {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text).then(function () {
                console.log('Copying to clipboard was successful!');
            }, function (err) {
                console.error('Could not copy text: ', err);
            });
        }

    }

    getSeasonalTheme(): SeasonalTheme {
        let week = moment().format('w');
        let date = moment().format('YYYY-MM-DD');
        let themeMappings: {
            weeks: { [week: string]: SeasonalTheme },
            dates: { [date: string]: SeasonalTheme }
        } = {
            weeks: {},
            dates: {}
        };

        let addDayInterval = (seasonalTheme: SeasonalTheme, year: number, month: number, startDay: number, numberOfDays: number) => {
            let startDate = moment(`${year}-${this.padded(month)}-${this.padded(startDay)}`);

            for (let i = 0; i < numberOfDays; i++) {
                let date = moment(startDate).add(i, 'days');
                themeMappings.dates[date.format('YYYY-MM-DD')] = seasonalTheme;
            }
        };

        addDayInterval('EASTER', 2023, 4, 5, 5);
        addDayInterval('EASTER', 2024, 3, 27, 5);
        addDayInterval('EASTER', 2025, 4, 17, 5);
        addDayInterval('EASTER', 2026, 4, 1, 5);
        addDayInterval('EASTER', 2027, 3, 24, 5);
        addDayInterval('EASTER', 2028, 4, 12, 5);
        addDayInterval('EASTER', 2029, 3, 28, 5);
        addDayInterval('EASTER', 2030, 4, 18, 5);

        addDayInterval('MIDSUMMER', 2023, 6, 22, 3);
        addDayInterval('MIDSUMMER', 2024, 6, 20, 3);
        addDayInterval('MIDSUMMER', 2025, 6, 19, 3);
        addDayInterval('MIDSUMMER', 2026, 6, 18, 3);
        addDayInterval('MIDSUMMER', 2027, 6, 24, 3);
        addDayInterval('MIDSUMMER', 2028, 6, 22, 3);
        addDayInterval('MIDSUMMER', 2029, 6, 21, 3);
        addDayInterval('MIDSUMMER', 2030, 6, 20, 3);

        addDayInterval('VALENTINES', 2023, 2, 14, 1);
        addDayInterval('VALENTINES', 2024, 2, 14, 1);
        addDayInterval('VALENTINES', 2025, 2, 14, 1);
        addDayInterval('VALENTINES', 2026, 2, 14, 1);
        addDayInterval('VALENTINES', 2027, 2, 14, 1);
        addDayInterval('VALENTINES', 2028, 2, 14, 1);
        addDayInterval('VALENTINES', 2029, 2, 14, 1);
        addDayInterval('VALENTINES', 2030, 2, 14, 1);


        addDayInterval('HALLOWEEN', 2023, 10, 30, 4);
        addDayInterval('HALLOWEEN', 2024, 10, 30, 4);
        addDayInterval('HALLOWEEN', 2025, 10, 30, 4);
        addDayInterval('HALLOWEEN', 2026, 10, 30, 4);
        addDayInterval('HALLOWEEN', 2027, 10, 30, 4);
        addDayInterval('HALLOWEEN', 2028, 10, 30, 4);
        addDayInterval('HALLOWEEN', 2029, 10, 30, 4);
        addDayInterval('HALLOWEEN', 2030, 10, 30, 4);


        addDayInterval('CHRISTMAS', 2023, 12, 18, 8);
        addDayInterval('CHRISTMAS', 2024, 12, 18, 8);
        addDayInterval('CHRISTMAS', 2025, 12, 18, 8);
        addDayInterval('CHRISTMAS', 2026, 12, 18, 8);
        addDayInterval('CHRISTMAS', 2027, 12, 18, 8);
        addDayInterval('CHRISTMAS', 2028, 12, 18, 8);
        addDayInterval('CHRISTMAS', 2029, 12, 18, 8);
        addDayInterval('CHRISTMAS', 2030, 12, 18, 8);

        addDayInterval('NEW_YEAR', 2023, 12, 30, 2);
        addDayInterval('NEW_YEAR', 2024, 12, 30, 2);
        addDayInterval('NEW_YEAR', 2025, 12, 30, 2);
        addDayInterval('NEW_YEAR', 2026, 12, 30, 2);
        addDayInterval('NEW_YEAR', 2027, 12, 30, 2);
        addDayInterval('NEW_YEAR', 2028, 12, 30, 2);
        addDayInterval('NEW_YEAR', 2029, 12, 30, 2);
        addDayInterval('NEW_YEAR', 2030, 12, 30, 2);



        if (moment().format('MMDD') == '0401')
            return 'APRIL_FOOLS';


        if (themeMappings.dates[date])
            return themeMappings.dates[date];
        if (themeMappings.weeks[week])
            return themeMappings.weeks[week];
    }


    getPackageDisplayName(_package: IPackage) {
        return _package.internalDescription && _package.internalDescription != '' ? `${_package.name} (${_package.internalDescription})` : _package.name;
    }


    getPaymentProviderName(provider: PaymentProviderName) {
        if (provider == 'billmate')
            return 'Qvickly';
        return provider;

    }

    formatDateByLocale(dateString: string | Date, countryCode: string, includeTime: boolean = false) {

        const countryToLocale: Record<string, string> = {
            "se": "sv-SE",
            "no": "nb-NO",
            "dk": "da-DK",
            "fi": "fi-FI",
            "fr": "fr-FR",
            "nl": "nl-NL",
            "de": "de-DE",
            "ch": "de-CH",
            "ae": "ar-AE",
            "es": "es-ES",
            "nz": "en-NZ",
            "be": "nl-BE",
            "gb": "en-GB",
            "eg": "ar-EG",
            "pl": "pl-PL",
            "sa": "ar-SA",
            "in": "en-IN",
            "ie": "en-IE",
            "cz": "cs-CZ",
            "pt": "pt-PT",
            "it": "it-IT",
            "at": "de-AT",
            "kr": "ko-KR",
            "th": "th-TH",
            "ma": "fr-MA",
            "ca": "en-CA"
        };

        const locale = countryToLocale[countryCode.toLowerCase()] || "sv-SE";

        const dateOptions: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        };

        if (includeTime) {
            dateOptions.hour = '2-digit';
            dateOptions.minute = '2-digit';
            dateOptions.hour12 = false;
        }

        const date = new Date(dateString)
        const dateFormatter = new Intl.DateTimeFormat(locale, dateOptions);

        return dateFormatter.format(date);
    }

}