// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.price-input .ex-vat {
  font-size: 10px;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/shared/price-input/price-input.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/shared/price-input/price-input.component.scss"],"names":[],"mappings":"AAOI;EAEI,eAAA;ACPR","sourcesContent":[".price-input\r\n{\r\n    .mat-mdc-select\r\n    {\r\n     //min-width: 100px;\r\n    }\r\n\r\n    .ex-vat\r\n    {\r\n        font-size:10px;\r\n    }\r\n}\r\n",".price-input .ex-vat {\n  font-size: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
