// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar-day-selector {
  width: 400px;
  max-width: 70vw;
  position: relative;
  min-height: 500px;
}
.calendar-day-selector .today-button {
  position: absolute;
  left: 160px;
  padding: 0;
  top: 28px;
  width: 120px;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/calendar/calendar-date-selector/calendar-date-selector.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/calendar/calendar-date-selector/calendar-date-selector.component.scss"],"names":[],"mappings":"AAAA;EAEI,YAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;ACAJ;ADEI;EACI,kBAAA;EACA,WAAA;EACA,UAAA;EACA,SAAA;EACA,YAAA;ACAR","sourcesContent":[".calendar-day-selector\r\n{\r\n    width: 400px;\r\n    max-width: 70vw;\r\n    position: relative;\r\n    min-height: 500px;\r\n\r\n    .today-button{\r\n        position: absolute;\r\n        left: 160px;\r\n        padding: 0;\r\n        top: 28px;\r\n        width: 120px;\r\n    }\r\n}\r\n",".calendar-day-selector {\n  width: 400px;\n  max-width: 70vw;\n  position: relative;\n  min-height: 500px;\n}\n.calendar-day-selector .today-button {\n  position: absolute;\n  left: 160px;\n  padding: 0;\n  top: 28px;\n  width: 120px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
