import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../../services/globals';
import { Utility } from '../../../services/utility';
import { Api } from '../../../services/api';
import { TranslateService } from '@ngx-translate/core';
import { orderBy } from '../../../helpers/utility';
import { IDepartment } from '../../../../../../../../../../common/common-interfaces/department';


@Component({
    selector: 'department-chooser',
    templateUrl: './department-chooser.component.html',
    styleUrls: ['./department-chooser.component.scss']
})

export class DepartmentChooserComponent implements OnInit {
    @Input()
        departmentId: any;

    @Output()
        departmentIdChange = new EventEmitter();


    departments: IDepartment[];
    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility, private translate: TranslateService) {

    }

    async departmentSelected(departmentId) {
        console.log(`Department selected: ${this.departmentId}`);
        this.departmentIdChange.emit(this.departmentId);
    }

    async ngOnInit() {
        this.departments = await this.api.client().get<IDepartment[]>(`/manage/departments`);
        this.departments.sort(orderBy('name').asc);
    }

}
