import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Api } from '../../services/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, from } from 'rxjs';
import { Globals } from '../../services/globals';
import { Utility } from '../../services/utility';
import { IQRCodePoster } from '../../../../../../../../../common/common-interfaces/qr-code';
import QRCodeStyling from 'qr-code-styling';

@Component({
    selector: 'qr-code-poster-print',
    styleUrls: ['./qr-code-poster-print.component.scss'],
    templateUrl: './qr-code-poster-print.component.html'
})

export class QRCodePosterPrintComponent implements OnInit, OnDestroy {
    @ViewChild("canvas", { static: false }) canvas: ElementRef<HTMLElement>;

    poster: IQRCodePoster;
    size: string = 'A4';
    orientation: string = 'portrait';

    private paramsSubscription?: Subscription;


    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility) {

    }

    async ngOnInit() {
        this.paramsSubscription = this.route.params.subscribe(async (params) => {
            if (params['id']) {
                this.poster = await this.api.client().get<IQRCodePoster>(`/manage/qr-code-posters/${params['id']}`);
                setTimeout(() => {
                    this.refreshQRCode();
                }, 100);
            }
        });

    }

    getClasses() {
        let classes = {};
        classes[this.size] = true;
        classes[this.orientation] = true;
        return classes;
    }

    async refreshQRCode() {
        this.canvas.nativeElement.innerHTML = "";
        const qrCode = new (QRCodeStyling as any)({
            width: this.poster.qrCodeData.style.width,
            height: this.poster.qrCodeData.style.height,
            margin: this.poster.qrCodeData.style.margin,
            data: this.poster.qrCodeData.data,
            image: this.poster.qrCodeData.style.logoImage ? this.poster.qrCodeData.style.logoImage.url : null,
            dotsOptions: this.poster.qrCodeData.style.dotsOptions,
            backgroundOptions: this.poster.qrCodeData.style.backgroundOptions,
            cornersSquareOptions: this.poster.qrCodeData.style.cornersSquareOptions,
            imageOptions: {
                crossOrigin: "anonymous",
                margin: 14
            }
        });

        qrCode.append(this.canvas.nativeElement);
        this.canvas.nativeElement.querySelector('canvas').style.maxWidth = `${this.poster.qrCodeData.style.percentSize}%`;
        this.canvas.nativeElement.querySelector('canvas').style.maxHeight = `${this.poster.qrCodeData.style.percentSize}%`;
    }

    ngAfterViewInit(): void {

    }

    print() {
        window.print();
    }

    async ngOnDestroy() {
        if (this.paramsSubscription)
            this.paramsSubscription.unsubscribe();
    }
}
