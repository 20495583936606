// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.extra-activities button.styled {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/calendar/quick-booking-dialog/extra-activities/quick-booking-extra-activities.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/calendar/quick-booking-dialog/extra-activities/quick-booking-extra-activities.component.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;EACA,4BAAA;ACAJ","sourcesContent":[".extra-activities {\r\n  button.styled {\r\n    padding-top: 0 !important;\r\n    padding-bottom: 0 !important;\r\n  }\r\n}\r\n",".extra-activities button.styled {\n  padding-top: 0 !important;\n  padding-bottom: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
