import { Component, OnInit, OnDestroy, ViewChild, Input, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../../../services/globals';
import { Utility } from '../../../../services/utility';
import { Api } from '../../../../services/api';
import { ICashRegisterArticle } from '../article-number-chooser/article-number-chooser.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'extenda-settings',
    templateUrl: './extenda-settings.component.html',
    styleUrls: ['./extenda-settings.component.scss']
})

export class ExtendaSettingsComponent implements OnInit {
  @Input()
      clientSettings: any = null;

  articles: ICashRegisterArticle[];
  vatRates: any[] = [];
  constructor(private api: Api, private router: Router, private route: ActivatedRoute, public globals: Globals, private utility: Utility, private translate: TranslateService) {

  }

  async ngOnInit() {
      this.articles = await this.api.client().get<ICashRegisterArticle[]>('/cash-register/articles');
      this.vatRates = await this.api.client().get<any[]>('/manage/third-party/extenda/vat-rates');

      if (!this.clientSettings.cashRegisterSettings.extendaSettings.productIdToVatRateIdMapping) {
          this.clientSettings.cashRegisterSettings.extendaSettings.productIdToVatRateIdMapping = {};
      }

      if (!Object.keys(this.clientSettings.cashRegisterSettings.extendaSettings.productIdToVatRateIdMapping).length) {
          let defaultMappings = await this.api.client().get<any[]>('/manage/third-party/extenda/default-vat-id-mapping');
          for (let defaultMapping of defaultMappings) {
              if (!this.clientSettings.cashRegisterSettings.extendaSettings.productIdToVatRateIdMapping[defaultMapping.productId]) {
                  this.clientSettings.cashRegisterSettings.extendaSettings.productIdToVatRateIdMapping[defaultMapping.productId] = defaultMapping.vatRateId;
              }
          }
      }

  }

  async save() {
      let result = await this.api.client().patch<any>(`/manage/settings`, this.clientSettings);
  }

  getNumberOfUnmappedVatIdsText() {
      return this.translate.instant('MISSING_VAT_IDS_NOTICE').replace('#AMOUNT', this.getNumberOfUnmappedVatIds());
  }

  getNumberOfUnmappedVatIds() {
      let numberOfUnmappedVatIds = 0;

      if (this.articles && this.articles.length) {
          for (let article of this.articles) {
              if (this.getIfVatRateIsMapped(article)) {
                  numberOfUnmappedVatIds++;
              }
          }
      }

      return numberOfUnmappedVatIds;
  }

  //returns true if the vat rate is not mapped
  getIfVatRateIsMapped(article: ICashRegisterArticle): boolean {
      let vatRateIds = this.vatRates.map(vatRate => vatRate.id);
      return !vatRateIds.includes(this.clientSettings.cashRegisterSettings.extendaSettings.productIdToVatRateIdMapping[article.articleNumber]);
  }


}
