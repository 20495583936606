import { Component, OnInit, OnDestroy, ViewChild, Input, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../../services/globals';
import { Utility } from '../../../services/utility';
import { Api } from '../../../services/api';


@Component({
    selector: 'custom-texts-settings',
    templateUrl: './custom-texts-settings.component.html',
    styleUrls: ['./custom-texts-settings.component.scss']
})

export class CustomTextsSettingsComponent implements OnInit {
    @Input()
        clientSettings: any;


    inputType: 'TEXT' | 'MULTILINE' | 'WYSIWYG';
    @ViewChild('tabGroup', { static: false }) tabGroup;
    @ViewChild('emailTabGroup', { static: false }) emailTabGroup;
    @ViewChild('smsTabGroup', { static: false }) smsTabGroup;
    constructor(private api: Api, private router: Router, private route: ActivatedRoute, public globals: Globals, private utility: Utility) {

    }

    async ngOnInit() {

    }

}
