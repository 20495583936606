import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../../services/globals';
import { TranslateService } from '@ngx-translate/core';
import { IPrice } from '../../../interfaces/price';

@Component({
    selector: 'price-input',
    templateUrl: './price-input.component.html',
    styleUrls: ['./price-input.component.scss']
})

export class PriceInputComponent implements OnInit, OnDestroy {
  @Input()
      price: IPrice;

  constructor(private router: Router, private route: ActivatedRoute, private globals: Globals, private translate: TranslateService) {

  }



  async calculateVat() {
      console.log(this.price.withVat);
      if (['undefined', 'null'].includes(typeof (this.price.withVat)))
          this.price.withVat = 0;
      console.log(this.price.withVat);

      let factor = 1 / (1 + (this.price.vatPercentage / 100));
      this.price.withoutVat = parseFloat((this.price.withVat * factor).toFixed(6));
      this.price.vat = this.price.withVat - this.price.withoutVat;
  }

  async ngOnInit() {

  }

  async ngOnDestroy() {

  }
}
