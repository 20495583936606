import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { IEvent } from '../../../../../../../../../../common/common-interfaces/event';
import { Api } from '../../../services/api';
import { Globals } from '../../../services/globals';
import { Utility } from '../../../services/utility';
import { CurrentUser } from '../../../services/user';
import { Currency } from '../../../services/currency';
import { IEventBooking } from '../../../../../../../../../../common/common-interfaces/event-booking';
import { MatDialog } from '@angular/material/dialog';
import { EventBookingDialogComponent } from '../../event-booking-dialog/event-booking-dialog.component';


@Component({
    selector: 'event-participant-list',
    styleUrls: ['./event-participant-list.component.scss'],
    templateUrl: './event-participant-list.component.html'
})

export class EventParticipantListComponent implements OnInit, OnDestroy {
    @Input()
        event: IEvent;

    eventBookings: IEventBooking[] = [];

    search = {
        includeCanceled: false,
        bookingNumber: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    };

    fetchEventBookingsTimeoutHandler: any;

    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility, public currentUser: CurrentUser, private translate: TranslateService, private snackBar: MatSnackBar, public currency: Currency, private dialog: MatDialog) {


    }

    async fetchEventBookings() {
        this.eventBookings = await this.api.client().post<IEventBooking[]>(`/event-bookings/search`, Object.assign(this.search, {
            eventId: this.event.id
        }));
    }


    searchChanged() {
        if (this.fetchEventBookingsTimeoutHandler)
            clearTimeout(this.fetchEventBookingsTimeoutHandler);
        this.fetchEventBookingsTimeoutHandler = setTimeout(() => {
            this.fetchEventBookings();
        }, 500);
    }

    openEventBooking(eventBooking: IEventBooking) {
        this.dialog.open(EventBookingDialogComponent, {
            data: {
                event: this.event,
                eventBooking: eventBooking,
                eventBookingChanged: (refetch?: boolean) => {
                    console.log('Event booking changed');
                },
                eventBookingCanceled: () => {
                    console.log('Event booking canceled');
                },
                eventBookingMoved: async (eventBooking: IEventBooking, newEvent: IEvent) => {
                    console.log('Event booking moved');
                    await this.fetchEventBookings();
                }
            }
        });
    }

    async ngOnInit() {
        this.fetchEventBookings();
    }

    async ngOnDestroy() {

    }

    async downloadCSV() {
        let download = (content, fileName, mimeType) => {
            var a = document.createElement('a');
            mimeType = mimeType || 'application/octet-stream';

            if ((navigator as any).msSaveBlob) { // IE10
                (navigator as any).msSaveBlob(new Blob([content], {
                    type: mimeType
                }), fileName);
            } else if (URL && 'download' in a) { //html5 A[download]
                a.href = URL.createObjectURL(new Blob([content], {
                    type: mimeType
                }));
                a.setAttribute('download', fileName);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } else {
                location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
            }
        };


        let getCsvString = (content: string) => {
            if (content && typeof (content) == 'string') {
                var result = content.replace(/"/g, '""');
                return `"${result}"`;
            }
            else {
                return '';
            }
        };


        let csvContent = "";
        csvContent += `${[
            '#',
            'FIRST_NAME',
            'LAST_NAME',
            'EMAIL',
            'PHONE'
        ].join(',')}\r\n`;

        let allEventBookings = await this.api.client().post<IEventBooking[]>(`/event-bookings/search`, Object.assign(this.search, {
            eventId: this.event.id,
            noLimit: true
        }));

        if(this.search.includeCanceled === false){
            allEventBookings = allEventBookings.filter((booking) => {
                return !booking.isCanceled;
            });
        }

        for (let data of allEventBookings) {
           
            csvContent += `${[
                getCsvString(String(data.bookingNumber)),
                getCsvString(data.customer.firstName),
                getCsvString(data.customer.lastName),
                getCsvString(data.customer.email),
                getCsvString(data.customer.phone),
            ].join(',')}\r\n`;

            //add participants info to csv if there are any
            if(data.participants && data.participants.length > 0){
                data.participants.forEach((participant) => {
                    csvContent += `${[
                        getCsvString(String(data.bookingNumber)),
                        getCsvString(participant.firstName),
                        getCsvString(participant.lastName),
                        getCsvString(participant.email),
                        getCsvString(participant.phone),
                    ].join(',')}\r\n`;
                });
            }

        }

        download(csvContent, 'participants.csv', "data:text/csv;charset=utf-8,");
    }

}

