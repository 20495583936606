import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { IEvent, IEventDate } from '../../../../../../../../../../common/common-interfaces/event';
import { Api } from '../../../services/api';
import { Globals } from '../../../services/globals';
import { Utility } from '../../../services/utility';
import allLocales from '@fullcalendar/core/locales-all';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import { IResource } from '../../../interfaces/resource';
import { addDurationToTime, timeDifferenceInMinutes } from '../../../helpers/utility';
import { getResourceEndTime, getResourceStartTime } from '../event-administration.component';

@Component({
    selector: 'event-dates-selector',
    styleUrls: ['./event-dates-selector.component.scss'],
    templateUrl: './event-dates-selector.component.html'
})

export class EventDatesSelectorComponent implements OnInit, OnDestroy {
    @Input()
        event: IEvent;

    @Input()
        availableResources: IResource[];

    @Input()
        isBooking: boolean = false;

    @Output()
        dateAdded = new EventEmitter<IEventDate>();

    @Output()
        dateRemoved = new EventEmitter<IEventDate>();


    @ViewChild('fullcalendar', { static: false }) fullcalendar: FullCalendarComponent;
    calendarOptions: any = {};
    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility) {
        this.calendarOptions = {
            header: {
                right: '',
                left: '',
                center: 'prev,title,next'
            },
            defaultView: 'dayGridMonth',
            locales: allLocales,
            events: this.getEvents(this),
            locale: globals.language,
            plugins: [dayGridPlugin, interactionPlugin],
            selectable: false,
            selectOverlap: false
        };
    }

    getEvents(_this: EventDatesSelectorComponent) {
        return async (info) => {
            console.log('Get events');
            let calendarEvents: any[] = [];

            for (let date of _this.event.dates) {
                calendarEvents.push({
                    id: date.identifier,
                    eventDate: date,
                    start: `${date.day} ${date.startTime}`,
                    end: `${date.day} ${date.endTime}`
                });
            }

            return calendarEvents;
        };
    }


    async eventRender(info) {
        let element: HTMLElement = info.el;
        let event = info.event;
        let eventDate: IEventDate = event.extendedProps.eventDate;

        console.log('Render event');
        console.log(event);

        console.log(this.isBooking);

        if (eventDate) {
            let classes = [];

            if (this.event.associatedResources.length && eventDate.isBooked) {
                classes.push('not-clickable');
                classes.push('positive-background');
            } else {
                classes.push('neutral-background');
            }


            element.innerHTML = `<div class="event ${classes.join(' ')}"></div>`;


            element.addEventListener('click', () => {
                console.log(`Event clicked`);
                this.eventClicked(event);

                if(!this.isBooking) {
                    if (!this.event.associatedResources.length || !eventDate.isBooked) {
                        this.event.dates.splice(this.event.dates.findIndex(d => d.identifier == eventDate.identifier), 1);
                        this.update();
                        this.dateRemoved.emit(eventDate);
                    }
                }
            });
        }
    }

    async eventClicked(event) {

    }

    async dateClicked(info) {
        console.log('Date clicked');
        console.log(info);
        let calendarEvents: any[] = [];
        let date = moment(info.date);
        let day = date.format('YYYY-MM-DD');
        if(!this.isBooking) {
            if (moment().isBefore(date) || day == moment().format('YYYY-MM-DD')) {

                if (!this.event.dates.some(d => d.day == day)) {

                    let eventDate: IEventDate = {
                        identifier: this.utility.uuid(),
                        day: day,
                        startTime: this.event.defaultStartTime,
                        endTime: this.event.defaultEndTime,
                        startDate: moment(`${day} ${this.event.defaultStartTime}`).toDate(),
                        endDate: moment(`${day} ${this.event.defaultEndTime}`).toDate(),
                        resources: []
                    };
                    for (let r of this.event.associatedResources) {
                        eventDate.resources.push({
                            resourceId: r.resourceId,
                            uniqueResourceId: this.utility.uuid(),
                            name: r.name,
                            startTime: getResourceStartTime(this.availableResources.find(resource => resource.id == r.resourceId), this.event.defaultStartTime),
                            endTime: getResourceEndTime(this.availableResources.find(resource => resource.id == r.resourceId), this.event.defaultEndTime),
                            lockTimeslots: false
                        });
                    }
                    this.event.dates.push(eventDate);
                    this.update();
                    this.dateAdded.emit(eventDate);
                }
            }
        }
    }



    async update() {
        this.fullcalendar.getApi().refetchEvents();
    }

    async ngOnInit() {

    }

    async ngAfterViewInit() {
        this.fullcalendar.getApi().render();
    }

    async ngOnDestroy() {

    }
}
