import { Component, OnInit, OnDestroy } from '@angular/core';
import { Api } from '../../services/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Globals } from '../../services/globals';
import { IResource } from '../../interfaces/resource';
import * as moment from 'moment';
import { GenerateTimeslots, CloneTimeslotsFromRole } from '../../helpers/timeslot-generator';
import { Utility } from '../../services/utility';
import { CurrentUser } from '../../services/user';

@Component({
    selector: 'resource-administration',
    templateUrl: './resource-administration.component.html'
})

export class ResourceAdministrationComponent implements OnInit, OnDestroy {

    resource: IResource | null = null;
    selectedTimeslotInterval = null;
    timeslotTable: any = null;
    private paramsSubscription?: Subscription;
    constructor(private api: Api, private router: Router, private route: ActivatedRoute, public globals: Globals, private utility: Utility, public currentUser: CurrentUser) {

    }

    async generateTimeslots(role) {
        await GenerateTimeslots(role,
            this.resource.timeslotInterval || this.selectedTimeslotInterval || '',
            this.resource,
            false, null);
    }

    async enabledTimeslotSchemeChanged(role) {
        if (this.resource.enabledTimeslotSchemes[role] && !this.resource.timeslots[role]) {
            this.resource.timeslots[role] = await CloneTimeslotsFromRole(this.resource.timeslots, 'public');
            //this.generateTimeslots(role);
        }
    }

    resetInterval() {
        delete this.resource.timeslotInterval;
        delete this.selectedTimeslotInterval;
        delete this.resource.timeslots;
    }


    async clone() {
        let result = await this.api.client().post<any>(`/manage/resources/${this.resource.id}/clone`, {});
        window.location.href = `/client/${this.globals.clientId}/resources/${result.newResource.id}`;
    }

    async save() {
        // Check if resource should be created or updated
        if (this.resource && this.resource.id) {
            let result = await this.api.client().patch<any>(`/manage/resources/${this.resource.id}`, this.resource);
        }
        else {
            let result = await this.api.client().put<any>(`/manage/resources`, this.resource);
            if (result.succeeded) {
                this.router.navigateByUrl(`/${this.globals.clientId}/resources/${result.resource.id}`);
            }
        }
    }

    async ngOnInit() {
        this.paramsSubscription = this.route.params.subscribe(async (params) => {
            if (params['id']) {
                this.resource = await this.api.client().get<IResource>(`/manage/resources/${params['id']}`);

                if (this.resource.customTimeslotPeriods) {
                    for (let timeslotPeriod of this.resource.customTimeslotPeriods) {
                        timeslotPeriod.startDate = moment(timeslotPeriod.startDate).toDate();
                        timeslotPeriod.endDate = moment(timeslotPeriod.endDate).toDate();
                    }
                }

                if (!this.resource.enabledTimeslotSchemes) {
                    this.resource.enabledTimeslotSchemes = { public: true };
                }

                if (!this.resource.customTimeslotPeriods) {
                    this.resource.customTimeslotPeriods = [];
                }

                if (!this.resource.minPeople) {
                    this.resource.minPeople = 1;
                }
                if (!this.resource.maxPeople) {
                    this.resource.minPeople = 100;
                }

                if (!this.resource.color) {
                    this.resource.color = this.utility.randomColor();
                }
            }
            else {
                this.resource = {
                    timeslots: {},
                    enabledTimeslotSchemes: { public: true },
                    maxPeople: 100,
                    minPeople: 1,
                    color: this.utility.randomColor()
                };
            }
        });
    }

    async ngOnDestroy() {
        if (this.paramsSubscription)
            this.paramsSubscription.unsubscribe();
    }
}
