import { Component, OnInit, Input } from '@angular/core';
import { Globals } from '../../../services/globals';
import { Api } from '../../../services/api';
import { IActivitySequences, IPackage, IPriceSettings, IExtraOrderRow } from '../../../interfaces/package';
import { IActivity } from '../../../interfaces/activity';
import { addDuration, minutesFromDuration, getStandardColor } from '../../../helpers/utility';
import { TranslateService } from '@ngx-translate/core';
import { newPrice } from '../../../interfaces/price';
import { Currency } from '../../../services/currency';
import { Utility } from '../../../services/utility';
import { CurrentUser } from '../../../services/user';






@Component({
    selector: 'price-settings-editor',
    templateUrl: './price-settings-editor.component.html',
    styleUrls: ['./price-settings-editor.component.scss']
})

export class PriceSettingsEditorComponent implements OnInit {
    @Input()
    priceSettings: IPriceSettings[];

    visablePrices: IPriceSettings[];
    showInactive: boolean = false

    constructor(private api: Api, public globals: Globals, public translate: TranslateService, public currency: Currency, public utility: Utility, public currentUser: CurrentUser) {

    }

    addPriceSetting() {
        this.priceSettings.push({
            name: '',
            color: getStandardColor(this.priceSettings.length),
            customerGroupPrices: {},
            extraOrderRows: [],
            price: newPrice(),
            inactive: false,
        });

        this.filterPrices()
    }


    disablePriceSetting(priceSetting: IPriceSettings, index: number) {
        if (this.visablePrices.length >= 1) {
            const targetPrice = this.priceSettings.findIndex((setting) => setting == priceSetting)
            this.priceSettings[targetPrice].inactive = true;
        }

        this.filterPrices()
    }

    enablePriceSetting(priceSetting: IPriceSettings, index: number) {
        const targetPrice = this.priceSettings.findIndex((setting) => setting == priceSetting)
        this.priceSettings[targetPrice].inactive = false;

        this.filterPrices()
    }



    removeExtraOrderRow(priceSetting: IPriceSettings, extraOrderRow: IExtraOrderRow) {
        priceSetting.extraOrderRows.splice(priceSetting.extraOrderRows.indexOf(extraOrderRow), 1);
    }

    addExtraOrderRow(priceSetting: IPriceSettings) {
        priceSetting.extraOrderRows.push({
            name: '',
            price: newPrice()
        });
    }
    toggleInactiveView() {
        if (this.showInactive) {
            this.visablePrices = this.priceSettings
            return
        }

        this.filterPrices()
    }

    filterPrices() {
        if (this.showInactive) {
            this.visablePrices = this.priceSettings;

            return
        }

        this.visablePrices = this.priceSettings.filter((price) => price.inactive == false)
    }


    async ngOnInit() {

        for (let priceSetting of this.priceSettings) {
            if (!priceSetting.inactive) {
                priceSetting.inactive = false;
            }
        }

        this.filterPrices()

    }
}
