import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../../services/globals';
import { TranslateService } from '@ngx-translate/core';
import { Editor, Toolbar } from 'ngx-editor';


@Component({
    selector: 'ckeditor',
    templateUrl: './ngx-editor.component.html',
})
export class NgxEditor implements OnInit, OnDestroy {
    @Input() text: string = "";

    @Output() textChange = new EventEmitter();
    @Output() change = new EventEmitter();

    editor: Editor;

    toolbar: Toolbar = [
        // default value
        ['bold', 'italic'],
        ['underline', 'strike'],
        ['blockquote'],
        ['ordered_list', 'bullet_list'],
        [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
        ['link', 'image'],
        // or, set options for link:
        //[{ link: { showOpenInNewTab: false } }, 'image'],
        ['text_color', 'background_color'],
        ['align_left', 'align_center', 'align_right', 'align_justify'],
        ['horizontal_rule', 'format_clear', 'indent', 'outdent'],
        ['superscript', 'subscript'],
        ['undo', 'redo'],
    ];

    viewHtml: boolean = false;

    constructor(private router: Router, private route: ActivatedRoute, private globals: Globals, private translate: TranslateService) {

    }

    toggleEditorViewType(): void {
        this.viewHtml = !this.viewHtml;
    }

    onChange(event): void {
        this.text = event;

        this.textChange.emit(event);
        this.change.emit(this.text);
    }

    ngOnInit(): void {
        this.editor = new Editor();
    }

    // make sure to destory the editor
    ngOnDestroy(): void {
        this.editor.destroy();
    }


}