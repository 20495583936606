import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Api } from '../../services/api';
import { CurrentUser } from '../../services/user';
import { Globals } from '../../services/globals';
import * as  moment from 'moment';

@Component({
    selector: 'changelog',
    styleUrls: ['./changelog.component.scss'],
    templateUrl: './changelog.component.html'
})

export class ChangelogComponent implements OnInit {
    changelogYears: {
        year: string,
        entries: []
    }[];
    constructor(private api: Api, private router: Router, private globals: Globals, private route: ActivatedRoute, private currentUser: CurrentUser) {

    }

    async ngOnInit() {
        let changelog = await this.api.base().get<any[]>('/changelog');
        this.changelogYears = [];

        let currentYear: any = {
            year: moment(changelog[0].date).format('YYYY'),
            entries: []
        };

        let stage = 'stable';
        for (let entry of changelog) {
            let year = moment(entry.date).format('YYYY');
            if (currentYear.year != year) {
                this.changelogYears.push(currentYear);
                currentYear = {
                    year: year,
                    entries: []
                };
            }

            currentYear.entries.push(Object.assign({
                stage: stage
            }, entry));

            if (entry.milestone) {
                switch (entry.milestone) {
                case 'Pre-Alpha':
                    stage = 'initial';
                    break;
                case 'Alpha':
                    stage = 'pre-alpha';
                    break;
                case 'Beta':
                    stage = 'alpha';
                    break;
                case 'Stable':
                    stage = 'beta';
                    break;
                }
            }
        }
        this.changelogYears.push(currentYear);
    }

    entryDate(date) {
        return moment(date).format('D MMM').replace(' ', '\r\n');
    }
}
