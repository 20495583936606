import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { IEvent, IEventDate } from '../../../../../../../../../../common/common-interfaces/event';
import { Api } from '../../../services/api';
import { Globals } from '../../../services/globals';
import { Utility } from '../../../services/utility';
import allLocales from '@fullcalendar/core/locales-all';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import { IResource } from '../../../interfaces/resource';
import { addDurationToTime, timeDifferenceInMinutes } from '../../../helpers/utility';
import { getResourceEndTime, getResourceStartTime } from '../event-administration.component';

@Component({
    selector: 'event-dates-selector-new',
    styleUrls: ['./event-dates-selector-new.component.scss'],
    templateUrl: './event-dates-selector-new.component.html'
})

export class EventDatesSelectorNewComponent implements OnInit, OnDestroy {
  @Input()
      event: IEvent;

  @Input()
      availableResources: IResource[];

  @Output()
      dateAdded = new EventEmitter<IEventDate>();

  @Output()
      dateRemoved = new EventEmitter<IEventDate>();

  @Input()
      isBooking: boolean = false;

  @ViewChild('fullcalendar', { static: false }) fullcalendar: FullCalendarComponent;
  calendarOptions: any = {};
  constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility) {
      this.calendarOptions = {
          header: {
              right: '',
              left: '',
              center: 'prev,title,next'
          },
          height: 450,
          defaultView: 'dayGridMonth',
          locales: allLocales,
          events: this.getEvents(this),
          dateClick: this.dateClick(this),
          locale: globals.language,
          plugins: [dayGridPlugin, interactionPlugin],
          selectable: false,
          selectOverlap: false
      };
  }

  getEvents(_this: EventDatesSelectorNewComponent) {
      return async (info) => {
          console.log('Get events');
          let calendarEvents: any[] = [];

          for (let date of _this.event.dates) {
              calendarEvents.push({
                  id: date.identifier,
                  eventDate: date,
                  start: `${date.day} ${date.startTime}`,
                  end: `${date.day} ${date.endTime}`
              });
          }

          console.log(calendarEvents);
          return calendarEvents;
      };
  }


  getEventDateClasses(data) {
      let event = data.event;
      let eventDate: IEventDate = event.extendedProps.eventDate;
      let classes = {};

      if (this.event.associatedResources.length && eventDate.isBooked) {
          classes['not-clickable'] = true;
          classes['positive-background'] = true;
      } else {
          classes['neutral-background'] = true;
      }
      return classes;
  }

  async eventRender(info) {
      let element: HTMLElement = info.el;
      let event = info.event;
      let eventDate: IEventDate = event.extendedProps.eventDate;

      console.log('Render event');
      console.log(event);

      if (eventDate) {
          let classes = [];

          if (this.event.associatedResources.length && eventDate.isBooked) {
              classes.push('not-clickable');
              classes.push('positive-background');
          } else {
              classes.push('neutral-background');
          }


          element.innerHTML = `<div class="event ${classes.join(' ')}"></div>`;

      }
  }

  async eventDateClicked(event: IEvent, eventDate: IEventDate) {
      console.log('Event date clicked');
      console.log(eventDate);
      if(!this.isBooking) {
          if (!this.event.associatedResources.length || !eventDate.isBooked) {
              this.event.dates.splice(this.event.dates.findIndex(d => d.identifier == eventDate.identifier), 1);
              this.update();
              this.dateRemoved.emit(eventDate);
          }
      }
  }

  dateClick(_this) {
      return async (info) => {
          _this.dateClicked(info);
      };
  }


  async dateClicked(info) {
      console.log('Date clicked');
      console.log(info);
      let calendarEvents: any[] = [];
      let date = moment(info.date);
      let day = date.format('YYYY-MM-DD');
    
      if(!this.isBooking) {
          if (moment().isBefore(date) || day == moment().format('YYYY-MM-DD')) {

              if (!this.event.dates.some(d => d.day == day)) {

                  let eventDate: IEventDate = {
                      identifier: this.utility.uuid(),
                      day: day,
                      startTime: this.event.defaultStartTime,
                      endTime: this.event.defaultEndTime,
                      startDate: moment(`${day} ${this.event.defaultStartTime}`).toDate(),
                      endDate: moment(`${day} ${this.event.defaultEndTime}`).toDate(),
                      resources: []
                  };
                  for (let r of this.event.associatedResources) {
                      eventDate.resources.push({
                          resourceId: r.resourceId,
                          uniqueResourceId: this.utility.uuid(),
                          name: r.name,
                          startTime: getResourceStartTime(this.availableResources.find(resource => resource.id == r.resourceId), this.event.defaultStartTime),
                          endTime: getResourceEndTime(this.availableResources.find(resource => resource.id == r.resourceId), this.event.defaultEndTime),
                          lockTimeslots: false
                      });
                  }
                  this.event.dates.push(eventDate);
                  this.update();
                  this.dateAdded.emit(eventDate);
              }
          }
      }
  }



  async update() {
      this.fullcalendar.getApi().refetchEvents();
  }

  async ngOnInit() {

  }

  async ngAfterViewInit() {
      this.fullcalendar.getApi().render();
  }

  async ngOnDestroy() {

  }
}
