import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Api } from '../../services/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, from } from 'rxjs';
import { Globals } from '../../services/globals';
import { MatCalendar } from '@angular/material/datepicker';
import { IPackage } from '../../interfaces/package';
import { Utility } from '../../services/utility';
import moment from 'moment';
import { IPromoCode } from '../../interfaces/promo-code';
import { IEvent } from '../../../../../../../../../common/common-interfaces/event';
import { orderBy } from '../../helpers/utility';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'promo-code-administration',
    templateUrl: './promo-code-administration.component.html'
})

export class PromoCodeAdministrationComponent implements OnInit, OnDestroy {
    promoCode: IPromoCode = null;
  @ViewChild('tabGroup', { static: false }) tabGroup;


  @ViewChild('validFromCalendar', { static: false }) validFromCalendar: MatCalendar<Date>;
  @ViewChild('validToCalendar', { static: false }) validToCalendar: MatCalendar<Date>;
  @ViewChild('validVisitPeriodFromCalendar', { static: false }) validVisitPeriodFromCalendar: MatCalendar<Date>;
  @ViewChild('validVisitPeriodToCalendar', { static: false }) validVisitPeriodToCalendar: MatCalendar<Date>;

  private paramsSubscription?: Subscription;

  availablePackages: IPackage[];
  availableEvents: IEvent[];

  constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility, public translate: TranslateService) {

  }


  async save() {
      if (!this.promoCode.amount)
          this.promoCode.amount = 0;

      if (this.promoCode.validVisitPeriodFrom && this.promoCode.validVisitPeriodTo && moment(this.promoCode.validVisitPeriodFrom).format('yyyyMMddHHmm') == moment(this.promoCode.validVisitPeriodTo).format('yyyyMMddHHmm')) {
          this.promoCode.validVisitPeriodTo = moment(this.promoCode.validVisitPeriodTo).endOf('day').toDate();
      }

      if (this.promoCode.validFrom && this.promoCode.validTo && moment(this.promoCode.validFrom).format('yyyyMMddHHmm') == moment(this.promoCode.validTo).format('yyyyMMddHHmm')) {
          this.promoCode.validTo = moment(this.promoCode.validTo).endOf('day').toDate();
      }

      // Check if promo code should be created or updated
      if (this.promoCode && this.promoCode.id) {
          let result = await this.api.client().patch<any>(`/manage/promo-codes/${this.promoCode.id}`, this.promoCode);
      }
      else {
          let result = await this.api.client().put<any>(`/manage/promo-codes`, this.promoCode);
          if (result.succeeded) {
              this.router.navigateByUrl(`/${this.globals.clientId}/promo-codes/${result.promoCode.id}`);
          }
      }
  }

  async ngOnInit() {
      this.paramsSubscription = this.route.params.subscribe(async (params) => {
          if (params['id']) {
              this.promoCode = await this.api.client().get<IPromoCode>(`/manage/promo-codes/${params['id']}`);
              this.promoCode.validFrom = moment(this.promoCode.validFrom).toDate();
              this.promoCode.validTo = moment(this.promoCode.validTo).toDate();
              if (this.promoCode.validVisitPeriodFrom)
                  this.promoCode.validVisitPeriodFrom = moment(this.promoCode.validVisitPeriodFrom).toDate();
              if (this.promoCode.validVisitPeriodTo)
                  this.promoCode.validVisitPeriodTo = moment(this.promoCode.validVisitPeriodTo).toDate();
          }
          else {
              this.promoCode = {
                  amount: 0,
                  restrictUsage: false,
                  usesLeft: 1,
                  validFrom: moment().subtract(1, 'day').toDate(),
                  validTo: moment().add(1, 'year').toDate(),
                  validVisitPeriodFrom: moment().subtract(1, 'day').toDate(),
                  validVisitPeriodTo: moment().add(1, 'year').toDate(),
                  minTotalPrice: 0,
                  packageIds: [],
                  restrictPackages: false,
                  validVisitWeekDays: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
                  type: 'PERCENT',
                  restrictEvents: false,
                  minPersons: 0,
                  maxPersons: 99,
                  restrictPersons: false,
                  eventIds: [],
                  code: (await this.api.client().get<any>(`/manage/promo-codes/generate-code`)).code
              };
          }

          if (!this.promoCode.validVisitPeriodFrom)
              this.promoCode.validVisitPeriodFrom = moment().subtract(1, 'day').toDate();

          if (!this.promoCode.validVisitPeriodTo)
              this.promoCode.validVisitPeriodTo = moment().add(1, 'year').toDate();

          if (!this.promoCode.packageIds)
              this.promoCode.packageIds = [];

          if (!this.promoCode.validVisitWeekDays)
              this.promoCode.validVisitWeekDays = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

          if (!this.promoCode.minTotalPrice)
              this.promoCode.minTotalPrice = 0;

          if (!this.promoCode.eventIds)
              this.promoCode.eventIds = [];

          setTimeout(() => {
              //  this.validFromCalendar._goToDateInView(this.promoCode.validFrom, 'month');
              //  this.validToCalendar._goToDateInView(this.promoCode.validTo, 'month');
              //  this.validVisitPeriodFromCalendar._goToDateInView(this.promoCode.validVisitPeriodFrom, 'month');
              //  this.validVisitPeriodToCalendar._goToDateInView(this.promoCode.validVisitPeriodTo, 'month');
          }, 500);



          this.availablePackages = await this.api.client().get<IPackage[]>(`/packages`);

          this.availableEvents = await this.api.client().get<IEvent[]>('/manage/events/available');
          this.availableEvents.sort(orderBy('name').asc);
      });

  }


  getRestrictedPersonsTooltipTexts() {
      if(!this.promoCode.restrictPackages) { 
          return this.translate.instant('PROMO_CODE_RESTRICTED_PACKAGES_NOT_ACTIVATED');
      }

      if(this.promoCode.restrictPackages && this.promoCode.packageIds.length == 0) {
          return this.translate.instant('RESTRICTED_PERSONS_TOOLTIP_TEXTS_NO_PACKAGES_SELECTED');
      }

      if(this.promoCode.restrictPackages && this.promoCode.packageIds.length > 0 && this.promoCode.restrictPersons) {
          return this.translate.instant('PROMO_CODE_RESTRICT_PERSONS_USECASE').replace('{MIN_PERSONS}', this.promoCode.minPersons.toString()).replace('{MAX_PERSONS}', this.promoCode.maxPersons.toString());
      }

      return this.translate.instant('PROMO_CODE_RESTRICT_PERSONS');
  }

  ngAfterViewInit(): void {

  }

  async ngOnDestroy() {
      if (this.paramsSubscription)
          this.paramsSubscription.unsubscribe();
  }
}
