import { Component, OnInit } from '@angular/core';
import { Api } from '../../services/api';
import { Router } from '@angular/router';
import { orderBy } from '../../helpers/utility';
import { Globals } from '../../services/globals';
import { IResource } from '../../interfaces/resource';



@Component({
    selector: 'resources',
    templateUrl: './resources.component.html'
})

export class ResourcesComponent implements OnInit {
    resources: IResource[] | null = null;
    displayedColumns = ['name', 'edit'];

    showDisabled: boolean = false;

    constructor(private api: Api, private router: Router, public globals: Globals) {

    }


    async ngOnInit() {
        this.resources = await this.api.client().get<IResource[]>('/manage/resources/list');
        this.resources.sort(orderBy('name').asc);
    }
}
