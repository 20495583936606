import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges, HostListener, QueryList, ViewChildren, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Api } from '../../../services/api';
import { Globals } from '../../../services/globals';
import { IPriceSettings } from '../../../interfaces/package';

@Component({
    selector: 'timeslot-table',
    templateUrl: './timeslot-table.component.html',
    styleUrls: ['./timeslot-table.component.scss']
})

export class TimeslotTableComponent implements OnInit, OnDestroy, OnChanges {
    @Input()
        timeslots: any;
    @Input()
        priceSettings: IPriceSettings[];
    @Input()
        activePriceSettingIndex: number;
    timeslotTable: any = null;
    private paramsSubscription?: Subscription;
    viewAllTimeslots: boolean;

    isMouseLftBtnDown: boolean = false;
    currentKey: string = '';
    
    @ViewChildren('checkbox') checkboxes!: any;
    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals) {

    }
    generateTimeslotTable() {
        let result: any = {};   
        // Restructure the timeslots for view in a table
        if (this.timeslots) {
            for (let day in this.timeslots) {
                for (let slot of this.timeslots[day]) {
                    if (this.viewAllTimeslots || slot.viewInTable) {
                        if (!result[slot.startTime])
                            result[slot.startTime] = {};
                        result[slot.startTime][day] = slot;
                    }
                }
            }
        }
        this.timeslotTable = result;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.generateTimeslotTable();
    }


    toggleColumn(day, value) {
        for (let time in this.timeslotTable) {
            this.timeslotTable[time][day].enabled = value;
            this.timeslotTable[time][day].priceSettingIndex = this.activePriceSettingIndex;
        }
    }

    toggleRow(time, value) {
        this.timeslotTable[time].mon.enabled
            = this.timeslotTable[time].tue.enabled
            = this.timeslotTable[time].wed.enabled
            = this.timeslotTable[time].thu.enabled
            = this.timeslotTable[time].fri.enabled
            = this.timeslotTable[time].sat.enabled
            = this.timeslotTable[time].sun.enabled
            = value;

        this.timeslotTable[time].mon.priceSettingIndex
            = this.timeslotTable[time].tue.priceSettingIndex
            = this.timeslotTable[time].wed.priceSettingIndex
            = this.timeslotTable[time].thu.priceSettingIndex
            = this.timeslotTable[time].fri.priceSettingIndex
            = this.timeslotTable[time].sat.priceSettingIndex
            = this.timeslotTable[time].sun.priceSettingIndex
            = this.activePriceSettingIndex;
    }


    valueClicked(val) {
        val.priceSettingIndex = this.activePriceSettingIndex;
    }

    @HostListener('document:keydown', ['$event'])
    onKeydown(event: KeyboardEvent) {
        this.currentKey = event.key;
    }

    @HostListener('document:keyup', ['$event'])
    onKeyup(event: KeyboardEvent) {
        this.currentKey = "";
    }

    mouseOver(mon: any) {
        //add check
        if (this.isMouseLftBtnDown && this.currentKey !== 'Shift') {
            mon.enabled = true;
            this.valueClicked(mon);
        }

        //remove check
        else if (this.isMouseLftBtnDown && this.currentKey === 'Shift' ) {
            mon.enabled = false;
            this.valueClicked(mon);
        }
    }

    @HostListener('document:mousedown', ['$event'])
    onMouseDown(event: MouseEvent) {
        // Check if left mouse button is pressed
        if (event.button === 0 && !this.isMouseLftBtnDown) {
            this.isMouseLftBtnDown = true;
        }
    }

    dblClick(value) {
        if(this.currentKey === 'Alt') {
            this.toggleAll(value);
        }
    }

    @HostListener('document:mouseup', ['$event'])
    onMouseUp(event: MouseEvent) {
        // Check if left mouse button is released
        if (event.button === 0 && this.isMouseLftBtnDown) {
            this.isMouseLftBtnDown = false;
        }
    }

    toggleAll(value) {
        for (let time in this.timeslotTable) {
            this.toggleRow(time, !value);
        }
    }

    

    async ngOnInit() {
        this.generateTimeslotTable();
    }

    async ngOnDestroy() {
        if (this.paramsSubscription)
            this.paramsSubscription.unsubscribe();
    }


}
