import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { Globals } from '../../../../services/globals';
import { CurrentUser } from '../../../../services/user';
import { Currency } from '../../../../services/currency';
import { Utility } from '../../../../services/utility';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';
import { GiftCardOrderEntry } from '../../../../../../../../../../../common/common-types/reports/journal';


@Component({
    selector: 'journal-entry-dialog',
    templateUrl: './journal-entry-dialog.component.html',
    styleUrls: ['./journal-entry-dialog.component.scss']
})
export class JournalEntryGiftCardDialogComponent implements OnInit, OnDestroy {
    
    entry: GiftCardOrderEntry;

    constructor(@Inject(MAT_DIALOG_DATA) public data: GiftCardOrderEntry, private dialogRef: MatDialogRef<JournalEntryGiftCardDialogComponent>, 
    private translate: TranslateService, public globals: Globals, public currentUser: CurrentUser, public currency: Currency, public utility: Utility) {
        
    }

    ngOnDestroy():void {

    }

    ngAfterViewInit():void {
        console.log("ngAfterViewInit");
        this.entry = this.data;
    }

    ngOnInit():void {}

}
