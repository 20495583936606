import { CountryCode } from "../common-types/country-codes";
import { Currency } from "../common-types/currency";

export const formatCurrency = (currency: Currency, number):string => {
    if (!number)
        number = 0;
    let split = (value: string):string => {
        return value.replace(',00', '');

        let split = value.split(',');
        if (split.length == 2)
            return `${split[0]} ${split[1]}`;
        if (split.length == 3)
            return `${split[0]} ${split[1]} ${split[2]}`;
        if (split.length == 4)
            return `${split[0]} ${split[1]} ${split[2]} ${split[3]}`;
        if (split.length == 5)
            return `${split[0]} ${split[1]} ${split[2]} ${split[3]} ${split[4]}`;
        if (split.length == 6)
            return `${split[0]} ${split[1]} ${split[2]} ${split[3]} ${split[4]} ${split[5]}`;
        if (split.length == 7)
            return `${split[0]} ${split[1]} ${split[2]} ${split[3]} ${split[4]} ${split[5]} ${split[6]}`;
        if (split.length == 8)
            return `${split[0]} ${split[1]} ${split[2]} ${split[3]} ${split[4]} ${split[5]} ${split[6]} ${split[7]}`;
        if (split.length == 9)
            return `${split[0]} ${split[1]} ${split[2]} ${split[3]} ${split[4]} ${split[5]} ${split[6]} ${split[7]} ${split[8]}`;
        if (split.length == 10)
            return `${split[0]} ${split[1]} ${split[2]} ${split[3]} ${split[4]} ${split[5]} ${split[6]} ${split[7]} ${split[8]} ${split[9]}`;
        else
            return split[0];
    };

    let numberString = (number != parseInt(number)) ? number.toFixed(2) : number;
    if (currency) {

        let price = parseFloat(numberString).toLocaleString('en', { minimumFractionDigits: 2 }).split(',').join(' ').split('.').join(',');
        switch (currency.toLowerCase()) {
        case 'sek':
            if (price.endsWith(',00'))
                price = split(price);
            return `${price} kr`;
        case 'nok':
            if (price.endsWith(',00'))
                price = split(price);
            return `${price} kr`;
        case 'dkk':
            if (price.endsWith(',00'))
                price = split(price);
            return `${price} DKK`;
        case 'eur':
            if (price.endsWith(',00'))
                price = split(price);
            return `€${price}`;
        case 'usd':
            if (price.endsWith(',00'))
                price = split(price);
            return `$${price}`;
        case 'chf':
            if (price.endsWith(',00'))
                price = split(price);
            return `CHF ${price}`;
        case 'aed':
            if (price.endsWith(',00'))
                price = split(price);
            return `${price} AED`;
        case 'nzd':
            if (price.endsWith(',00'))
                price = split(price);
            return `NZ$${price}`;
        case 'gbp':
            if (price.endsWith(',00'))
                price = split(price);
            return `£${price}`;
        case 'sar':
            if (price.endsWith(',00'))
                price = split(price);
            return `${price} SAR`;
        case 'inr':
            if (price.endsWith(',00'))
                price = split(price);
            return `₹${price.replace(',', '.').replace(' ', ',')}`;
        case 'czk':
            if (price.endsWith(',00'))
                price = split(price);
            return `Kč ${price}`;
        }
    }
    return number;
};


export const getCurrencySymbol = (currency: Currency): { symbol: string, prefix?: boolean } => {
    switch (currency) {
    case 'sek':
    case 'dkk':
    case 'nok':
        return { symbol: 'kr' };
    case 'eur':
        return { symbol: '€', prefix: true };
    case 'gbp':
        return { symbol: '£', prefix: true };
    case 'nzd':
        return { symbol: 'NZ$', prefix: true };
    case 'aed':
        return { symbol: 'AED' };
    case 'usd':
        return { symbol: '$', prefix: true };
    case 'chf':
        return { symbol: 'CHF', prefix: true };
    case 'inr':
        return { symbol: '₹', prefix: true };
    case 'czk':
        return { symbol: 'Kč', prefix: true };
    case 'krw':
        return { symbol: '₩', prefix: true };
    case 'thb':
        return { symbol: '฿', prefix: true };
    }
    return { symbol: '' };
};

export const currencyFromCountry = (country: CountryCode): Currency => {
    switch (country) {
    case 'ae':
        return 'aed';
    case 'ch':
        return 'eur';
    case 'de':
        return 'eur';
    case 'dk':
        return 'dkk';
    case 'fi':
        return 'eur';
    case 'fr':
        return 'eur';
    case 'nl':
        return 'eur';
    case 'no':
        return 'nok';
    case 'se':
        return 'sek';
    case 'be':
        return 'eur';
    case 'nz':
        return 'nzd';
    case 'gb':
        return 'gbp';
    case 'in':
        return 'inr';
    case 'cz':
        return 'czk';
    case 'ko':
        return 'krw';
    case 'th':
        return 'thb';
    }
};
