// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-time-picker .inner {
  display: flex;
}
.date-time-picker .inner .mat-mdc-form-field {
  margin-top: 0;
}
.date-time-picker .inner .date::ng-deep {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
}
.date-time-picker .inner .date::ng-deep .mat-form-field-suffix {
  align-self: flex-end;
  padding-bottom: 4px;
}
.date-time-picker .inner .time {
  margin-top: -1px;
}
.date-time-picker .inner .time input {
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/shared/date-time-picker/date-time-picker.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/shared/date-time-picker/date-time-picker.component.scss"],"names":[],"mappings":"AAMI;EACI,aAAA;ACLR;ADWQ;EACE,aAAA;ACTV;ADcY;EACI,iIAAA;ACZhB;ADagB;EACI,oBAAA;EACA,mBAAA;ACXpB;ADgBQ;EACI,gBAAA;ACdZ;ADgBY;EACI,iBAAA;ACdhB","sourcesContent":[".date-time-picker {\r\n    .label {\r\n   //top: 16px;\r\n   //position: absolute;\r\n    }\r\n\r\n    .inner {\r\n        display: flex;\r\n\r\n        mat-form-field {\r\n        //width: 50%;\r\n        }\r\n\r\n        .mat-mdc-form-field{\r\n          margin-top: 0;\r\n        }\r\n\r\n        .date {\r\n       //transform: translateY(-3px);\r\n            &::ng-deep {\r\n                /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */\r\n                .mat-form-field-suffix {\r\n                    align-self: flex-end;\r\n                    padding-bottom: 4px;\r\n                }\r\n            }\r\n        }\r\n\r\n        .time {\r\n            margin-top: -1px;\r\n\r\n            input {\r\n                text-align: right;\r\n            }\r\n        }\r\n    }\r\n}\r\n",".date-time-picker .inner {\n  display: flex;\n}\n.date-time-picker .inner .mat-mdc-form-field {\n  margin-top: 0;\n}\n.date-time-picker .inner .date::ng-deep {\n  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */\n}\n.date-time-picker .inner .date::ng-deep .mat-form-field-suffix {\n  align-self: flex-end;\n  padding-bottom: 4px;\n}\n.date-time-picker .inner .time {\n  margin-top: -1px;\n}\n.date-time-picker .inner .time input {\n  text-align: right;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
