import { Component, OnInit, EventEmitter, ViewChild, Inject, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Api } from '../../../services/api';
import { IBooking } from '../../../../../../../src/app/interfaces/booking';
import { IResourceBooking } from 'src/app/interfaces/resource-booking';
import moment, { Moment } from 'moment';
import { IEventBooking } from '../../../../../../../../../../common/common-interfaces/event-booking';
import { Globals } from '../../../services/globals';
import { CurrentUser } from '../../../services/user';


@Component({
    selector: 'booking-search-dialog',
    templateUrl: './booking-search-dialog.component.html',
    styleUrls: ['./booking-search-dialog.component.scss']
})

export class BookingSearchDialogComponent implements OnInit {
    searchData: {
    bookingNumber?: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    company?: string
  } = {};

    quickSearchData: {
    query: string
  } = {
            query: ''
        };

    searchResults: {
    start: Moment,
    booking?: IBooking,
    resourceBooking?: IResourceBooking,
    eventBooking?: IEventBooking
  }[];
    latestBookings: {
    created: Moment,
    booking?: IBooking,
    resourceBooking?: IResourceBooking,
    eventBooking?: IEventBooking
  }[];
    searching = false;
    openBooking: any;

    searchDebounceHandler: any;

  @ViewChild('bookingNumberInput') bookingNumberInput: ElementRef<HTMLInputElement>;
  @ViewChild('quickSearchInput') quickSearchInput: ElementRef<HTMLInputElement>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<BookingSearchDialogComponent>, private api: Api, public globals: Globals, public currentUser: CurrentUser) {
      this.openBooking = data.openBooking;

  }

  async quickSearchEnabledChanged() {
      setTimeout(() => {
          if (this.globals.userSettings && this.globals.userSettings.enableQuickSearch)
              this.quickSearchInput.nativeElement.focus();
          else
              this.bookingNumberInput.nativeElement.focus();
      }, 400);

      await this.globals.saveUserSettings();

  }

  async searchKeyUp() {
      if (this.searchDebounceHandler)
          clearInterval(this.searchDebounceHandler);


      this.searchDebounceHandler = setInterval(() => {
          if (!this.searching) {
              clearInterval(this.searchDebounceHandler);

              if (this.globals.userSettings && this.globals.userSettings.enableQuickSearch)
                  this.quickSearch();
              else
                  this.search();
          }
      }, 500);
  }

  async quickSearch() {
      this.searchResults = [];
      this.searching = true;
      if (!this.quickSearchData.query.length) {
          await this.fetchLatestBookings();
          this.searching = false;
          return;
      }


      let bookings = await this.api.client().post<IBooking[]>('/bookings/search/new', this.quickSearchData);
      for (let booking of bookings) {
          this.searchResults.push({
              start: moment(booking.startDate),
              booking: booking
          });
      }

      this.searching = false;


      let resourceBookings = await this.api.client().post<IResourceBooking[]>('/resource-bookings/search/new', this.quickSearchData);
      for (let resourceBooking of resourceBookings) {
          this.searchResults.push({
              start: moment(resourceBooking.startDate),
              resourceBooking: resourceBooking
          });
      }

      this.searchResults.sort((result1: any, result2: any) => {
          return result1._score - result2._score;
      });

      let eventBookings = await this.api.client().post<IEventBooking[]>('/event-bookings/search/new', this.quickSearchData);
      for (let eventBooking of eventBookings) {
          this.searchResults.push({
              start: moment(eventBooking.created),
              eventBooking: eventBooking
          });
      }

      this.searchResults.sort((result1: any, result2: any) => {
          return result1._score - result2._score;
      });


  }

  async search() {
      let isProvided = (field, limit = 3) => field && field.length >= limit;
      if (isProvided(this.searchData.bookingNumber, 1)
      || isProvided(this.searchData.firstName)
      || isProvided(this.searchData.lastName)
      || isProvided(this.searchData.email)
      || isProvided(this.searchData.phone)
      || isProvided(this.searchData.company)) {
          this.searchResults = [];
          this.searching = true;
          let bookings = await this.api.client().post<IBooking[]>('/bookings/search/new', this.searchData);
          for (let booking of bookings) {
              this.searchResults.push({
                  start: moment(booking.startDate),
                  booking: booking
              });
          }


          let resourceBookings = await this.api.client().post<IResourceBooking[]>('/resource-bookings/search/new', this.searchData);
          for (let resourceBooking of resourceBookings) {
              this.searchResults.push({
                  start: moment(resourceBooking.startDate),
                  resourceBooking: resourceBooking
              });
          }


          let eventBookings = await this.api.client().post<IEventBooking[]>('/event-bookings/search/new', this.searchData);
          for (let eventBooking of eventBookings) {
              this.searchResults.push({
                  start: moment(eventBooking.created),
                  eventBooking: eventBooking
              });
          }

          this.searchResults.sort((result1, result2) => {
              if (result1.start.isAfter(result2.start))
                  return -1;
              else
                  return 1;
          });

      }

      this.searching = false;
  }

  async selectBooking(data) {
      this.openBooking(data);
      this.dialogRef.close();
  }

  async ngAfterViewInit() {

  }

  async fetchLatestBookings() {
      this.latestBookings = [];


      let latestBookings = await this.api.client().get<IBooking[]>('/bookings/latest/25');
      for (let booking of latestBookings) {
          this.latestBookings.push({
              created: moment(booking.created),
              booking: booking
          });
      }
      let latestResourceBookings = await this.api.client().get<IResourceBooking[]>('/resource-bookings/latest/25');
      for (let resourceBooking of latestResourceBookings) {
          this.latestBookings.push({
              created: moment(resourceBooking.created),
              resourceBooking: resourceBooking
          });
      }


      let latestEventBookings = await this.api.client().get<IEventBooking[]>('/event-bookings/latest/25');
      for (let eventBooking of latestEventBookings) {
          this.latestBookings.push({
              created: moment(eventBooking.created),
              eventBooking: eventBooking
          });
      }

      this.latestBookings.sort((booking1, booking2) => {
          if (booking1.created.isAfter(booking2.created))
              return -1;
          else
              return 1;
      });


  }

  async ngOnInit() {


      setTimeout(() => {
          if (this.globals.userSettings && this.globals.userSettings.enableQuickSearch)
              this.quickSearchInput.nativeElement.focus();
          else
              this.bookingNumberInput.nativeElement.focus();
      }, 400);
      await this.fetchLatestBookings();
  }
}
