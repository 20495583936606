// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `hr {
  margin-right: 10px;
  opacity: 0.3;
}

@media print {
  canvas {
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    height: auto !important;
    width: auto !important;
  }
  .graph-col {
    flex: 100%;
    max-width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/reports/sales/sales-report-graph/sales-report-chart.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/reports/sales/sales-report-graph/sales-report-chart.component.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,YAAA;ACDJ;;ADIA;EACI;IACI,gBAAA;IACA,eAAA;IACA,gBAAA;IACA,uBAAA;IACA,sBAAA;ECDN;EDIE;IACI,UAAA;IACA,eAAA;ECFN;AACF","sourcesContent":["//Component targeted - dont move\r\n\r\nhr {\r\n    margin-right: 10px;\r\n    opacity: 0.3;\r\n}\r\n\r\n@media print {\r\n    canvas {\r\n        min-height: 100%;\r\n        max-width: 100%;\r\n        max-height: 100%;\r\n        height: auto !important;\r\n        width: auto !important;\r\n    }\r\n\r\n    .graph-col {\r\n        flex: 100%;\r\n        max-width: 100%;\r\n    }\r\n}\r\n","hr {\n  margin-right: 10px;\n  opacity: 0.3;\n}\n\n@media print {\n  canvas {\n    min-height: 100%;\n    max-width: 100%;\n    max-height: 100%;\n    height: auto !important;\n    width: auto !important;\n  }\n  .graph-col {\n    flex: 100%;\n    max-width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
