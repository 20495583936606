// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search label {
  font-size: 0.8em;
}

.event-bookings .canceled {
  opacity: 0.7;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/events/event-participant-list/event-participant-list.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/events/event-participant-list/event-participant-list.component.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;ACAJ;;ADKE;EACE,YAAA;ACFJ","sourcesContent":[".search {\r\n  label {\r\n    font-size: .8em;\r\n  }\r\n}\r\n\r\n.event-bookings {\r\n  .canceled {\r\n    opacity: 0.7;\r\n  }\r\n}\r\n",".search label {\n  font-size: 0.8em;\n}\n\n.event-bookings .canceled {\n  opacity: 0.7;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
