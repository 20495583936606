// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.check-in-section .price {
  font-size: 40px;
  font-weight: bold;
  margin-top: 30px;
}
.check-in-section .payment-status {
  font-size: 30px;
}
.check-in-section .has-arrived {
  font-size: 30px;
}
.check-in-section .has-arrived .arrived-icon {
  font-size: 90px;
}
.check-in-section .has-arrived .arrival-date {
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/calendar/booking-info-dialog/check-in-section/check-in-section.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/calendar/booking-info-dialog/check-in-section/check-in-section.component.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,iBAAA;EACA,gBAAA;ACAJ;ADGE;EACE,eAAA;ACDJ;ADIE;EACE,eAAA;ACFJ;ADII;EACE,eAAA;ACFN;ADKI;EACI,eAAA;ACHR","sourcesContent":[".check-in-section {\r\n  .price {\r\n    font-size: 40px;\r\n    font-weight: bold;\r\n    margin-top: 30px;\r\n  }\r\n\r\n  .payment-status {\r\n    font-size: 30px;\r\n  }\r\n\r\n  .has-arrived {\r\n    font-size: 30px;\r\n\r\n    .arrived-icon {\r\n      font-size: 90px;\r\n    }\r\n\r\n    .arrival-date{\r\n        font-size:14px;\r\n    }\r\n  }\r\n}\r\n",".check-in-section .price {\n  font-size: 40px;\n  font-weight: bold;\n  margin-top: 30px;\n}\n.check-in-section .payment-status {\n  font-size: 30px;\n}\n.check-in-section .has-arrived {\n  font-size: 30px;\n}\n.check-in-section .has-arrived .arrived-icon {\n  font-size: 90px;\n}\n.check-in-section .has-arrived .arrival-date {\n  font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
