// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-ons .add-on-group {
  padding-left: 10px;
  padding-right: 10px;
}
.add-ons .add-on-group .header {
  background: rgba(0, 0, 0, 0.05);
  margin-bottom: 15px;
  padding-top: 5px;
}
.add-ons .add-on-group .header.conditions {
  margin-top: -15px !important;
}
.add-ons .add-on-group:nth-child(even) {
  background: rgba(0, 0, 0, 0.05);
  margin-top: 10px;
}
.add-ons .add-on-group:nth-child(odd) .header {
  margin-top: 10px;
}
.add-ons .add-on-group:nth-child(odd) .header .conditions {
  margin-top: -25px;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/add-ons/add-ons.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/add-ons/add-ons.component.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,mBAAA;ACAJ;ADEI;EACE,+BAAA;EACA,mBAAA;EACA,gBAAA;ACAN;ADEM;EACE,4BAAA;ACAR;ADII;EACE,+BAAA;EACA,gBAAA;ACFN;ADOM;EACE,gBAAA;ACLR;ADOQ;EACE,iBAAA;ACLV","sourcesContent":[".add-ons {\r\n  .add-on-group {\r\n    padding-left: 10px;\r\n    padding-right: 10px;\r\n\r\n    .header {\r\n      background: rgb(0 0 0 / 5%);\r\n      margin-bottom: 15px;\r\n      padding-top: 5px;\r\n\r\n      &.conditions {\r\n        margin-top: -15px !important;\r\n      }\r\n    }\r\n\r\n    &:nth-child(even) {\r\n      background: rgb(0 0 0 / 5%);\r\n      margin-top: 10px;\r\n      \r\n    }\r\n\r\n    &:nth-child(odd) {\r\n      .header {\r\n        margin-top: 10px;\r\n\r\n        .conditions {\r\n          margin-top: -25px;\r\n        }\r\n\r\n      }\r\n    }\r\n  }\r\n}",".add-ons .add-on-group {\n  padding-left: 10px;\n  padding-right: 10px;\n}\n.add-ons .add-on-group .header {\n  background: rgba(0, 0, 0, 0.05);\n  margin-bottom: 15px;\n  padding-top: 5px;\n}\n.add-ons .add-on-group .header.conditions {\n  margin-top: -15px !important;\n}\n.add-ons .add-on-group:nth-child(even) {\n  background: rgba(0, 0, 0, 0.05);\n  margin-top: 10px;\n}\n.add-ons .add-on-group:nth-child(odd) .header {\n  margin-top: 10px;\n}\n.add-ons .add-on-group:nth-child(odd) .header .conditions {\n  margin-top: -25px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
