import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { IBooking } from '../../../../../../../../src/app/interfaces/booking';
import { Api } from '../../../../services/api';
import { DomSanitizer } from '@angular/platform-browser';
import { Globals } from '../../../../services/globals';
import { CurrentUser } from '../../../../services/user';
import { Utility } from '../../../../services/utility';



@Component({
    selector: 'booking-payment-settlement-dialog',
    templateUrl: './booking-payment-settlement-dialog.component.html',
    styleUrls: ['./booking-payment-settlement-dialog.component.scss']
})

export class BookingPaymentSettlementDialogComponent implements OnInit {
    public booking: IBooking;
    loading: boolean;
    mode: 'REFUND' | 'ADDITIONAL_PAYMENT';
    amountToRefund = 0;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog, private dialogRef: MatDialogRef<BookingPaymentSettlementDialogComponent>, private translate: TranslateService, private api: Api, private sanitizer: DomSanitizer, public globals: Globals, public currentUser: CurrentUser, public utility: Utility) {
        this.booking = data.booking;

        if (this.booking.hasArrived) {
            if (this.booking.simplifiedPaymentData.prepaidAmount > this.booking.simplifiedPaymentData.refundedAmount) {
                this.mode = 'REFUND';
                this.amountToRefund = Math.max(0, (this.booking.simplifiedPaymentData.prepaidAmount - this.booking.simplifiedPaymentData.refundedAmount));
            }
        }
        else {

            if (this.booking.simplifiedPaymentData.prepaidAmount >= this.booking.price.withVat) {
                this.mode = 'REFUND';
                this.amountToRefund = Math.max(0, (this.booking.simplifiedPaymentData.prepaidAmount - this.booking.simplifiedPaymentData.refundedAmount) - this.booking.price.withVat);
            }
            else if (this.booking.simplifiedPaymentData.prepaidAmount < this.booking.price.withVat) {
                this.mode = 'ADDITIONAL_PAYMENT';
            }
        }

        this.dialogRef.backdropClick().subscribe(async () => {
            await this.close();
        });
    }

    async refund() {
        this.amountToRefund = Math.max(0, Math.min(this.amountToRefund, (this.booking.simplifiedPaymentData.prepaidAmount - this.booking.simplifiedPaymentData.refundedAmount)));
        this.loading = true;
        let result = await this.api.client().post<any>(`/bookings/${this.booking.id}/refund`, {
            amount: this.amountToRefund
        });

        if (result.succeeded) {
            this.booking.simplifiedPaymentData = result.booking.simplifiedPaymentData;
        }
        else {
            this.close();
        }

        if (this.data.bookingSettlementFinished)
            await this.data.bookingSettlementFinished();
        this.close();
    }


    async skipRefund() {
        if (this.data.bookingSettlementSkipped)
            await this.data.bookingSettlementSkipped();
        this.close();
    }

    async cancel() {
        if (this.data.bookingSettlementCanceled)
            await this.data.bookingSettlementCanceled();
        this.close();
    }


    async close() {
        this.dialogRef.close();
    }

    async ngOnInit() {

    }


    ngOnDestroy() {

    }
}
