// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-icon {
  vertical-align: bottom;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/reports/monthly-summary-report/key-figure/monthly-summary-report-key-figure.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/reports/monthly-summary-report/key-figure/monthly-summary-report-key-figure.component.scss"],"names":[],"mappings":"AAEA;EACE,sBAAA;ACDF","sourcesContent":["//Dont move\r\n\r\n.mat-icon{\r\n  vertical-align: bottom;\r\n}\r\n",".mat-icon {\n  vertical-align: bottom;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
