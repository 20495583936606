// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rule-sections {
  padding: 15px;
}
.rule-sections .rule {
  min-height: 80px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}
.rule-sections .rule:nth-child(odd) {
  background: rgba(0, 0, 0, 0.05);
}
.rule-sections .rule .icon-valign {
  transform: translateY(100%);
}

::ng-deep .checkbox-valign {
  transform: translateY(50%);
}

.heading-3 {
  padding-left: 15px;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/packages/customer-group-rules/customer-group-rules.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/packages/customer-group-rules/customer-group-rules.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;ACCJ;ADCI;EACI,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;ACCR;ADCQ;EACI,+BAAA;ACCZ;ADIQ;EACI,2BAAA;ACFZ;;ADUA;EACE,0BAAA;ACPF;;ADWA;EACI,kBAAA;ACRJ","sourcesContent":[".rule-sections {\r\n    padding: 15px;\r\n\r\n    .rule {\r\n        min-height: 80px;\r\n        margin-left: auto;\r\n        margin-right: auto;\r\n        padding: 10px;\r\n\r\n        &:nth-child(odd) {\r\n            background: rgb(0 0 0 / 5%);\r\n        }\r\n\r\n\r\n\r\n        .icon-valign {\r\n            transform: translateY(100%);\r\n        }\r\n    }\r\n\r\n\r\n}\r\n\r\n\r\n::ng-deep .checkbox-valign {\r\n  transform: translateY(50%);\r\n}\r\n\r\n\r\n.heading-3 {\r\n    padding-left: 15px;\r\n}\r\n",".rule-sections {\n  padding: 15px;\n}\n.rule-sections .rule {\n  min-height: 80px;\n  margin-left: auto;\n  margin-right: auto;\n  padding: 10px;\n}\n.rule-sections .rule:nth-child(odd) {\n  background: rgba(0, 0, 0, 0.05);\n}\n.rule-sections .rule .icon-valign {\n  transform: translateY(100%);\n}\n\n::ng-deep .checkbox-valign {\n  transform: translateY(50%);\n}\n\n.heading-3 {\n  padding-left: 15px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
