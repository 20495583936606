import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../../services/globals';
import { TranslateService } from '@ngx-translate/core';
import { IBookingForm } from '../../../interfaces/booking-form';
import { orderBy } from '../../../helpers/utility';
import { Api } from '../../../services/api';

@Component({
    selector: 'booking-form-mapper',
    templateUrl: './booking-form-mapper.component.html',
    styleUrls: ['./booking-form-mapper.component.scss']
})

export class BookingFormMapperComponent implements OnInit, OnDestroy {
    @Input()
        eventId: string;

    @Input()
        packageId: string;

    bookingForms: IBookingForm[];

    enabledBookingForms: { [bookingFormId: string]: boolean } = {};

    constructor(private router: Router, private route: ActivatedRoute, private globals: Globals, private translate: TranslateService, private api: Api) {

    }

    async ngOnInit() {
        this.bookingForms = await this.api.client().get<IBookingForm[]>('/manage/booking-forms');
        this.bookingForms.sort(orderBy('name').asc);

        for (let bookingForm of this.bookingForms) {
            // Packages
            if (this.packageId) {
                if (bookingForm.associatedPackages && bookingForm.associatedPackages.map(p => p.packageId).includes(this.packageId)) {
                    this.enabledBookingForms[bookingForm.id] = true;
                }
            }

            // Events
            else if (this.eventId) {
                if (bookingForm.associatedEvents && bookingForm.associatedEvents.map(e => e.eventId).includes(this.eventId)) {
                    this.enabledBookingForms[bookingForm.id] = true;
                }
            }
        }
    }

    async bookingFormToggled(bookingForm) {
        // Packages
        if (this.packageId) {
            if (this.enabledBookingForms[bookingForm.id]) {
                let result = await this.api.client().put<any>(`/manage/booking-forms/${bookingForm.id}/package/${this.packageId}`, {});
                if (!result.succeeded) {
                    this.enabledBookingForms[bookingForm.id] = false;
                }
            } else {
                let result = await this.api.client().delete<any>(`/manage/booking-forms/${bookingForm.id}/package/${this.packageId}`);
                if (!result.succeeded) {
                    this.enabledBookingForms[bookingForm.id] = true;
                }
            }
        }

        // Events
        else if (this.eventId) {
            if (this.enabledBookingForms[bookingForm.id]) {
                let result = await this.api.client().put<any>(`/manage/booking-forms/${bookingForm.id}/event/${this.eventId}`, {});
                if (!result.succeeded) {
                    this.enabledBookingForms[bookingForm.id] = false;
                }
            } else {
                let result = await this.api.client().delete<any>(`/manage/booking-forms/${bookingForm.id}/event/${this.eventId}`);
                if (!result.succeeded) {
                    this.enabledBookingForms[bookingForm.id] = true;
                }
            }
        }


    }


    public hasEnabledBookingForms() {
        return Object.values(this.enabledBookingForms).some(x => x);
    }

    async ngOnDestroy() {

    }
}
