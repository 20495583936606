import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { IEvent, IEventDate } from '../../../../../../../../../common/common-interfaces/event';
import { IEventBooking, IEventBookingDate } from '../../../../../../../../../common/common-interfaces/event-booking';
import { Api } from '../../services/api';
import { Currency } from '../../services/currency';
import { Globals } from '../../services/globals';
import { CurrentUser } from '../../services/user';
import { Utility } from '../../services/utility';

@Component({
    selector: 'event-booking-dialog',
    templateUrl: './event-booking-dialog.component.html',
    styleUrls: ['./event-booking-dialog.component.scss']
})

export class EventBookingDialogComponent implements OnInit {
    public event: IEvent;
    public eventDate: IEventDate;
    public eventBookingDate: IEventBookingDate;
    public eventBooking: IEventBooking;
    public nextEventDate: IEventDate;


    availableMoveEvents: IEvent[];
    loadingAvailableMoveEvents: boolean;
    movingBooking: boolean;

    eventBookingCanceled: any;
    eventBookingChanged: any;
    eventBookingMoved: any;

    paymentInfo: any;

  @ViewChild('tabGroup', { static: false })
      tabGroup: MatTabGroup;


  public checkingIn = false;
  public canceling = false;
  public showPaymentText = false;
  public showCancelSureText = false;
  public sendingToCashRegister = false;
  public finishingOrAbortingCashRegisterTransaction = false;

  waitForCashRegisterInterval;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog, private dialogRef: MatDialogRef<EventBookingDialogComponent>, private translate: TranslateService, private api: Api, private sanitizer: DomSanitizer, public globals: Globals, public currentUser: CurrentUser, public currency: Currency, public utility: Utility) {
      this.event = data.event;
      this.eventDate = data.eventDate;
      this.eventBooking = data.eventBooking;
      this.eventBookingChanged = data.eventBookingChanged;
      this.eventBookingCanceled = data.eventBookingCanceled;
      this.eventBookingMoved = data.eventBookingMoved;

      this.dialogRef.backdropClick().subscribe(() => {

      });

  }

  async ngAfterViewInit() {

  }

  ngOnDestroy() {

  }

  async ngOnInit() {
      if (!this.eventBooking.dates)
          this.eventBooking.dates = {};

      this.paymentInfo = this.eventBooking.paymentInfo?.find(pi => pi.status == 'PAID');

      if (!this.event) {
          this.event = await this.api.client().get<IEvent>(`/events/${this.eventBooking.eventId}`);
      }

      let today = moment().format('YYYY-MM-DD');
      this.eventDate = this.event.dates.find(eventDate => eventDate.day == today);

      if (this.eventDate) {
          this.eventBookingDate = this.eventBooking.dates[this.eventDate.identifier];

          if (!this.eventBookingDate)
              this.eventBookingDate = await this.api.client().get<IEventBookingDate>(`/event-bookings/${this.eventBooking.id}/dates/${this.eventDate.identifier}`);
      } else {

      }

  }

  async checkIn(eventBookingDate: IEventBookingDate) {
      if (!this.checkingIn) {

          // Booking is not pre-paid
          if (!this.eventBooking.isPaid) {
              if (!this.showPaymentText) {
                  this.showPaymentText = true;
                  return;
              }
              else {
                  this.checkingIn = true;
                  await this.finishCheckIn(eventBookingDate);
                  return;
              }
          } else {
              this.checkingIn = true;
              await this.finishCheckIn(eventBookingDate);
          }

      }
  }


  async finishCheckIn(eventBookingDate: IEventBookingDate) {
      let result = await this.api.client().post<any>(`/event-bookings/${this.eventBooking.id}/dates/${eventBookingDate.dateIdentifier}/check-in`, {});
      if (result.succeeded) {
          if (!this.eventBooking.dates[eventBookingDate.dateIdentifier])
              this.eventBooking.dates[eventBookingDate.dateIdentifier] = {
                  dateIdentifier: eventBookingDate.dateIdentifier
              };
          this.eventBooking.dates[eventBookingDate.dateIdentifier].hasArrived = eventBookingDate.hasArrived = true;
          this.eventBooking.dates[eventBookingDate.dateIdentifier].arrivalDate = eventBookingDate.arrivalDate = new Date();
          this.eventBooking.isPaid = result.eventBooking.isPaid;
          this.eventBooking.log = result.eventBooking.log;

          this.checkingIn = false;
      } else {

      }
  }

  async sendToCashRegister() {
      if (!this.sendingToCashRegister) {
          this.sendingToCashRegister = true;

          let result = await this.api.client().post<any>(`/event-bookings/${this.eventBooking.id}/cash-register/create-transaction`, {});

          if (result.succeeded) {
              this.eventBooking.waitingForCashRegister = result.waitingForCashRegister;
              if (this.eventBooking.waitingForCashRegister) {
                  this.waitForCashRegister();
              }
          }

          this.sendingToCashRegister = false;
      }
  }

  waitForCashRegister() {
      this.waitForCashRegisterInterval = setInterval(async () => {
          let eventBooking = await this.api.client().get<IEventBooking>(`/event-bookings/${this.eventBooking.id}`);
          if (!eventBooking.waitingForCashRegister) {
              this.eventBooking.waitingForCashRegister = false;
              this.eventBooking.isPaid = eventBooking.isPaid;
              this.eventBooking.paymentDate = eventBooking.paymentDate;
              clearInterval(this.waitForCashRegisterInterval);
              delete this.waitForCashRegisterInterval;
          }
      }, 3000);
  }

  getCheckInButtonText() {
      if (this.showPaymentText)
          return this.translate.instant('HAVE_YOU_BEEN_PAID');
      else
          return this.translate.instant('CHECK_IN_BUTTON');
  }

  getCancelButtonText() {
      return this.showCancelSureText ? this.translate.instant('ARE_YOU_SURE') : this.translate.instant('CANCEL_BOOKING');
  }

  async cancel() {
      if (!this.showCancelSureText) {
          this.showCancelSureText = true;
      }
      else {
          this.canceling = true;
          let result = await this.api.publicClient().post<any>(`/event-bookings/${this.eventBooking.id}/cancel`, {});
          if (result.succeeded) {
              let eventBooking = await this.api.client().get<IEventBooking>(`/event-bookings/${this.eventBooking.id}`);
              this.eventBooking.isCanceled = eventBooking.isCanceled;
              this.eventBooking.cancelDate = eventBooking.cancelDate;
              this.eventBooking.log = eventBooking.log;

              if (this.eventBookingCanceled)
                  this.eventBookingCanceled();
          }
          this.canceling = false;
      }
  }

  async updateStaffComment() {
      await this.api.client().put<any>(`/event-bookings/${this.eventBooking.id}/staff-comment`, { staffComment: this.eventBooking.staffComment });
  }


  isMovable() {
      return this.event && !this.event.isFinished;
  }

  async getAvailableMoveEvents() {
      if (!this.loadingAvailableMoveEvents) {
          this.loadingAvailableMoveEvents = true;
          this.availableMoveEvents =
        (await this.api.client().get<IEvent[]>(`/events/available`))
            .filter(e => e.id != this.event.id && (e.availableSlots - e.occupiedSlots) >= this.eventBooking.persons);
      }
  }

  async moveTo(event: IEvent) {
      this.movingBooking = true;
      let result = await this.api.client().post<any>(`/event-bookings/${this.eventBooking.id}/move/to/${event.id}`, {});
      if (result.succeeded) {
          this.eventBooking = result.eventBooking;
          this.event = event;
          this.eventBookingMoved(this.eventBooking, this.event);
          this.tabGroup.selectedIndex = 0;
      }
  }

  getMovedText() {
      return this.translate.instant('MOVED_EVENT_BOOKING_NOTICE')
          .replace('#OLD_EVENT', this.eventBooking.movedFromEvent.previousEventName)
          .replace('#DATE', moment(this.eventBooking.movedFromEvent.moveDate).format('YYYY-MM-DD HH:mm'))
          .replace('#USER', this.eventBooking.movedFromEvent.by);
  }

}
