import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Utility } from '../../../services/utility';
import { Globals } from '../../../services/globals';
import { Api } from '../../../services/api';
import { Currency } from '../../../services/currency';
import { CurrentUser } from '../../../services/user';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { GiftCardOrderEntry, BookingEntry, JournalEntry } from '../../../../../../../../../../common/common-types/reports/journal';
import { MatDialog } from '@angular/material/dialog';
import { JournalEntryDialogComponent } from './journal-entry-dialog/journal-entry-dialog.component';
import { IBookingEntry, IEventBookingEntry, IGiftCardOrderEntry } from '../../../../../../../../../../common/common-interfaces/reports/journal';
import { isNumber } from 'lodash';

@Component({
    selector: 'journal-report',
    templateUrl: './journal-report.component.html',
    styleUrls: ['./journal-report.component.scss']
})
export class JournalReportComponent implements OnInit, OnDestroy, OnChanges {
    startDate: any = moment().subtract(1, "days").startOf('day').format('YYYY-MM-DD');
    endDate: any = moment().endOf('day').format('YYYY-MM-DD');

    user: any;

    selectedType: 'booking' | 'gift-card-order' = 'booking';

    journalReportData: JournalEntry[];
    unFilteredJournalReportData: any[];

    bookingNumberSearch: string = '';
    externalIdSearch: string = '';
    sequenceIdSearch: string = '';

    isPaid: boolean;
    isCanceled: boolean = false;

    searchMode: string = 'created';

    sortMode = {
        created: null,
        paymentDate: null,
        transactionDate: null
    };

    @ViewChild('JournalReportTable', { static: false })
        JournalTable: any;

    constructor(private api: Api, public currentUser: CurrentUser, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility, public currency: Currency, private dateAdapter: DateAdapter<any>, private translate: TranslateService, private dialog: MatDialog) {
        this.globals.clientSettingsReceived.subscribe(() => {
            this.dateAdapter.setLocale(`${this.globals.clientSettings.language}-${this.globals.clientSettings.country.toUpperCase()}`);
            this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
        });

    }
    ngOnChanges(changes: SimpleChanges): void{}

    getDate(date: string): string {
        return date != '' ? moment(date).format('YYYY-MM-DD') : '';
    }

    async updateJournalReport(): Promise<void> {
        delete this.journalReportData;
        await this.fetchData();
    }

    async openEntry(entry: JournalEntry): Promise<void> {
        
        if(entry.type != 'gift-card-order') {
            this.dialog.open(JournalEntryDialogComponent, {
                data: entry
            });
        }
    }

    async csvDownload(): Promise<void> {

        let download = (content, fileName, mimeType):void => {
            var a = document.createElement('a');
            mimeType = mimeType || 'application/octet-stream';

            if ((navigator as any).msSaveBlob) { // IE10
                (navigator as any).msSaveBlob(new Blob([content], {
                    type: mimeType
                }), fileName);
            } else if (URL && 'download' in a) { //html5 A[download]
                a.href = URL.createObjectURL(new Blob([content], {
                    type: mimeType
                }));
                a.setAttribute('download', fileName);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } else {
                location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
            }
        };

        let getCsvString = (content: string): string => {
            if (content && typeof (content) == 'string') {
                var result = content.replace(/"/g, '""');
                return `"${result}"`;
            }
            else {
                return '';
            }
        };

        let csvContent = "";

        if(this.selectedType != 'gift-card-order'){
            csvContent = `#,CREATED_AT,CUSTOMER_NAME,GIFT_CARD_SUM,PAYMENT_DATE,PAYMENT_STATUS,PAYMENT_METHOD,TRANSACTION_DATE,TRANSACTION_STATUS,EXTERNAL_ID,Seq.Id,HANDLED_BY,ABORTED_BY,IS_VERIFIED,PRICE_WITH_VAT,PRICE_WITHOUT_VAT\n`;
            for(let entry of this.journalReportData as BookingEntry[]) {
                csvContent += `${[
                    getCsvString(String(entry.bookingNumber)),
                    getCsvString(String(moment(entry.created).format("YYYY-MM-DD"))),
                    getCsvString(String(entry.customer.firstName + " " + entry.customer.lastName)),
                    getCsvString(String(entry.giftCardSum ?? '')),
                    getCsvString(String(entry.paymentDate)),
                    getCsvString(String(entry.paymentStatus)),
                    getCsvString(String(entry.paymentMethod)),
                    getCsvString(String(entry.transactionInfo.transactionDate ?? '')),
                    getCsvString(String(entry.transactionInfo.status ?? '')),
                    getCsvString(String(entry.transactionInfo.externalId ?? '')),
                    getCsvString(String(entry.transactionInfo.sequenceNumber ?? '')),
                    getCsvString(String(entry.transactionInfo.finishedBy ?? '')),
                    getCsvString(String(entry.transactionInfo.abortedBy ?? '')),
                    getCsvString(String(entry.isVerified)),
                    getCsvString(String(entry.price.withVat)),
                    getCsvString(String(entry.price.withoutVat)),
                ].join(',')}\r\n`;
            }
        }
        else {
            csvContent = `CREATED_AT,\n`;
            for(let entry of this.journalReportData as GiftCardOrderEntry[]) {
                csvContent += `${[
                    getCsvString(String(entry.created)),
                ].join(',')}\r\n`;
            }
        }


        download(csvContent, `journal_data_${moment(this.startDate).format("YYYY-MM-DD")}_${moment(this.endDate).format("YYYY-MM-DD")}.csv`, "data:text/csv;charset=utf-8,");
    }

    async ngOnInit(): Promise<void> {
        this.user = await this.currentUser.get();
        await this.fetchData();
    }

    async fetchData(): Promise<void> {
        let query = `startDate=${moment(this.startDate).format('YYYY-MM-DD')}&endDate=${moment(this.endDate).format('YYYY-MM-DD')}`;

        console.log(this.isCanceled);

        if(this.selectedType != 'gift-card-order'){
            if (typeof (this.isPaid) != 'undefined')
                query += `&isPaid=${this.isPaid}`;
            if (typeof (this.isCanceled) != 'undefined')
                query += `&isCanceled=${this.isCanceled}`;

            query += `&searchMode=${this.searchMode}`;

            this.journalReportData = await this.api.client().get<JournalEntry[]>(`/reports/journal-report/data?${query}`);
        }
        else {
            this.journalReportData = await this.api.client().get<JournalEntry[]>(`/reports/journal-report/data/gift-card-orders?${query}`);
        }

        this.unFilteredJournalReportData = this.journalReportData;

        this.filterRows();
    }

    filterRows(): void {
        this.journalReportData = this.unFilteredJournalReportData;

        if(this.bookingNumberSearch != '' && this.selectedType != 'gift-card-order') {
            this.journalReportData = this.journalReportData.filter((entry) => { return (entry as BookingEntry).bookingNumber.match(this.bookingNumberSearch); });
        }

        if(this.externalIdSearch != ''){
            this.journalReportData = this.journalReportData.filter((entry) => { return entry.transactionInfo.externalId && entry.transactionInfo.externalId.match(this.externalIdSearch); });
        }

        if(this.sequenceIdSearch) {
            this.journalReportData = this.journalReportData.filter((entry) => { return entry.transactionInfo.sequenceNumber && String(entry.transactionInfo.sequenceNumber).match(this.sequenceIdSearch); });
        }

        //sort the table
        this.sortRows();
    }

    sortRows(): void{

        let created: boolean = this.sortMode["created"];
        let paymentDate: boolean = this.sortMode["paymentDate"];
        let transactionDate: boolean = this.sortMode["transactionDate"];

        //sort acording to the toggles (chat-gpt might be right here)
        (this.journalReportData as BookingEntry[]).sort((a, b) => {
            const getTimeOrFallback = (date: any): (number | null) => {
                const time = new Date(date).getTime();
                return isNaN(time) ? null : time; // Return null for invalid dates
            };

            if (created !== null) {
                const dateA = new Date(a.created).getTime();
                const dateB = new Date(b.created).getTime();
                return created ? dateB - dateA : dateA - dateB; // Sort descending if true, ascending if false
            }
            else if (paymentDate !== null) {
                const dateA = getTimeOrFallback(a.paymentDate);
                const dateB = getTimeOrFallback(b.paymentDate);
                if (dateA === null && dateB === null) return 0;
                if (dateA === null) return paymentDate ? 1 : -1; // Null at bottom for descending, top for ascending
                if (dateB === null) return paymentDate ? -1 : 1;
                return paymentDate ? dateB - dateA : dateA - dateB;
            }
            else if (transactionDate !== null) {
                const dateA = getTimeOrFallback(a.transactionInfo.transactionDate);
                const dateB = getTimeOrFallback(b.transactionInfo.transactionDate);
                if (dateA === null && dateB === null) return 0;
                if (dateA === null) return transactionDate ? 1 : -1; // Null at bottom for descending, top for ascending
                if (dateB === null) return transactionDate ? -1 : 1;
                return transactionDate ? dateB - dateA : dateA - dateB;
            }
            // Default sorting by created date (ascending)
            else {
                const numA = Number(a.bookingNumber); 
                const numB = Number(b.bookingNumber);
                if(isNaN(numA) && isNaN(numB)) return 0;
                if(isNaN(numA)) return 1;
                if(isNaN(numB)) return -1;
                return numA - numB;
            }
        });

        if(this.JournalTable){
            this.JournalTable.reload();
        }
    }


    getGiftCardUrl(giftCardId: string): string {
        return `${this.globals.baseUrl}/api/public/clients/${this.globals.clientId}/gift-cards/${giftCardId}/view`;
    }

    changeSelectedType(type: 'booking' | 'gift-card-order'): void {
        this.selectedType = type;
        this.updateJournalReport();
    }


    changeSearchMode(searchMode: string): void {
        this.searchMode = searchMode;
    }

    cutExternalIdToFit(externalId: string): string {
        return externalId.length > 21 ? externalId.substring(0, 21) + '...' : externalId;
    }

    openBookingRef(entry: BookingEntry): void {
        if(entry.type == 'booking') {
            window.open(`${this.globals.baseUrl}/api/public/clients/${this.globals.clientId}/bookings/${(entry as IBookingEntry).bookingId}/ticket`, '_blank');
        }
        else {
            window.open(`${this.globals.baseUrl}/api/public/clients/${this.globals.clientId}/bookings/${(entry as IEventBookingEntry).eventBookingId}/ticket`, '_blank');
        }
    }

    async ngOnDestroy(): Promise<void> {

    }

    getVerifiedIcon(verified: boolean): string {
        return verified ? 'check' : 'warning';
    }

    getFilterOptionClass(value: boolean, reverse?: boolean): any {
        return {
            none: typeof (value) == 'undefined',
            'green-text': reverse ? value === false : value,
            'red-text': reverse ? value === true : value === false
        };
    }

    toggleIsCanceled(): void {
        if (typeof (this.isCanceled) == 'undefined')
            this.isCanceled = true;
        else if (this.isCanceled)
            this.isCanceled = false;
        else
            delete this.isCanceled;
    }

    toggleIsPaid(): void {
        if (typeof (this.isPaid) == 'undefined')
            this.isPaid = true;
        else if (this.isPaid)
            this.isPaid = false;
        else
            delete this.isPaid;
    }

    getVerifiedIconInfoText(entry: JournalEntry): string {
        let infoText = "PAYMENT_CONFIRMED";

        if(!entry.isVerified && !entry.transactionInfo.manuallyConfirmed){
            infoText = "PAYMENT_NOT_CONFIRMED";
        }
        else if(!entry.isVerified && !entry.transactionInfo.manuallyConfirmed) {
            infoText = "MANUALLY_CONFIRMED";
        }
        
        return infoText;
    }

    toggleSort(type: string): void {
        for(let key in this.sortMode) {
            if(key === type) {
                if(this.sortMode[key] === true) {
                    this.sortMode[key] = false;
                }
                else if(this.sortMode[key] === false) {
                    this.sortMode[key] = null;

                }
                else if(this.sortMode[key] === null){
                    this.sortMode[key] = true;
                }
            }
            else {
                this.sortMode[key] = null;
            }
        }

        this.sortRows();
    }

    getSortIcon(type: string): string {
        let mode = this.sortMode[type];

        if(mode == null) {
            return "remove";
        }

        return mode ? "expand_more" : "expand_less";
    }

    getBookingType(type: string): string {
        return type == 'booking' ? 'B-' : 'EB-';
    }
}
