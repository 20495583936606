import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { Api } from '../../../../services/api';
import { Globals } from '../../../../services/globals';
import { Utility } from '../../../../services/utility';



@Component({
    selector: 'monthly-summary-report-key-figure',
    templateUrl: './monthly-summary-report-key-figure.component.html',
    styleUrls: ['./monthly-summary-report-key-figure.component.scss']
})
export class MonthlySummaryReportKeyFigureComponent implements OnInit, OnDestroy {
  @Input()
      title: string;
  @Input()
      value: number;
  @Input()
      comparisonValue: number;


  constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility, private dateAdapter: DateAdapter<any>, private translate: TranslateService) {

  }


  getComparisonValue() {
      return (this.value / this.comparisonValue * 100) - 100;
  }

  async ngOnInit() {

  }

  async ngOnDestroy() {

  }
}
