import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Utility } from '../../../services/utility';
import { Globals } from '../../../services/globals';
import { Api } from '../../../services/api';
import { DateAdapter } from '@angular/material/core';
import { IMonthlySummaryReport, IMonthlySummaryReportKeyFiguresData } from '../../../../../../../../../../common/common-interfaces/reports/monthly-summary-report';
import { TranslateService } from '@ngx-translate/core';
import { ClientCategory, clientCategories } from '../../../../../../../../../../common/common-types/client-categories';
import { CountryCode, availableCountries } from '../../../../../../../../../../common/common-types/country-codes';
import { countriesByCode } from '../../../../../../../../../../common/common-helpers/country';
import { IAggregateMonthlySummaryReport } from '../../../../../../../../../../common/common-interfaces/reports/aggregate-monthly-summary-report';



@Component({
    selector: 'monthly-summary-report',
    templateUrl: './monthly-summary-report.component.html',
    styleUrls: ['./monthly-summary-report.component.scss']
})
export class MonthlySummaryReportComponent implements OnInit, OnDestroy {
    selectedMonth: string = moment().subtract(1, 'month').format('YYYY-MM');
    report: IMonthlySummaryReport;
    comparisonData: {
    keyFigures: IMonthlySummaryReportKeyFiguresData
  };
    loading = true;
    availableMonths: { name: string, month: string }[] = [];
    compareMode: 'PREVIOUS_YEAR' | 'OTHER_CLIENTS';
    availableCategories: ClientCategory[] = clientCategories;
    availableCountries = availableCountries.map(c => c.code);
    countriesByCode = countriesByCode;
    selectedCountries: CountryCode[] = [];
    selectedCategories: ClientCategory[] = [];

    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility, private dateAdapter: DateAdapter<any>, private translate: TranslateService) {

    }

    print() {
        window.print();
    }

    valueExist(value) {
        return value !== undefined && value !== null;
    }


    async refreshData() {
        delete this.comparisonData;
        this.report = await this.api.client().get<any>(`/reports/monthly-summary/${this.selectedMonth}`);

        if (this.compareMode == 'PREVIOUS_YEAR') {
            this.comparisonData = {
                keyFigures: this.report.reportData.previousKeyFigures
            };
        }
        else if (this.compareMode == 'OTHER_CLIENTS') {
            let aggregateData = await this.api.client().get<IAggregateMonthlySummaryReport>(`/reports/monthly-summary/aggregate/${this.selectedMonth}?clientCategories=${this.selectedCategories.join(',')}&countries=${this.selectedCountries.join(',')}`);
            this.comparisonData = {
                keyFigures: aggregateData.reportData.averageKeyFigures
            };
        }

    }


    async monthChanged() {
        delete this.report;
        this.loading = true;
        await this.refreshData();
    }


    async selectedCategoriesChanged() {

    }


    async compareModeChanged() {

    }


    async ngOnInit() {
        this.compareMode = 'PREVIOUS_YEAR';
        let clientSettings = await this.globals.clientSettingsPromise;
        if (clientSettings.category)
            this.selectedCategories.push(clientSettings.category);
        this.selectedCountries.push(clientSettings.country);


        let date = moment().subtract(1, 'month');
        while (date.isAfter(moment().subtract(2, 'years'))) {
            this.availableMonths.push({ name: date.format('MMMM YYYY'), month: date.format('YYYY-MM') });
            date = date.subtract(1, 'month');
        }
        await this.refreshData();
    }

    async ngOnDestroy() {

    }
}
