import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { Utility } from '../../../services/utility';
import { Globals } from '../../../services/globals';
import { Api } from '../../../services/api';
import { ICustomerFeedback } from '../../../interfaces/customer-feedback';
import { DateAdapter } from '@angular/material/core';

@Component({
    selector: 'customer-feedback-report',
    templateUrl: './customer-feedback-report.component.html',
    styleUrls: ['./customer-feedback-report.component.scss']
})

export class CustomerFeedbackReportComponent implements OnInit, OnDestroy {
    startDate: Date = moment().subtract(1, 'month').toDate();
    endDate: Date = moment().toDate();
    customerFeedbacks: ICustomerFeedback[];
    pointsChartData: { date: Date, points: number }[] = null;
    feedbacksWithComments: ICustomerFeedback[];
    feedbackSections: any[];
    feedbackSectionData: {
        [feedbackSectionId: string]: {
            customerFeedback: ICustomerFeedback,
            sectionData: any
        }[]
    } = {};
    commentsOnly = true;
    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility, private dateAdapter: DateAdapter<any>) {
        this.globals.clientSettingsReceived.subscribe(() => {
            this.dateAdapter.setLocale(`${this.globals.clientSettings.language}-${this.globals.clientSettings.country.toUpperCase()}`);
            this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
        });
    }

    async fetchCustomerFeedback() {
        this.customerFeedbacks = null;
        this.customerFeedbacks = await this.api.client().get<ICustomerFeedback[]>(`/reports/customer-feedback/from/${moment(this.startDate).startOf('day').toISOString()}/to/${moment(this.endDate).endOf('day').toISOString()}`);
        this.pointsChartData = this.customerFeedbacks.map(cf => { return { date: cf.date, points: cf.points }; });
        this.feedbacksWithComments = this.commentsOnly ? this.customerFeedbacks.filter(cf => cf.comment) : this.customerFeedbacks;
        this.feedbackSectionData = {};
        for (let customerFeedback of this.customerFeedbacks) {
            for (let feedbackSectionId in customerFeedback.feedbackSectionsData) {
                if (!this.feedbackSectionData[feedbackSectionId])
                    this.feedbackSectionData[feedbackSectionId] = [];
                this.feedbackSectionData[feedbackSectionId].push({
                    customerFeedback: customerFeedback,
                    sectionData: customerFeedback.feedbackSectionsData[feedbackSectionId]
                });
            }
        }
    }

    async fetchCustomerFeedbackBooking(customerFeedback) {
        customerFeedback.booking = await this.api.client().get<any>(`/bookings/${customerFeedback.bookingId}`);
    }

    async fetchCustomerFeedbackEventBooking(customerFeedback) {
        customerFeedback.eventBooking = await this.api.client().get<any>(`/event-bookings/${customerFeedback.eventBookingId}`);
    }


    async updateCustomerFeedback(customerFeedback) {
        await this.api.client().patch<any>(`/reports/customer-feedback/${customerFeedback.id}`, customerFeedback);
    }

    async dateChanged() {
        this.fetchCustomerFeedback();
    }

    async ngOnInit() {
        this.fetchCustomerFeedback();
        this.feedbackSections = await this.api.client().get<any[]>(`/reports/customer-feedback/sections`);
    }

    async ngOnDestroy() {

    }
}
