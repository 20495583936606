import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'new-quantity-chooser',
    templateUrl: './new-quantity-chooser.component.html',
    styleUrls: ['./new-quantity-chooser.component.scss']
})
export class NewQuantityChooserComponent implements OnInit {
    @Output() quantityChanged = new EventEmitter();
    @Input() value: number = 0;
    @Input() min: number = 0;
    @Input() max: number = 999;
    @Input() disabled: boolean;
    @Input() disableOnValue: boolean;

    constructor(private translate: TranslateService) {}

    async increment(): Promise<void> {
        if (this.value < this.max) {
            let oldValue = this.value;
            this.value++;
            this.changed(() => {
                console.log(`Reverted from ${this.value} to ${oldValue}`);
                this.value = oldValue;
            });
        }
    }

    async decrement(): Promise<void> {
        if (this.value > this.min) {
            let oldValue = this.value;
            this.value--;
            this.changed(() => {
                console.log(`Reverted from ${this.value} to ${oldValue}`);
                this.value = oldValue;
            });
        }
    }

    async keyUp(): Promise<void> {
        this.value = Math.ceil(this.value);

        if (this.value < this.min) {
            console.log(`Reverted from ${this.value} to ${this.min}`);
            this.value = this.min;
        } else if (this.value > this.max) {
            console.log(`Reverted from ${this.value} to ${this.max}`);
            this.value = this.max;
        } else {
            this.changed(() => {});
        }
    }

    disableButton(type?: 'ADD' | 'DEC'): boolean {
        if (this.disableOnValue) {
            if (type && type == 'ADD') {
                return this.value == this.max;
            } else if (type && type == 'DEC') {
                return this.value == 0;
            }
        }
        return this.disabled;
    }

    async setValue(value: number): Promise<void> {
        this.value = value;
    }

    async changed(revert?: () => void): Promise<void> {
        if (this.value >= 0) {
            this.quantityChanged.emit({
                value: this.value,
                revert: revert
            });
        } else {
            this.value = 0;
        }
    }

    async ngOnInit(): Promise<void> {}
}
