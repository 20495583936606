import * as QRCodeStyling from "qr-code-styling";
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../../services/globals';
import { TranslateService } from '@ngx-translate/core';
import { IQRCodePoster } from '../../../../../../../../../../common/common-interfaces/qr-code';
import { randomColor } from "../../../../../../../../../../common/common-helpers/utility";
import { Api } from "../../../services/api";
import { googleFonts } from "../../../directives/google-font/google-font.component";
import { Utility } from "../../../services/utility";

@Component({
    selector: 'qr-code-poster-configurator',
    templateUrl: './qr-code-poster-configurator.component.html',
    styleUrls: ['./qr-code-poster-configurator.component.scss']
})

export class QRCodePosterConfiguratorComponent implements OnInit, OnDestroy {
    @Input()
        name: string;
    @Input()
        url: string;
    @Input()
        id: string;

    @Output()
        posterCreated = new EventEmitter<any>();

    poster: IQRCodePoster;

    creatingPoster: boolean;

    @ViewChild("canvas", { static: false }) canvas: ElementRef<HTMLElement>;

    availableFonts = googleFonts;
    availableWeights = [100, 400, 600, 900];

    saveTimeoutHandler: any;

    qrCode:any;

    constructor(private router: Router, private route: ActivatedRoute, private globals: Globals, private translate: TranslateService, private api: Api, public utility: Utility) {

    }

    async ngOnInit() {
        console.log(QRCodeStyling);
        if (!QRCodeStyling) {
            return;
        }

        if (!this.poster) {

            let footerText = '';
            if (this.globals.clientSettings.contactInfo.email)
                footerText += this.globals.clientSettings.contactInfo.email;
            if (this.globals.clientSettings.contactInfo.phone) {
                if (footerText != '')
                    footerText += ' | ';
                footerText += this.globals.clientSettings.contactInfo.phone;
            }
            if (this.globals.clientSettings.hostname) {
                if (footerText != '')
                    footerText += ' | ';
                footerText += this.globals.clientSettings.hostname;
            }


            if (this.id) {
                this.poster = await this.api.client().get<any>(`/manage/qr-code-posters/${this.id}`);
            }
            else {
                this.poster = {
                    name: this.name,
                    textSettings: {
                        heading: {
                            googleFont: 'Droid Sans',
                            color: '#000000',
                            size: 4,
                            text: this.translate.instant('QR_DEFAULT_HEADING'),
                            weight: 400
                        },
                        footer: {
                            googleFont: 'Oswald',
                            color: '#000000',
                            size: 1,
                            text: footerText,
                            weight: 400
                        }
                    },
                    backgroundColor: '#ffffff',
                    qrCodeData: {
                        data: this.url,
                        style: {
                            width: 1500,
                            height: 1500,
                            percentSize: 70,
                            margin: 14,
                            dotsOptions: {
                                color: '#000000',
                                type: 'square'
                            },
                            backgroundOptions: {
                                color: '#ffffff00'
                            },
                            cornersSquareOptions: {
                                color: '#000000',
                                type: 'square'
                            }
                        }
                    }
                };

                this.creatingPoster = true;
                let result = await this.api.client().put<any>(`/manage/qr-code-posters`, this.poster);
                if (result.succeeded) {
                    this.poster = result.qrCodePoster;
                    this.posterCreated.emit(this.poster);
                }
                this.creatingPoster = false;
            }


        }

        setTimeout(() => {
            this.refreshQRCode();
        }, 100);
    }

    async dataChanged() {
        this.poster.qrCodeData.style.backgroundOptions.color = this.poster.backgroundColor;
        clearTimeout(this.saveTimeoutHandler);
        this.saveTimeoutHandler = setTimeout(async () => {
            this.refreshQRCode();
            await this.api.client().patch<any>(`/manage/qr-code-posters/${this.poster.id}`, this.poster);
        }, 1000);
    }

    async refreshQRCode() {
        this.canvas.nativeElement.innerHTML = "";
        this.qrCode = new (QRCodeStyling as any)({
            width: this.poster.qrCodeData.style.width,
            height: this.poster.qrCodeData.style.height,
            margin: this.poster.qrCodeData.style.margin,
            data: this.poster.qrCodeData.data,
            image: this.poster.qrCodeData.style.logoImage ? this.poster.qrCodeData.style.logoImage.url : null,
            dotsOptions: this.poster.qrCodeData.style.dotsOptions,
            backgroundOptions: this.poster.qrCodeData.style.backgroundOptions,
            cornersSquareOptions: this.poster.qrCodeData.style.cornersSquareOptions,
            imageOptions: {
                crossOrigin: "anonymous",
                margin: 14
            }
        });

        this.qrCode.append(this.canvas.nativeElement);
        this.canvas.nativeElement.querySelector('canvas').style.maxWidth = `${this.poster.qrCodeData.style.percentSize}%`;
        this.canvas.nativeElement.querySelector('canvas').style.maxHeight = `${this.poster.qrCodeData.style.percentSize}%`;
    }


    async downloadQRCode(extension: string) {
        this.qrCode.download({ name: "qr", extension: extension });
    }



    async ngOnDestroy() {

    }
}
