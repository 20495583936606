import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Utility } from '../../../services/utility';
import { Globals } from '../../../services/globals';
import { Api } from '../../../services/api';
import { IBooking } from '../../../../../../../src/app/interfaces/booking';
import { DateAdapter } from '@angular/material/core';
import { IPackage } from '../../../interfaces/package';
import { IResourceBooking } from '../../../../../../../src/app/interfaces/resource-booking';
import { IBookingBase } from '../../../../../../../src/app/interfaces/booking-base';

export interface IBookingData {
    created: Date,
    startDate: Date,
    booking?: IBooking,
    bookingBase: IBookingBase,
    resourceBooking?: IResourceBooking
}

@Component({
    selector: 'booking-list-report',
    templateUrl: './booking-list-report.component.html',
    styleUrls: ['./booking-list-report.component.scss']
})
export class BookingListReportComponent implements OnInit, OnDestroy {
    startDate: Date = moment().startOf('day').subtract(1, 'day').toDate();
    endDate: Date = moment().endOf('day').toDate();
    bookingData: IBookingData[];
    searchMode: 'created' | 'startDate' = 'startDate';
    displayMode: 'table' | 'list' | 'customer' = 'table';
    loading: boolean = true;

    birthdayInfo: boolean;
    isPaid: boolean;
    hasArrived: boolean;
    isCanceled: boolean = false;

    availablePackages: IPackage[];
    selectedPackageIds: string[] = [];

    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, public utility: Utility, private dateAdapter: DateAdapter<any>) {
        this.globals.clientSettingsReceived.subscribe(() => {
            this.dateAdapter.setLocale(`${this.globals.clientSettings.language}-${this.globals.clientSettings.country.toUpperCase()}`);
            this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
        });
    }

    print() {
        window.print();
    }

    getFilterOptionClass(value, reverse?) {
        return {
            none: typeof (value) == 'undefined',
            'green-text': reverse ? value === false : value,
            'red-text': reverse ? value === true : value === false
        };
    }

    toggleBirthdayInfo() {
        if (typeof (this.birthdayInfo) == 'undefined')
            this.birthdayInfo = true;
        else if (this.birthdayInfo)
            this.birthdayInfo = false;
        else
            delete this.birthdayInfo;
    }

    toggleIsCanceled() {
        if (typeof (this.isCanceled) == 'undefined')
            this.isCanceled = true;
        else if (this.isCanceled)
            this.isCanceled = false;
        else
            delete this.isCanceled;
    }

    toggleIsPaid() {
        if (typeof (this.isPaid) == 'undefined')
            this.isPaid = true;
        else if (this.isPaid)
            this.isPaid = false;
        else
            delete this.isPaid;
    }

    toggleHasArrived() {
        if (typeof (this.hasArrived) == 'undefined')
            this.hasArrived = true;
        else if (this.hasArrived)
            this.hasArrived = false;
        else
            delete this.hasArrived;
    }

    changeDisplayMode(displayMode) {
        this.displayMode = displayMode;
    }

    changeSearchMode(searchMode) {
        this.searchMode = searchMode;
    }


    updateReports() {
        this.fetchBookings();
    }

    async fetchBookings() {
        this.loading = true;
        this.bookingData = [];
        let query = `startDate=${moment(this.startDate).startOf('day').toISOString()}&endDate=${moment(this.endDate).endOf('day').toISOString()}&searchMode=${this.searchMode}`;

        if (typeof (this.birthdayInfo) != 'undefined')
            query += `&birthdayInfo=${this.birthdayInfo}`;
        if (typeof (this.isPaid) != 'undefined')
            query += `&isPaid=${this.isPaid}`;
        if (typeof (this.hasArrived) != 'undefined')
            query += `&hasArrived=${this.hasArrived}`;
        if (typeof (this.isCanceled) != 'undefined')
            query += `&isCanceled=${this.isCanceled}`;

        if (this.selectedPackageIds.length)
            query += `&packageIds=${this.selectedPackageIds.join(',')}`;

        let bookings = await this.api.client().get<IBooking[]>(`/bookings/get?${query}`);
        bookings.forEach(booking => {
            this.bookingData.push({
                created: moment(booking.created).toDate(),
                startDate: moment(booking.startDate).toDate(),
                bookingBase: booking,
                booking: booking
            });
        });

        let resourceBookings = await this.api.client().get<IResourceBooking[]>(`/resource-bookings/get?${query}`);
        if (resourceBookings) {
            resourceBookings.forEach(resourceBooking => {
                if (!resourceBooking.eventId) {
                    this.bookingData.push({
                        created: moment(resourceBooking.created).toDate(),
                        startDate: moment(resourceBooking.startDate).toDate(),
                        bookingBase: resourceBooking,
                        resourceBooking: resourceBooking
                    });
                }
            });
        }

        this.bookingData.sort((result1, result2) => {
            if (moment(result1[this.searchMode]).isAfter(result2[this.searchMode]))
                return 1;
            else
                return -1;
        });

        this.loading = false;
    }

    async ngOnInit() {
        this.fetchBookings();
        this.availablePackages = await this.api.client().get(`/packages`);
    }

    async ngOnDestroy() {

    }
}
