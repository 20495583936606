import { Component, OnInit, Inject, ViewChild, ElementRef, OnDestroy, AfterViewInit, AfterViewChecked } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import * as  moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Api } from '../../../services/api';
import { DomSanitizer } from '@angular/platform-browser';
import { IBooking, IBookingAddOn } from '../../../../../../../src/app/interfaces/booking';
import { Globals } from '../../../services/globals';
import { CurrentUser } from '../../../services/user';
import { Currency } from '../../../services/currency';
import { IPackage } from '../../../interfaces/package';
import { IChangedPackageInfo } from './change-package-section/change-package-section.component';
import { cloneDeep } from 'lodash';
import { ICheckInPromptData, CheckInPromptDialogComponent } from './check-in-prompt-dialog/check-in-prompt-dialog.component';
import { ICustomer } from '../../../../../../../../../../common/common-interfaces/customer';
import { PrisonIslandRegistrationDialogComponent } from './third-party/prison-island-registration/prison-island-registration-dialog.component';
import { SocialGamingAdministrationDialogComponent } from './third-party/social-gaming-administration/social-gaming-administration-dialog.component';
import { Utility } from '../../../services/utility';
import { BookingPaymentSettlementDialogComponent } from './booking-payment-settlement-dialog/booking-payment-settlement-dialog.component';

@Component({
    selector: 'booking-info-dialog',
    templateUrl: './booking-info-dialog.component.html',
    styleUrls: ['./booking-info-dialog.component.scss']
})

export class BookingInfoDialogComponent implements OnInit, AfterViewInit, AfterViewChecked {
    public booking: IBooking;
    public bookingChanged;
    public bookingCanceled;

    @ViewChild('tabGroup', { static: false })
    tabGroup: MatTabGroup;

    @ViewChild('codeInput', { static: false })
    codeInput: ElementRef<HTMLInputElement>;

    @ViewChild('staffCommentTextarea', { static: false })
    textArea: ElementRef<HTMLTextAreaElement>;


    refetchRequired: boolean;

    public checkingIn = false;
    public showPaymentText = false;
    public showCancelSureText = false;
    public sendingToCashRegister = false;
    public finishingOrAbortingCashRegisterTransaction = false;
    public showManualCashRegisterPrompt = false;

    public codeToApply: string;
    public applyingCode: boolean;
    savingLastMinuteChanges = false;

    waitForCashRegisterInterval;

    changedBookingPackages: { [uniquePackageId: string]: IChangedPackageInfo } = {};
    hasChangedBookingPackages: boolean;

    customerEditMode;
    originalEditCustomerJSON: string;
    editCustomer: ICustomer;
    editCustomerIsDirty: boolean;
    superFieldsDirty: boolean;
    savingCustomerInfo = false;

    newlySentToCashRegister = false;

    paymentInfo: any;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog, private dialogRef: MatDialogRef<BookingInfoDialogComponent>, private translate: TranslateService, private api: Api, private sanitizer: DomSanitizer, public globals: Globals, public currentUser: CurrentUser, private currency: Currency, public utility: Utility) {
        this.booking = data.booking;
        this.bookingChanged = data.bookingChanged;
        this.bookingCanceled = data.bookingCanceled;
        this.currentUser.get();
        this.dialogRef.backdropClick().subscribe(async () => {
            await this.close();

        });

        if (this.booking.waitingForCashRegister) {
            this.waitForCashRegister();
        }
    }

    async closeDialog() {
        await this.close();
        if (this.dialogRef)
            this.dialogRef.close();
    }

    async close() {
        if (this.editCustomerIsDirty)
            await this.saveCustomerEdit();
        if (this.superFieldsDirty)
            await this.saveSuperFields();
        if (this.refetchRequired)
            this.bookingChanged(true);
    }

    cancelBooking() {
        this.bookingCanceled();
        this.dialogRef.close();
    }

    metadataHasChanged() {
        this.refetchRequired = true;
    }

    packageHasChanged(info: IChangedPackageInfo) {
        this.changedBookingPackages[info.bookingPackage.uniquePackageId] = info;
        this.hasChangedBookingPackages = true;
        console.log('Package changed');
    }

    resourcesSwitched(e) {
        this.refetchRequired = true;
        //this.close();
        //this.dialogRef.close();
    }


    lastMinuteChangesEnabled() {
        let hours = typeof (this.globals.clientSettings.checkInChangeAllowedHoursInAdvance) == 'undefined' ? 1 : this.globals.clientSettings.checkInChangeAllowedHoursInAdvance;
        return this.booking && moment(this.booking.startDate).subtract(hours, 'hours').isBefore(moment());
    }

    async changePackage(info: IChangedPackageInfo) {

        // Replace the addOns of the package with the selected addOns
        info.bookingPackage.addOns = [];
        for (let addOnId in info.selectedAddOns) {
            info.bookingPackage.addOns.push(info.selectedAddOns[addOnId] as IBookingAddOn);
        }

        let result = await this.api.publicClient().post<any>(`/bookings/${this.booking.id}/change/persons-and-add-ons/to?uniquePackageId=${encodeURIComponent(info.bookingPackage.uniquePackageId)}`, info.bookingPackage);

        return result.booking;
    }

    getExtraActivities(_package) {
        let extraActivities = _package.activities.filter(a => a.isExtra);
        let uniqueExtraActivities = [];
        for (let extraActivity of extraActivities) {
            if (!uniqueExtraActivities.some(a => a.activityId == extraActivity.activityId))
                uniqueExtraActivities.push(extraActivity);
        }

        return uniqueExtraActivities.length ? uniqueExtraActivities : null;
    }

    async ngAfterViewInit() {
        if (!this.booking.hasArrived && this.codeInput) {
            setTimeout(() => {
                //   this.codeInput.nativeElement.focus();
            }, 200);
        }

    }
    ngAfterViewChecked(): void {

        this.autoResizeTextarea();

    }
    private autoResizeTextarea() {
        if (this.textArea) {
            this.textArea.nativeElement.style.height = 'auto'; // Reset height to auto to shrink if necessary
            this.textArea.nativeElement.style.height = `${this.textArea.nativeElement.scrollHeight}px`;

            if (this.booking.staffComment === "") {
                this.textArea.nativeElement.style.height = "24px"; // Minimum height for empty textarea
            }
        }
    }

    async updateBookingObject(data: { booking: IBooking, refetch?: boolean }) {
        this.booking.packages = data.booking.packages;
        this.booking.price = data.booking.price;
        this.booking.log = data.booking.log;
        this.booking.persons = data.booking.persons;
        this.booking.orderRows = data.booking.orderRows;
        this.booking.isPaid = data.booking.isPaid;
        this.booking.hasArrived = data.booking.hasArrived;
        this.booking.appliedPromoCodes = data.booking.appliedPromoCodes;
        this.booking.appliedGiftCards = data.booking.appliedGiftCards;
        this.booking.simplifiedPaymentData = data.booking.simplifiedPaymentData;

        this.booking.waitingForCashRegister = data.booking.waitingForCashRegister;
        this.booking.cashRegisterTransaction = data.booking.cashRegisterTransaction;

        if (data.refetch)
            this.refetchRequired = true;

        this.bookingChanged();
    }


    async updateStaffComment() {
        await this.api.client().put<any>(`/bookings/${this.booking.id}/staff-comment`, { staffComment: this.booking.staffComment });
        this.bookingChanged();
        if (this.textArea) {
            this.textArea.nativeElement.style.height = 'auto';
            this.textArea.nativeElement.style.height = (this.textArea.nativeElement.scrollHeight) + 'px';

            if (this.booking.staffComment == "") {
                this.textArea.nativeElement.style.height = "24px";
            }
        }

    }

    updateStaffCommentSize() {
        if (this.textArea) {
            this.textArea.nativeElement.style.height = 'auto';
            this.textArea.nativeElement.style.height = (this.textArea.nativeElement.scrollHeight) + 'px';

            if (this.booking.staffComment == "") {
                this.textArea.nativeElement.style.height = "24px";
            }
        }
    }


    async applyCode() {
        this.applyingCode = true;
        let result = await this.api.client().post<any>(`/bookings/${this.booking.id}/apply-code`, { code: this.codeToApply });
        if (result.succeeded) {
            this.updateBookingObject({ booking: result.booking, refetch: true });
            this.codeToApply = '';
        }
        this.applyingCode = false;
    }



    backToCheckIn() {
        this.tabGroup.selectedIndex = 0;
    }

    getBirthdayPersonText(person) {
        let text = text => this.translate.instant(text);
        let birthDate = moment(person.birthDate);
        console.log(birthDate)
        //Update to make sure correct birthdate is always shown. Base on booking date instead of when booking is viewed.
        let age = moment(this.booking.startDate).endOf("year").diff(birthDate, 'years');

        console.log(age)

        return `${person.name} ${age} ${text('YEARS')} (${birthDate.format('YYYY-MM-DD')})`;
    }

    async undoCheckIn() {
        let result = await this.api.client().post<any>(`/bookings/${this.booking.id}/undo-check-in`, {});
        if (result.succeeded) {
            this.booking.hasArrived = false;
        }
    }

    async undoCancel() {
        let result = await this.api.client().post<any>(`/bookings/${this.booking.id}/undo-cancel`, {});
        if (result.succeeded) {
            this.booking.isCanceled = false;
        }
    }

    async checkIn() {
        if (!this.checkingIn) {

            let originalIsPaid = this.booking.isPaid;
            let originalPrice = cloneDeep(this.booking.price);

            // Booking is not pre-paid and not changed
            if (!this.booking.isPaid && !this.hasChangedBookingPackages) {
                if (!this.showPaymentText) {
                    this.showPaymentText = true;
                    return;
                }
                else {
                    this.checkingIn = true;
                    await this.finishCheckIn();
                    return;
                }
            }

            this.checkingIn = true;

            for (let uniquePackageId in this.changedBookingPackages) {
                let booking = await this.changePackage(this.changedBookingPackages[uniquePackageId]);
                await this.updateBookingObject({ booking: booking, refetch: true });
            }

            // Booking is pre-paid and has not changed
            if (this.booking.isPaid && !this.hasChangedBookingPackages) {
                await this.finishCheckIn();
            }
            // Booking has changed
            else {
                let data: ICheckInPromptData = {
                    booking: this.booking,
                    original: {
                        isPaid: originalIsPaid,
                        price: originalPrice
                    }
                };
                this.dialog.open(CheckInPromptDialogComponent, {
                    data: {
                        data: data,
                        finish: async () => {
                            await this.finishCheckIn();
                        },
                        cancel: () => {
                            this.checkingIn = false;
                        }
                    }
                });
            }

            this.hasChangedBookingPackages = false;
            this.changedBookingPackages = {};
        }
    }


    async finishCheckIn() {
        let result = await this.api.client().post<any>(`/bookings/${this.booking.id}/check-in`, {});
        if (result.succeeded) {
            let booking = await this.api.client().get<IBooking>(`/bookings/${this.booking.id}`);
            this.booking.hasArrived = true; //booking.hasArrived;
            this.booking.arrivalDate = booking.arrivalDate;
            this.booking.isPaid = booking.isPaid;
            this.booking.log = booking.log;
            this.updateBookingObject({ booking: this.booking });
            this.checkingIn = false;
        } else {

        }
    }


    async saveLastMinuteChanges() {
        this.savingLastMinuteChanges = true;
        for (let uniquePackageId in this.changedBookingPackages) {
            let booking = await this.changePackage(this.changedBookingPackages[uniquePackageId]);
            await this.updateBookingObject({ booking: booking, refetch: true });
        }
        this.savingLastMinuteChanges = false;
        this.hasChangedBookingPackages = false;
    }

    async sendToCashRegister() {
        if (!this.sendingToCashRegister) {

            let originalIsPaid = this.booking.isPaid;
            let originalPrice = cloneDeep(this.booking.price);

            this.sendingToCashRegister = true;

            // Booking is not pre-paid and not changed
            if (!this.booking.isPaid && !this.hasChangedBookingPackages) {
                await this.finishSendToCashRegister();
                return;
            }



            for (let uniquePackageId in this.changedBookingPackages) {
                let booking = await this.changePackage(this.changedBookingPackages[uniquePackageId]);
                await this.updateBookingObject({ booking: booking, refetch: true });
            }

            // Booking is pre-paid and has not changed
            if (this.booking.isPaid && !this.hasChangedBookingPackages) {
                await this.finishSendToCashRegister();
            }
            // Booking has changed
            else {
                let data: ICheckInPromptData = {
                    booking: this.booking,
                    original: {
                        isPaid: originalIsPaid,
                        price: originalPrice
                    }
                };
                this.dialog.open(CheckInPromptDialogComponent, {
                    data: {
                        data: data,
                        finish: async () => {
                            await this.finishSendToCashRegister();
                        },
                        cancel: () => {
                            this.checkingIn = false;
                        }
                    }
                });
            }

            this.hasChangedBookingPackages = false;
            this.changedBookingPackages = {};

        }
    }

    async checkInSimplifiedPayment() {
        if (!this.checkingIn) {

            let originalIsPaid = this.booking.isPaid;
            let originalPrice = cloneDeep(this.booking.price);

            // Booking is not fully prepaid
            if (!this.booking.isPaid) {
                if (!this.showPaymentText) {
                    this.showPaymentText = true;
                    return;
                }
                else {
                    this.checkingIn = true;
                    await this.finishCheckIn();
                    return;
                }
            }
            //Booking is paid
            else {
                // Booking is paid exactly
                if ((this.booking.simplifiedPaymentData.prepaidAmount - this.booking.simplifiedPaymentData.refundedAmount) == this.booking.price.withVat) {
                    await this.finishCheckIn();
                    return;
                }
                // Booking is overpaid
                else if (this.booking.simplifiedPaymentData.prepaidAmount - this.booking.simplifiedPaymentData.refundedAmount > this.booking.price.withVat) {
                    this.dialog.open(BookingPaymentSettlementDialogComponent, {
                        data: {
                            booking: this.booking,
                            bookingSettlementFinished: async () => {
                                await this.finishCheckIn();
                            },
                            bookingSettlementSkipped: async () => {
                                await this.finishCheckIn();
                            },
                            bookingSettlementCanceled: () => {

                            }
                        }
                    });
                }
            }
        }
    }

    async sendSimplifiedPaymentToCashRegister() {
        if (!this.sendingToCashRegister) {

            // If the booking is paid and not overpaid, just make a check in
            if (this.booking.isPaid && (this.booking.simplifiedPaymentData.prepaidAmount - this.booking.simplifiedPaymentData.refundedAmount) == this.booking.price.withVat) {
                await this.finishCheckIn();
                return;
            }

            // Booking is overpaid
            if (this.booking.simplifiedPaymentData.prepaidAmount - this.booking.simplifiedPaymentData.refundedAmount > this.booking.price.withVat) {
                this.dialog.open(BookingPaymentSettlementDialogComponent, {
                    data: {
                        booking: this.booking,
                        bookingSettlementFinished: async () => {
                            await this.finishSendToCashRegister();
                        },
                        bookingSettlementSkipped: async () => {
                            await this.finishSendToCashRegister();
                        },
                        bookingSettlementCanceled: () => {

                        }
                    }
                });
            }
            // Booking is partially paid
            else if (this.booking.simplifiedPaymentData.prepaidAmount < this.booking.price.withVat) {
                await this.finishSendToCashRegister();
            }

        }

    }

    async finishSendToCashRegister() {

        let result = await this.api.client().post<any>(`/bookings/${this.booking.id}/cash-register/create-transaction`, {});

        if (result.succeeded) {
            this.booking.waitingForCashRegister = result.waitingForCashRegister;
            if (this.booking.waitingForCashRegister) {
                this.newlySentToCashRegister = true;
                this.waitForCashRegister();
            }
            else {
                await this.finishCashRegisterTransaction(true);
            }
        }

        this.sendingToCashRegister = false;
    }


    waitForCashRegister() {
        this.waitForCashRegisterInterval = setInterval(async () => {
            let booking = await this.api.client().get<IBooking>(`/bookings/${this.booking.id}`);
            if (!booking.waitingForCashRegister) {
                this.updateBookingObject({ booking: booking, refetch: true });
                clearInterval(this.waitForCashRegisterInterval);
                delete this.waitForCashRegisterInterval;
            }
        }, 3000);
    }

    async cancel() {
        if (!this.showCancelSureText) {
            this.showCancelSureText = true;
        }
        else {
            this.checkingIn = true;
            let result = await this.api.publicClient().post<any>(`/bookings/${this.booking.id}/cancel`, {});
            if (result.succeeded) {
                let booking = await this.api.client().get<IBooking>(`/bookings/${this.booking.id}`);
                this.booking.isCanceled = booking.isCanceled;
                this.booking.cancelDate = booking.cancelDate;
                this.booking.log = booking.log;
                this.cancelBooking();
            }
            this.checkingIn = true;
        }
    }

    getCheckInButtonText() {
        if (this.showPaymentText)
            return this.translate.instant('HAVE_YOU_BEEN_PAID');
        if (this.hasChangedBookingPackages)
            return this.translate.instant('UPDATE_BOOKING');
        else
            return this.translate.instant('CHECK_IN_BUTTON');
    }


    getCancelButtonText() {
        return this.showCancelSureText ? this.translate.instant('ARE_YOU_SURE') : this.translate.instant('CANCEL_BOOKING');
    }

    async finishCashRegisterTransaction(skipPrompt = false) {
        if (!skipPrompt && !this.showManualCashRegisterPrompt) {
            this.showManualCashRegisterPrompt = true;
            return;
        }
        this.finishingOrAbortingCashRegisterTransaction = true;
        let result = await this.api.client().post<any>(`/bookings/${this.booking.id}/cash-register/finish-transaction`, {});
        if (result.succeeded) {
            this.updateBookingObject({ booking: result.booking, refetch: true });
            clearInterval(this.waitForCashRegisterInterval);
            delete this.waitForCashRegisterInterval;
        }
        this.finishingOrAbortingCashRegisterTransaction = false;
    }

    async abortCashRegisterTransaction() {
        this.finishingOrAbortingCashRegisterTransaction = true;
        let result = await this.api.client().post<any>(`/bookings/${this.booking.id}/cash-register/abort-transaction`, {});
        if (result.succeeded) {
            this.updateBookingObject({ booking: result.booking, refetch: true });
            clearInterval(this.waitForCashRegisterInterval);
            delete this.waitForCashRegisterInterval;
        }
        this.finishingOrAbortingCashRegisterTransaction = false;
    }


    async startCustomerEdit() {
        this.editCustomer = Object.assign({}, this.booking.customer) as ICustomer;
        this.originalEditCustomerJSON = JSON.stringify(this.editCustomer);
        this.customerEditMode = true;
    }

    async cancelCustomerEdit() {
        this.customerEditMode = false;
    }

    async customerEditChanged() {
        if (JSON.stringify(this.editCustomer) != this.originalEditCustomerJSON) {
            this.editCustomerIsDirty = true;
            this.refetchRequired = true;
        }
        else {
            this.editCustomerIsDirty = false;
        }
    }

    async superFieldsEdited() {
        this.superFieldsDirty = true;
        this.refetchRequired = true;
    }

    async saveCustomerEdit() {
        this.savingCustomerInfo = true;
        let result = await this.api.client().post<any>(`/bookings/${this.booking.id}/change/customer-info`, this.editCustomer);
        if (result.succeeded) {
            this.booking.customer = result.booking.customer;
        }
        this.savingCustomerInfo = false;
        this.customerEditMode = false;
    }

    async saveSuperFields() {
        this.savingCustomerInfo = true;
        console.log("Call made")
        let result = await this.api.client().post<any>(`/bookings/${this.booking.id}/change/superfields`, this.booking.superfields)
        if (result.succeeded) {
            this.booking.superfields = result.booking.superfields;
        }
        this.superFieldsDirty = false;
        this.savingCustomerInfo = false;
    }


    openPrisonIslandRegistrationDialog() {
        this.dialog.open(PrisonIslandRegistrationDialogComponent, {
            data: {
                booking: this.booking,
                hijackQRScan: (callback) => {
                    return this.data.hijackQRScan(callback);
                },
                releaseQRScanHijack: () => {
                    this.data.releaseQRScanHijack();
                }
            }
        });
    }

    openSocialGamingAdministrationDialog() {
        this.dialog.open(SocialGamingAdministrationDialogComponent, {
            data: {
                booking: this.booking
            }
        });
    }

    bookingIsRefundable() {
        return this.booking.simplifiedPaymentData 
        && this.booking.simplifiedPaymentData.prepaidAmount > 0
        && this.booking.simplifiedPaymentData.prepaidAmount > this.booking.simplifiedPaymentData.refundedAmount;
    }

    async openBookingRefund() {
        this.dialog.open(BookingPaymentSettlementDialogComponent, {
            data: {
                booking: this.booking,
                bookingSettlementFinished: async () => {
       
                },
                bookingSettlementSkipped: async () => {
                    
                },
                bookingSettlementCanceled: () => {

                }
            }
        });
    }




    async ngOnInit() {
        this.paymentInfo = this.booking.paymentInfo.find(pi => pi.status == 'PAID');
        this.startCustomerEdit();
    }

    ngOnDestroy() {
        if (this.waitForCashRegisterInterval)
            clearInterval(this.waitForCashRegisterInterval);
    }


}
