// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .activity-sequence-duration {
  margin-right: 16px;
}

.indented {
  padding-left: 10px;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/packages/package-administration.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/packages/package-administration.component.scss"],"names":[],"mappings":"AAGE;EACE,kBAAA;ACFJ;;ADMA;EACE,kBAAA;ACHF","sourcesContent":[":host {\r\n\r\n\r\n  .activity-sequence-duration {\r\n    margin-right: 16px;\r\n  }\r\n}\r\n\r\n.indented{\r\n  padding-left:10px;\r\n}\r\n",":host .activity-sequence-duration {\n  margin-right: 16px;\n}\n\n.indented {\n  padding-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
