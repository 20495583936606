import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Utility } from '../../../services/utility';
import { Globals } from '../../../services/globals';
import { Api } from '../../../services/api';
import { DateAdapter } from '@angular/material/core';
import { IGiftCard } from 'src/app/interfaces/gift-card';
import { Currency } from '../../../services/currency';
import moment from 'moment';
import { ChartDataset, LegendOptions, ChartOptions, ChartType } from 'chart.js';
import { orderBy } from '../../../helpers/utility';

@Component({
    selector: 'prison-island-report',
    templateUrl: './prison-island-report.component.html',
    styleUrls: ['./prison-island-report.component.scss']
})

export class PrisonIslandReportComponent implements OnInit, OnDestroy {
    loading: boolean = true;
    startDate: Date;
    endDate: Date;
    prisonIslandData: any;

    cellTableSort = {
        field: 'name',
        order: 'asc'
    };

    totals: {
    byCell: {
      [cellName: string]: {
        avgPointsPercent: number,
        avgSecondsPercent: number,
        seconds: number,
        tries: number,
        points: number
      }
    },
    byDate: {
      [day: string]: {
        tries: number,
        seconds: number
      }
    }
    seconds: number,
    tries: number,
    points: number,
    avgPointsPercent: number,
    avgSecondsPercent: number,
    numberOfTeams: number,
    cellTableRows: any[]
  };

    cellsByDayAveragePointsGraphData: {
    datasets: ChartDataset[],
    labels: string[],
    options: ChartOptions,
    legend?: LegendOptions<any>,
    chartType: ChartType
  };

    cellsByDayAverageSecondsGraphData: {
    datasets: ChartDataset[],
    labels: string[],
    options: ChartOptions,
    legend?: LegendOptions<any>,
    chartType: ChartType
  };

    cellsByDayTriesGraphData: {
    datasets: ChartDataset[],
    labels: string[],
    options: ChartOptions,
    legend?: LegendOptions<any>,
    chartType: ChartType
  };

    cellsByDayTimeInCellGraphData: {
    datasets: ChartDataset[],
    labels: string[],
    options: ChartOptions,
    legend?: LegendOptions<any>,
    chartType: ChartType
  };

    timeInCellDonutGraphData: {
    datasets: ChartDataset[],
    labels: string[],
    options: ChartOptions,
    legend?: LegendOptions<any>,
    chartType: ChartType
  };


    maxTeamScorePerCellGraphData: {
    datasets: ChartDataset[],
    labels: string[],
    options: ChartOptions,
    legend?: LegendOptions<any>,
    chartType: ChartType
  };




    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, public utility: Utility, private dateAdapter: DateAdapter<any>, public currency: Currency) {
        this.globals.clientSettingsReceived.subscribe(() => {
            this.dateAdapter.setLocale(`${this.globals.clientSettings.language}-${this.globals.clientSettings.country.toUpperCase()}`);
            this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
        });
    }

    print() {
        window.print();
    }

    newGraphData(): any {
        return {
            chartType: 'line',
            datasets: [],
            labels: [],
            options: {
                plugins: {
                    tooltip: {}
                },
                elements: {
                    line: {
                        //tension: 0
                    }
                },
                responsive: true,
                tooltips: {
                    mode: 'index',

                },
                hover: {
                    mode: 'index'
                },
                scales: {
                    x:{
                        stacked: false,
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 10
                        }
                    },
                    y:{
                        stacked: true,
                    }
                    /*
          xAxes: [{
            stacked: false,
            scaleLabel: {
              display: true,
              labelString: ''
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 10
            }
          }],
          */
                    /*
          yAxes: [{
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: ''
            }
          }]
        */
                }
            }
        };
    }

    newDonutGraphData(): any {
        return {
            chartType: 'doughnut',
            datasets: [],
            labels: [],
            options: {
                plugins: {
                    tooltip: {}
                },
                responsive: true,
                tooltips: {
                    mode: 'index',
                },
                hover: {
                    mode: 'index'
                }
            }
        };
    }

    newBarGraphData(): any {
        return {
            chartType: 'bar',
            datasets: [],
            labels: [],
            options: {
                plugins: {
                    tooltip: {}
                },
                responsive: true,
                tooltips: {
                    mode: 'index',
                },
                hover: {
                    mode: 'index'
                }
            }
        };
    }


    async fetchData() {
        this.loading = true;
        let data = await this.api.client().get<any>(`/reports/prison-island/data?startDate=${moment(this.startDate).startOf('day').toISOString()}&endDate=${moment(this.endDate).endOf('day').toISOString()}`);
        let days = [];
        let date = moment(this.startDate);
        let cellNames = Object.keys(data.cellsByDay);

        while (date.isBefore(this.endDate)) {
            days.push(date.format('YYYY-MM-DD'));
            date = date.add(1, 'day');
        }


        this.totals = {
            byCell: {},
            byDate: {},
            seconds: 0,
            tries: 0,
            points: 0,
            avgPointsPercent: 0,
            avgSecondsPercent: 0,
            numberOfTeams: data.numberOfTeams,
            cellTableRows: []
        };

        for (let cellName in data.cellsByDay) {
            let avgPointsPercentCount = 0;
            let avgSecondsPercentCount = 0;
            let dayCount = 0;
            this.totals.byCell[cellName] = {
                seconds: 0,
                tries: 0,
                avgPointsPercent: 0,
                avgSecondsPercent: 0,
                points: 0
            };
            for (let day of days) {
                if (!this.totals.byDate[day])
                    this.totals.byDate[day] = {
                        seconds: 0,
                        tries: 0
                    };
                if (data.cellsByDay[cellName].dates[day]) {
                    this.totals.byCell[cellName].seconds += data.cellsByDay[cellName].dates[day].seconds;
                    this.totals.seconds += data.cellsByDay[cellName].dates[day].seconds;

                    this.totals.byCell[cellName].tries += data.cellsByDay[cellName].dates[day].tries;
                    this.totals.tries += data.cellsByDay[cellName].dates[day].tries;

                    this.totals.byCell[cellName].points += data.cellsByDay[cellName].dates[day].points;
                    this.totals.points += data.cellsByDay[cellName].dates[day].points;

                    this.totals.byDate[day].seconds += data.cellsByDay[cellName].dates[day].seconds;
                    this.totals.byDate[day].tries += data.cellsByDay[cellName].dates[day].tries;

                    avgPointsPercentCount += data.cellsByDay[cellName].dates[day].avgPointsPercent;
                    avgSecondsPercentCount += data.cellsByDay[cellName].dates[day].avgSecondsPercent;
                }
                dayCount++;
            }

            this.totals.byCell[cellName].avgPointsPercent = avgPointsPercentCount / dayCount;
            this.totals.byCell[cellName].avgSecondsPercent = avgSecondsPercentCount / dayCount;
        }

        this.totals.avgPointsPercent = Object.values(this.totals.byCell).reduce((previous, current) => previous + current.avgPointsPercent, 0) / Object.values(this.totals.byCell).length;
        this.totals.avgSecondsPercent = Object.values(this.totals.byCell).reduce((previous, current) => previous + current.avgSecondsPercent, 0) / Object.values(this.totals.byCell).length;


        // AVERAGE POINTS GRAPH
        this.cellsByDayAveragePointsGraphData = this.newGraphData();
        this.cellsByDayAveragePointsGraphData.labels = days;

        this.cellsByDayTriesGraphData = this.newGraphData();
        this.cellsByDayTriesGraphData.labels = days;

        this.cellsByDayTriesGraphData.options.plugins.tooltip = {
            mode: 'index',
            callbacks: {
                label: (item) => {
                    var label = item.dataset.label || '';
                    if (label) {
                        label += ': ';
                    }
                    label += `${item.parsed.y.toFixed(2)}%`;
                    return label;
                }
            }
        };

        for (let cellName in data.cellsByDay) {
            this.cellsByDayAveragePointsGraphData.datasets.push({
                label: cellName,
                //   borderColor: color,
                //borderWidth: 0,
                //    backgroundColor: '#00000000',
                pointBorderColor: '#00000000',
                //  pointBackgroundColor: color,
                data: days.map(d => {
                    if (data.cellsByDay[cellName].dates[d])
                        return data.cellsByDay[cellName].dates[d].avgPointsPercent;
                    else return 0;
                })
            });
        }




        // AVERAGE SECONDS GRAPH
        this.cellsByDayAverageSecondsGraphData = this.newGraphData();
        this.cellsByDayAverageSecondsGraphData.labels = days;

        this.cellsByDayTriesGraphData.options.plugins.tooltip = {
            mode: 'index',
            callbacks: {
                label: (item) => {
                    var label = item.dataset.label || '';
                    let seconds = (item.dataset.data[item.dataIndex] as any).seconds;
                    if (label) {
                        label += ': ';
                    }
                    label += `${item.parsed.y.toFixed(2)}% (${seconds ? seconds.toFixed(2) : 0}s)`;
                    return label;
                }
            }
        };

        for (let cellName in data.cellsByDay) {
            this.cellsByDayAverageSecondsGraphData.datasets.push({
                label: cellName,
                //   borderColor: color,
                //borderWidth: 0,
                //   backgroundColor: '#00000000',
                pointBorderColor: '#00000000',
                //  pointBackgroundColor: color,
                data: days.map(d => {
                    if (data.cellsByDay[cellName].dates[d])
                        return { x: d, y: data.cellsByDay[cellName].dates[d].avgSecondsPercent, seconds: data.cellsByDay[cellName].dates[d].avgSeconds };
                    else return { x: d, y: 0, seconds: 0 };
                })
            });
        }



        // TRIES GRAPH
        this.cellsByDayTriesGraphData = this.newGraphData();
        this.cellsByDayTriesGraphData.labels = days;

        this.cellsByDayTriesGraphData.options.plugins.tooltip = {
            mode: 'index',
            callbacks: {
                label: (item) => {
                    var label = item.dataset.label || '';
                    if (label) {
                        label += ': ';
                    }
                    label += `${item.parsed.y}`;
                    return label;
                }
            }
        };

        for (let cellName in data.cellsByDay) {
            this.cellsByDayTriesGraphData.datasets.push({
                label: cellName,
                //   borderColor: color,
                //borderWidth: 0,
                //     backgroundColor: '#00000000',
                pointBorderColor: '#00000000',
                //  pointBackgroundColor: color,
                data: days.map(d => {
                    if (data.cellsByDay[cellName].dates[d])
                        return data.cellsByDay[cellName].dates[d].tries;
                    else return 0;
                })
            });
        }


        // TIME IN CELL GRAPH
        this.cellsByDayTimeInCellGraphData = this.newGraphData();
        this.cellsByDayTimeInCellGraphData.labels = days;
        (this.cellsByDayTimeInCellGraphData.options.scales.y as any).stacked = true;
        this.cellsByDayTriesGraphData.options.plugins.tooltip = {
            mode: 'index',
            callbacks: {
                label: (item) => {
                    var label = item.dataset.label || '';
                    if (label) {
                        label += ': ';
                    }
                    label += `${item.parsed.y.toFixed(2)}%`;
                    return label;
                }
            }
        };

        for (let cellName in data.cellsByDay) {
            this.cellsByDayTimeInCellGraphData.datasets.push({
                label: cellName,
                //   borderColor: color,
                //borderWidth: 0,
                //   backgroundColor: '#00000000',
                pointBorderColor: '#00000000',
                //  pointBackgroundColor: color,
                data: days.map(d => {
                    if (data.cellsByDay[cellName].dates[d])
                        return { x: d, y: data.cellsByDay[cellName].dates[d].seconds / this.totals.byDate[d].seconds * 100 };
                    else return { x: d, y: 0, seconds: 0 };
                })
            });
        }



        // TIME IN CELL DONUT GRAPH
        this.timeInCellDonutGraphData = this.newDonutGraphData();
        this.timeInCellDonutGraphData.labels = cellNames;
        this.timeInCellDonutGraphData.options.plugins.tooltip = {
            mode: 'index',
            callbacks: {
                label: (item) => {
                    var label = item.dataset.label || '';
                    let value = item.dataset.data[item.dataIndex] as number;
                    if (label) {
                        label += ': ';
                    }
                    console.log(value);
                    console.log(label);
                    label += `${value.toFixed(2)}%`;
                    return label;
                }
            }
        };
        this.timeInCellDonutGraphData.datasets.push({
            //   borderColor: color,
            //borderWidth: 0,
            //   backgroundColor: '#00000000',
            pointBorderColor: '#00000000',
            //  pointBackgroundColor: color,
            data: cellNames.map(cellName => {
                return this.totals.byCell[cellName].seconds / this.totals.seconds * 100;
            })
        });


        // MAX TEAM SCORE PER CELL GRAPH
        this.maxTeamScorePerCellGraphData = this.newBarGraphData();
        this.maxTeamScorePerCellGraphData.labels = cellNames;
        this.maxTeamScorePerCellGraphData.options.plugins.legend = {
            display: false
        };
        this.cellsByDayTriesGraphData.options.plugins.tooltip = {
            mode: 'index',
            callbacks: {
                label: (item) => {
                    var label = item.dataset.label || '';
                    if (label) {
                        label += ': ';
                    }
                    label += `${item.parsed.y.toFixed(2)}`;
                    return label;
                }
            }
        };
        this.maxTeamScorePerCellGraphData.datasets.push({
            //   borderColor: color,
            //borderWidth: 0,
            //   backgroundColor: '#00000000',
            pointBorderColor: '#00000000',
            //  pointBackgroundColor: color,
            data: cellNames.map(cellName => {
                return data.cellTeamData[cellName].averagePoints;
            })
        });

        for (let cellName in this.totals.byCell) {
            this.totals.cellTableRows.push({
                name: cellName,
                tries: this.totals.byCell[cellName].tries,
                triesPerTeam: this.totals.byCell[cellName].tries / this.totals.numberOfTeams,
                averageTimePerTry: this.totals.byCell[cellName].seconds / this.totals.byCell[cellName].tries,
                averageScorePerTry: this.totals.byCell[cellName].points / this.totals.byCell[cellName].tries,
                averageMaxScore: data.cellTeamData[cellName].averagePoints,
            });
        }

        this.sortCellTable();


        this.loading = false;
    }

    sortCellTable(field?: string) {
        if (field) {
            if (this.cellTableSort.field == field) {
                if (this.cellTableSort.order == 'asc')
                    this.cellTableSort.order = 'desc';
                else
                    this.cellTableSort.order = 'asc';
            }
            else {
                this.cellTableSort = {
                    field: field,
                    order: 'asc'
                };
            }
        }
        if (this.cellTableSort.order == 'asc')
            this.totals.cellTableRows.sort(orderBy(this.cellTableSort.field).asc);
        else
            this.totals.cellTableRows.sort(orderBy(this.cellTableSort.field).desc);

    }

    async ngOnInit() {
        this.startDate = moment().subtract(1, 'month').toDate();
        this.endDate = moment().subtract(1, 'day').toDate();
        this.fetchData();
    }

    async ngOnDestroy() {

    }
}
