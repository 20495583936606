export interface V {
    ci: string;
    co: string;
    dn: number;
    dt: string;
    is: string;
    ma: string;
    mp: string;
    sd: number;
    tg: string;
    vp: string;
}

export interface T {
    ci: string;
    co: string;
    is: string;
    ma: string;
    sc: Date;
    tc: string;
    tg: string;
    tr: string;
    tt: string;
}

export interface R {
    tg: string;
    fr: string;
    co: string;
    is: string;
    df: string;
    du: string;
    ci: string;
}
export interface Nam {
    fn: string;
    gn: string;
    fnt: string;
    gnt: string;
}

export interface DCCObject {
    v: V[];
    t: T[];
    r: R[];
    dob: string;
    nam: Nam;
    ver: string;
}


export const getVaccineText = (product: string) => {
    let products = {
        "valueSetId": "vaccines-covid-19-names",
        "valueSetDate": "2021-04-27",
        "valueSetValues": {
            "EU/1/20/1528": {
                "display": "Comirnaty",
                "lang": "en",
                "active": true,
                "system": "https://ec.europa.eu/health/documents/community-register/html/",
                "version": ""
            },
            "EU/1/20/1507": {
                "display": "COVID-19 Vaccine Moderna",
                "lang": "en",
                "active": true,
                "system": "https://ec.europa.eu/health/documents/community-register/html/",
                "version": ""
            },
            "EU/1/21/1529": {
                "display": "Vaxzevria",
                "lang": "en",
                "active": true,
                "system": "https://ec.europa.eu/health/documents/community-register/html/",
                "version": ""
            },
            "EU/1/20/1525": {
                "display": "COVID-19 Vaccine Janssen",
                "lang": "en",
                "active": true,
                "system": "https://ec.europa.eu/health/documents/community-register/html/",
                "version": ""
            },
            "CVnCoV": {
                "display": "CVnCoV",
                "lang": "en",
                "active": true,
                "system": "http://ec.europa.eu/temp/vaccineproductname",
                "version": "1.0"
            },
            "Sputnik-V": {
                "display": "Sputnik-V",
                "lang": "en",
                "active": true,
                "system": "http://ec.europa.eu/temp/vaccineproductname",
                "version": "1.0"
            },
            "Convidecia": {
                "display": "Convidecia",
                "lang": "en",
                "active": true,
                "system": "http://ec.europa.eu/temp/vaccineproductname",
                "version": "1.0"
            },
            "EpiVacCorona": {
                "display": "EpiVacCorona",
                "lang": "en",
                "active": true,
                "system": "http://ec.europa.eu/temp/vaccineproductname",
                "version": "1.0"
            },
            "BBIBP-CorV": {
                "display": "BBIBP-CorV",
                "lang": "en",
                "active": true,
                "system": "http://ec.europa.eu/temp/vaccineproductname",
                "version": "1.0"
            },
            "Inactivated-SARS-CoV-2-Vero-Cell": {
                "display": "Inactivated SARS-CoV-2 (Vero Cell)",
                "lang": "en",
                "active": true,
                "system": "http://ec.europa.eu/temp/vaccineproductname",
                "version": "1.0"
            },
            "CoronaVac": {
                "display": "CoronaVac",
                "lang": "en",
                "active": true,
                "system": "http://ec.europa.eu/temp/vaccineproductname",
                "version": "1.0"
            },
            "Covaxin": {
                "display": "Covaxin (also known as BBV152 A, B, C)",
                "lang": "en",
                "active": true,
                "system": "http://ec.europa.eu/temp/vaccineproductname",
                "version": "1.0"
            }
        }
    };

    if (products.valueSetValues[product]) {
        return products.valueSetValues[product].display;
    } else {
        return '';
    }
};