import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Api } from '../../../../../services/api';
import { DomSanitizer } from '@angular/platform-browser';
import { IBooking, IBookingAddOn } from '../../../../../../../../../src/app/interfaces/booking';
import { Globals } from '../../../../../services/globals';
import { CurrentUser } from '../../../../../services/user';
import { IPrisonIslandParticipant, IPrisonIslandTeam } from '../../../../../../../../../../../../common/common-third-party/prison-island/interfaces';
import { Utility } from '../../../../../services/utility';


@Component({
    selector: 'prison-island-registration-dialog',
    templateUrl: './prison-island-registration-dialog.component.html',
    styleUrls: ['./prison-island-registration-dialog.component.scss']
})

export class PrisonIslandRegistrationDialogComponent implements OnInit {
    public booking: IBooking;
    selectedTeam: IPrisonIslandTeam = null;
    selectedParticipant: IPrisonIslandParticipant = null;
    loading: boolean;
    editTeamData: {
        name: string,
        tagId: string
    };
    scanSucceeded: boolean;
    
    allTeams: IPrisonIslandTeam;
    
    showGoBack: boolean = false;

    prisonIslandSettings: any;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog, private dialogRef: MatDialogRef<PrisonIslandRegistrationDialogComponent>,
        private translate: TranslateService, private api: Api, private sanitizer: DomSanitizer, public globals: Globals, public currentUser: CurrentUser, public utility: Utility) {
        this.booking = data.booking;

        this.dialogRef.backdropClick().subscribe(async () => {
            await this.close();
        });
    }


    async close(): Promise<void> {
        this.dialogRef.close();
        this.data.releaseQRScanHijack();
    }

    async ngOnInit(): Promise<void> {
        this.data.hijackQRScan((data:any) => {
            this.tagScanned(data);
        });

        this.prisonIslandSettings = this.globals.clientSettings.gameIntegrationSettings.prisonIslandSettings;
        this.allTeams = this.booking.gameIntegrationData.prisonIslandData.teams;
    }

    async selectTeam(team: IPrisonIslandTeam): Promise<void> {
        this.selectedTeam = team;
        this.showGoBack = true;
        this.editTeamData = {
            name: this.selectedTeam.name,
            tagId: this.selectedTeam.tagId
        };
    }

    async removeSelectedParticipant(): Promise<void> {
        this.loading = true;
        let result = await this.api.client().delete<any>(`/bookings/${this.booking.id}/third-party/prison-island/participant/${this.selectedParticipant.identifier}`);
        if (result.succeeded) {
            this.booking.gameIntegrationData.prisonIslandData = result.data;
        }

        delete this.selectedParticipant;
        this.showGoBack = false;
        this.loading = false;
    }

    async saveSelectedParticipant(): Promise<void> {
        this.loading = true;
        let result = await this.api.client().put<any>(`/bookings/${this.booking.id}/third-party/prison-island/participant`, this.selectedParticipant);
        if (result.succeeded) {

        }

        delete this.selectedParticipant;
        this.showGoBack = false;
        this.loading = false;
    }

    async selectParticipant(participant: IPrisonIslandParticipant): Promise<void>{
        this.selectedParticipant = participant;
        this.showGoBack = true;
    }

    getTeamParticipants(team: IPrisonIslandTeam): Promise<void>{
        return this.booking.gameIntegrationData.prisonIslandData.participants.filter(p => p.teamIdentifier == team.identifier);
    }

    getTeamParticipantsWithoutTeam(): Promise<void>{
        return this.booking.gameIntegrationData.prisonIslandData.participants.filter(p => !p.teamIdentifier);
    }



    async newTeam(): Promise<void> {
        let newTeam = {
            identifier: this.utility.uuid(),
            name: ''
        };
        this.booking.gameIntegrationData.prisonIslandData.teams.push(newTeam);
        this.api.client().put<any>(`/bookings/${this.booking.id}/third-party/prison-island/team`, newTeam);
    }

    async newParticipant(team?: IPrisonIslandTeam) : Promise<void>{
        let teamIdentifier = team.identifier ? team.identifier : null;

        let teams = this.booking.gameIntegrationData.prisonIslandData.teams;

        let newParticipant: IPrisonIslandParticipant = {
            identifier: this.utility.uuid(),
            role: 'PARTICIPANT',
            name: '',
            teamIdentifier: teams.length == 1 ? teams[0].identifier : teamIdentifier 
        };

        this.booking.gameIntegrationData.prisonIslandData.participants.push(newParticipant);
        this.selectedParticipant = newParticipant;
        this.api.client().put<any>(`/bookings/${this.booking.id}/third-party/prison-island/participant`, newParticipant);
    }

    async saveTeam() : Promise<void>{
        this.loading = true;
        let result = await this.api.client().put<any>(`/bookings/${this.booking.id}/third-party/prison-island/team`, Object.assign({}, this.selectedTeam, this.editTeamData));
        if (result.succeeded) {
            Object.assign(this.selectedTeam, this.editTeamData);
        }
        delete this.selectedTeam;
        delete this.editTeamData;
        this.scanSucceeded = false;
        this.loading = false;
        this.showGoBack = false;
    }

    async removeTeam() : Promise<void>{
        this.loading = true;
        let result = await this.api.client().delete<any>(`/bookings/${this.booking.id}/third-party/prison-island/team/${this.selectedTeam.identifier}`);
        if (result.succeeded) {
            this.booking.gameIntegrationData.prisonIslandData = result.data;
        }

        delete this.selectedTeam;
        delete this.editTeamData;

        this.allTeams = this.booking.gameIntegrationData.prisonIslandData.teams;
        this.showGoBack = false;
        this.loading = false;
    }

    async tagScanned(data: string) : Promise<void>{

        // Fix if focus is on team name data
        if (this.editTeamData.name) {
            this.editTeamData.name = this.editTeamData.name.replace(data, '');
        }

        let keyboardType = this.prisonIslandSettings.keyboardType;
        console.log(keyboardType);

        //AZERTY
        if (keyboardType == 1) {
            data = data.replace(/a/g, "q");
            data = data.replace(/z/g, "w");
            data = data.replace(/q/g, "a");
            data = data.replace(/w/g, "z");
            data = data.replace(/,/g, "m");
            data = data.replace(/&/g, "1");
            data = data.replace(/é/g, "2");
            data = data.replace(/"/g, "3");
            data = data.replace(/'/g, "4");
            while (data.indexOf("(") > -1) {
                data = data.replace('(', "5");
            }
            data = data.replace(/-/g, "6");
            data = data.replace(/è/g, "7");
            data = data.replace(/_/g, "8");
            data = data.replace(/ç/g, "9");
            data = data.replace(/à/g, "0");

        }
        //AZERTY (Belgium)
        if (keyboardType == 2) {
            data = data.replace(/a/g, "q");
            data = data.replace(/z/g, "w");
            data = data.replace(/q/g, "a");
            data = data.replace(/w/g, "z");
            data = data.replace(/,/g, "m");
            data = data.replace(/&/g, "1");
            data = data.replace(/é/g, "2");
            data = data.replace(/"/g, "3");
            data = data.replace(/'/g, "4");
            while (data.indexOf("(") > -1) {
                data = data.replace('(', "5");
            }
            data = data.replace(/§/g, "6");
            data = data.replace(/è/g, "7");
            data = data.replace(/!/g, "8");
            data = data.replace(/ç/g, "9");
            data = data.replace(/à/g, "0");
        }

        console.log(`Key without checksum: ${data}`);

        // Checksum stuff
        if (data.length == 10) {
            data = data.slice(Math.max(data.length - 10, 0));

            // Applying checksum to the key
            var checkSum = parseInt(data[0] + data[1], 16) ^ parseInt(data[2] + data[3], 16) ^ parseInt(data[4] + data[5], 16) ^ parseInt(data[6] + data[7], 16) ^ parseInt(data[8] + data[9], 16);
            var keyTag;
            if (checkSum.toString(16).length > 1)
                keyTag = data + checkSum.toString(16);
            else
                keyTag = data + '0' + checkSum.toString(16);

            //var keyTag = data + checkSum.toString(16)

            keyTag = keyTag.toUpperCase();
            data = keyTag;

            console.log(`Key with checksum: ${data}`);
        }


        if (this.editTeamData) {
            this.editTeamData.tagId = data;
            this.scanSucceeded = true;
        }


    }


    goBack(): void {
        this.selectedTeam = null;
        this.selectedParticipant = null;
        this.showGoBack = false;
    }

    ngOnDestroy(): void {

    }
}
