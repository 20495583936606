import { Injectable } from '@angular/core';
import moment from 'moment';
import { addDurationToTime, minutesFromDuration, shiftTimeByMinutes, subtractDurationFromTime } from '../../../../../../common/common-helpers/utility';
import { Utility } from './utility';


@Injectable()
export class ExtraActivityHelper {
    constructor() {

    }


    getExtraActivityTimeslots(extraActivity: any, activitiesTimeslots: any, selectedPackage: any, selectedTime: string, selectedDate: string, selectedPersons: number) {
        let result: any = {};
        if (activitiesTimeslots && activitiesTimeslots[extraActivity.activityId]) {
            let activity = activitiesTimeslots[extraActivity.activityId].activity;
            let timeslots = activitiesTimeslots[extraActivity.activityId].timeslots;

            // Check if extra activity is available before package start
            if (extraActivity.allowBefore) {
                var beforeStartTime = selectedTime;
                // First, find the start date of the extra activity
                for (var i = 0; i < extraActivity.slots; i++)
                    beforeStartTime = subtractDurationFromTime(beforeStartTime, activity.timeslotInterval);

                // Check that all slots are available for the extra activity
                var beforeAvailable = true;
                var currentBeforeTime = beforeStartTime;
                for (var i = 0; i < extraActivity.slots; i++) {
                    if (!timeslots.dates[selectedDate]
            || !timeslots.dates[selectedDate][currentBeforeTime]
            || timeslots.dates[selectedDate][currentBeforeTime].availableSlots < selectedPersons) {
                        beforeAvailable = false;
                    }
                    currentBeforeTime = addDurationToTime(currentBeforeTime, activity.timeslotInterval);
                }

                // Add the before time to results if it is available
                if (beforeAvailable) {
                    result.before = {
                        time: beforeStartTime,
                        minPeoplePerBooking: activity.minPeoplePerBooking,
                        maxPeoplePerBooking: activity.maxPeoplePerBooking,
                        customerGroupIds: timeslots.dates[selectedDate][beforeStartTime].customerGroupIds,
                        hasBookings: timeslots.dates[selectedDate][beforeStartTime].hasBookings
                    };
                }
            }

            // Check if extra activity is available at package start
            if (extraActivity.allowDuring) {

                // Check that all slots are available for the extra activity
                var duringAvailable = true;
                var currentDuringTime = selectedTime;
                var currentDate = selectedDate;
                for (var i = 0; i < extraActivity.slots; i++) {
                    if (!timeslots.dates[currentDate]
            || !timeslots.dates[currentDate][currentDuringTime]
            || timeslots.dates[currentDate][currentDuringTime].availableSlots < selectedPersons) {
                        duringAvailable = false;
                    }


                    // Package allows midnight overflow
                    if (selectedPackage.allowMidnightOverflow) {
                        let newStartTime = addDurationToTime(currentDuringTime, activity.timeslotInterval, selectedPackage.allowMidnightOverflow);
                        let newStartTimeMinutes = minutesFromDuration(newStartTime);
                        if (newStartTimeMinutes >= 24 * 60) {
                            currentDate = moment(currentDate).add(1, 'day').format('YYYY-MM-DD');
                            currentDuringTime = shiftTimeByMinutes('00:00', newStartTimeMinutes - 24 * 60);
                        }
                        else {
                            currentDuringTime = newStartTime;
                        }
                    }
                    else {
                        currentDuringTime = addDurationToTime(currentDuringTime, activity.timeslotInterval);
                    }


                }

                // Add the during time to results if it is available
                if (duringAvailable) {
                    result.during = {
                        time: selectedTime,
                        minPeoplePerBooking: activity.minPeoplePerBooking,
                        maxPeoplePerBooking: activity.maxPeoplePerBooking,
                        customerGroupIds: timeslots.dates[selectedDate][selectedTime].customerGroupIds,
                        hasBookings: timeslots.dates[selectedDate][selectedTime].hasBookings
                    };
                }
            }

            // Check if extra activity is available after package end
            if (extraActivity.allowAfter) {
                var currentDate = selectedDate;
                let afterStartTime;

                // Package allows midnight overflow
                if (selectedPackage.allowMidnightOverflow) {
                    let newStartTime = addDurationToTime(selectedTime, selectedPackage.duration, selectedPackage.allowMidnightOverflow);
                    let newStartTimeMinutes = minutesFromDuration(newStartTime);
                    if (newStartTimeMinutes >= 24 * 60) {
                        currentDate = moment(currentDate).add(1, 'day').format('YYYY-MM-DD');
                        afterStartTime = shiftTimeByMinutes('00:00', newStartTimeMinutes - 24 * 60);
                    }
                    else {
                        afterStartTime = newStartTime;
                    }
                }
                // Get the package end time
                else {
                    afterStartTime = addDurationToTime(selectedTime, selectedPackage.duration);
                }



                // Check that all slots are available for the extra activity
                var afterAvailable = true;
                var currentAfterTime = afterStartTime;
                for (var i = 0; i < extraActivity.slots; i++) {
                    if (!timeslots.dates[currentDate]
            || !timeslots.dates[currentDate][currentAfterTime]
            || timeslots.dates[currentDate][currentAfterTime].availableSlots < selectedPersons) {
                        afterAvailable = false;
                    }

                    // Package allows midnight overflow
                    if (selectedPackage.allowMidnightOverflow) {
                        let newStartTime = addDurationToTime(currentAfterTime, activity.timeslotInterval, selectedPackage.allowMidnightOverflow);
                        let newStartTimeMinutes = minutesFromDuration(newStartTime);
                        if (newStartTimeMinutes >= 24 * 60) {
                            currentDate = moment(currentDate).add(1, 'day').format('YYYY-MM-DD');
                            currentAfterTime = shiftTimeByMinutes('00:00', newStartTimeMinutes - 24 * 60);
                        }
                        else {
                            currentAfterTime = newStartTime;
                        }
                    }
                    else {
                        currentAfterTime = addDurationToTime(currentAfterTime, activity.timeslotInterval);
                    }
                }

                // Add the after time to results if it is available
                if (afterAvailable) {
                    result.after = {
                        time: afterStartTime,
                        minPeoplePerBooking: activity.minPeoplePerBooking,
                        maxPeoplePerBooking: activity.maxPeoplePerBooking,
                        customerGroupIds: timeslots.dates[selectedDate][afterStartTime].customerGroupIds,
                        hasBookings: timeslots.dates[selectedDate][afterStartTime].hasBookings
                    };
                }
            }
        }
        return result;
    }
}
