// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.price-input .ex-vat {
  font-size: 10px;
  position: absolute;
  bottom: 0;
  padding-left: 15px;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/shared/price-input-new/price-input-new.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/shared/price-input-new/price-input-new.component.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,kBAAA;EACA,SAAA;EACA,kBAAA;ACAJ","sourcesContent":[".price-input {\r\n  .ex-vat {\r\n    font-size:10px;\r\n    position: absolute;\r\n    bottom: 0;\r\n    padding-left: 15px;\r\n  }\r\n}\r\n",".price-input .ex-vat {\n  font-size: 10px;\n  position: absolute;\n  bottom: 0;\n  padding-left: 15px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
