import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Api } from '../../../../services/api';
import { Globals } from '../../../../services/globals';
import { Utility } from '../../../../services/utility';
import { CurrentUser } from '../../../../services/user';

@Component({
    selector: 'person-per-person-customer-group-rule',
    templateUrl: './person-per-person-customer-group-rule.component.html',
})

export class PersonPerPersonCustomerGroupRuleComponent implements OnInit, OnChanges {

    @Input()
        customerRule: any;

    @Input()
        availableCustomerGroups: any[];

    @Output()
        ruleChanged = new EventEmitter();

    data: any;

    constructor(private api: Api, private router: Router, public globals: Globals, private utility: Utility, public currentUser: CurrentUser) {}

    async ngOnInit() {
        console.log(this.customerRule.data);

        if(!this.customerRule.data) {
            this.customerRule.data = {};
        }

        this.data = this.customerRule.data;

        if(!this.data.firstGroup) {
            this.data.firstGroup = {};
        }

        if(!this.data.secondGroup) {
            this.data.secondGroup = {};
        }

    }
    
    ngOnChanges(changes: SimpleChanges): void {
        this.ruleChanged.emit(this.customerRule);
    }
}
