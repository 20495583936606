import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

import { Globals } from '../../../services/globals';
import { IResource } from '../../../interfaces/resource';
import { Api } from '../../../services/api';
import { GenerateTimeslots, CloneTimeslots } from '../../../helpers/timeslot-generator';
import { IPrice } from '../../../interfaces/price';
import { cloneDeep } from 'lodash';
import { MatTable } from '@angular/material/table';

@Component({
    selector: 'customer-group-prices',
    templateUrl: './customer-group-prices.component.html',
    styleUrls: ['./customer-group-prices.component.scss']
})

export class CustomerGroupPricesComponent implements OnInit, OnDestroy {
    @Input()
        customerGroupPrices: { [customerGroupId: string]: { enabled: boolean, name: string, price: IPrice } };
    @Input()
        defaultPrice: IPrice;
    availableCustomerGroups: any[] = [];

    @ViewChild('customerGroupPricesTable', { static: false }) customerGroupPricesTable: MatTable<any>;

    displayedColumns = ['toggle', 'name', 'price'];

    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals) {

    }

    async customerGroupToggled(customerGroup) {

    }

    async ngAfterViewInit() {

    }

    async ngOnInit() {
        this.availableCustomerGroups = await this.api.client().get<any[]>('/manage/customer-groups');
        for (let customerGroup of this.availableCustomerGroups) {
            if (!this.customerGroupPrices[customerGroup.id]) {
                this.customerGroupPrices[customerGroup.id] = {
                    name: customerGroup.name,
                    enabled: false,
                    price: cloneDeep(this.defaultPrice)
                };
            }
            // Update name if it differs
            else if (customerGroup.name != this.customerGroupPrices[customerGroup.id].name) {
                this.customerGroupPrices[customerGroup.id].name = customerGroup.name;
            }

            //delete customer group if its disabled in availableCustomerGroups
            if (customerGroup.disabled) {
                delete this.customerGroupPrices[customerGroup.id];
            }
            

        }

        this.availableCustomerGroups = this.availableCustomerGroups.filter(c => !c.disabled);

        this.customerGroupPricesTable.renderRows();
    }

    async ngOnDestroy() {

    }
}
