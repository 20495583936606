import { Component, OnInit } from '@angular/core';
import { Api } from '../../services/api';
import { Router } from '@angular/router';
import { orderBy } from '../../helpers/utility';
import { Globals } from '../../services/globals';
import { IBookingForm } from '../../interfaces/booking-form';
import { IDepartment } from '../../../../../../../../../common/common-interfaces/department';


@Component({
    selector: 'departments',
    templateUrl: './departments.component.html'
})

export class DepartmentsComponent implements OnInit {
    departments: IDepartment[] | null = null;
    displayedColumns = ['name', 'edit'];

    showDisabled: boolean = false;

    constructor(private api: Api, private router: Router, public globals: Globals) {

    }


    async ngOnInit() {
        this.departments = await this.api.client().get<IDepartment[]>('/manage/departments');
        this.departments.sort(orderBy('name').asc);
    }
}
