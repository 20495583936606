import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { IEvent } from '../../../../../../../../../../common/common-interfaces/event';
import { orderBy } from '../../../helpers/utility';
import { IActivity } from '../../../interfaces/activity';
import { IAddOn } from '../../../interfaces/add-on';
import { IPackage } from '../../../interfaces/package';
import { Api } from '../../../services/api';
import { Currency } from '../../../services/currency';
import { Globals } from '../../../services/globals';
import { CurrentUser } from '../../../services/user';
import { Utility } from '../../../services/utility';
import { ICashRegisterArticle } from './article-number-chooser/article-number-chooser.component';



@Component({
    selector: 'cash-register-settings',
    styleUrls: ['./cash-register-settings.component.scss'],
    templateUrl: './cash-register-settings.component.html'
})

export class CashRegisterSettingsComponent implements OnInit {
  @Input()
      clientSettings: any = null;

  packages: IPackage[];
  addOns: IAddOn[];
  activities: IActivity[];
  events: IEvent[];


  articles: ICashRegisterArticle[];
  articlesByArticleNumber: { [articleNumber: string]: ICashRegisterArticle } = {};
  filteredOptions: Observable<ICashRegisterArticle[]>;

  showDisabled: boolean;


  constructor(private api: Api, private router: Router, private route: ActivatedRoute, public globals: Globals, private utility: Utility, public currentUser: CurrentUser, public currency: Currency, private translate: TranslateService) {

  }

  async save() {
      let result = await this.api.client().patch<any>(`/manage/settings`, this.clientSettings);
  }


  async savePackage(_package: IPackage) {
      await this.api.client().patch(`/manage/packages/${_package.id}`, _package);
  }


  async saveAddOn(addOn: IAddOn) {
      await this.api.client().patch(`/manage/add-ons/${addOn.id}`, { addOn: addOn });
  }

  async saveActivity(activity: IActivity) {
      await this.api.client().patch(`/manage/activities/${activity.id}`, activity);
  }

  async saveEvent(event: IEvent) {
      await this.api.client().patch(`/manage/events/${event.id}`, event);
  }


  async fetchStuff() {
      this.articles = await this.api.client().get<ICashRegisterArticle[]>('/cash-register/articles');
      this.articles.sort(orderBy('name').asc);
      for (let article of this.articles) {
          this.articlesByArticleNumber[article.articleNumber] = article;
      }

      this.packages = await this.api.client().get<IPackage[]>('/manage/packages');
      this.packages = this.packages.filter(p => !p.disabled || this.showDisabled);
      this.packages.sort(orderBy('name').asc);

      this.addOns = await this.api.client().get<IAddOn[]>('/manage/add-ons');
      this.addOns = this.addOns.filter(a => !a.disabled || this.showDisabled);
      this.addOns.sort(orderBy('name').asc);

      this.activities = await this.api.client().get<IActivity[]>('/manage/activities');
      this.activities = this.activities.filter(a => !a.disabled || this.showDisabled);
      this.activities.sort(orderBy('name').asc);

      this.events = await this.api.client().get<IEvent[]>('/manage/events');
      this.events = this.events.filter(e => !e.disabled || this.showDisabled);
      this.events.sort(orderBy('name').asc);
  }

  async ngOnInit() {
      await this.fetchStuff();
  }

  getUnmappedArticlesNoticeText() {
      return this.translate.instant('UNMAPPED_ARTICLES_NOTICE').replace('#AMOUNT', this.getNumberOfUnmappedArticleNumbers());
  }

  getNumberOfUnmappedArticleNumbers() {
      let isEmpty = (articleNumber: string) => {
          return !articleNumber
        || articleNumber == ''
        || (this.articles && !this.articles.map(a => a.articleNumber).includes(articleNumber));
      };
      let result = 0;

      // PACKAGES
      if (this.packages) {
          result = this.packages.reduce((value, _package) => {
              let packageValue = value;

              if (isEmpty(_package.articleNumber))
                  packageValue++;

              packageValue = _package.priceSettings.reduce((value, priceSetting) => {
                  return priceSetting.extraOrderRows.reduce((value, orderRow) => {
                      if (isEmpty(orderRow.articleNumber))
                          return value + 1;
                      else
                          return value;
                  }, value);
              }, packageValue);
              return packageValue;
          }, result);
      }

      // ADD-ONS
      if (this.addOns) {
          result = this.addOns.reduce((value, addOn) => {
              if (isEmpty(addOn.articleNumber))
                  return value + 1;
              else
                  return value;
          }, result);
      }

      // ACTIVITIES
      if (this.activities) {
          result = this.activities.reduce((value, activity) => {
              if (isEmpty(activity.articleNumber))
                  return value + 1;
              else
                  return value;
          }, result);
      }


      // EVENTS
      if (this.events) {
          result = this.events.reduce((value, event) => {
              let eventValue = value;

              if (isEmpty(event.articleNumber))
                  eventValue++;

              eventValue = event.priceSettings.extraOrderRows.reduce((value, orderRow) => {
                  if (isEmpty(orderRow.articleNumber))
                      return value + 1;
                  else
                      return value;
              }, eventValue);

              return eventValue;
          }, result);
      }

      return result;
  }

  hasCharities() {
      return this.clientSettings &&
      this.clientSettings.charitySettings &&
      Object.keys(this.clientSettings.charitySettings.enabledCharities).length;
  }

  getDifferingVatRatesNoticeText() {
      return this.translate.instant('DIFFERING_VAT_RATES_NOTICE').replace('#AMOUNT', this.getNumberOfDifferingVatRates());
  }

  canInitExternalJournal() : boolean {
      return this.clientSettings 
        && this.clientSettings.cashRegisterSettings.provider == 'onslip'
        && this.clientSettings.cashRegisterSettings 
        && this.clientSettings.cashRegisterSettings.onslipSettings 
        && !this.clientSettings.cashRegisterSettings.onslipSettings.oAuthInfo 
        && this.clientSettings.cashRegisterSettings.onslipSettings.useExternalJournal 
          ? true 
          : false;
  }

  async initExternalJournal() {
      if(this.canInitExternalJournal()) {
          let url = await this.api.clientUrl(`/manage/third-party/onslip/init-external-journal`);
          window.location.href = url;
      }
  }

  getNumberOfDifferingVatRates() {

      let result = 0;

      // PACKAGES
      if (this.packages) {
          result = this.packages.reduce((value, _package) => {
              let packageValue = value;



              packageValue = _package.priceSettings.reduce((value, priceSetting) => {
                  if (this.getDifferingVatRate(priceSetting.price.vatPercentage, _package.articleNumber))
                      value++;
                  return priceSetting.extraOrderRows.reduce((value, orderRow) => {
                      if (this.getDifferingVatRate(orderRow.price.vatPercentage, orderRow.articleNumber))
                          return value + 1;
                      else
                          return value;
                  }, value);
              }, packageValue);
              return packageValue;
          }, result);
      }

      // ADD-ONS
      if (this.addOns) {
          result = this.addOns.reduce((value, addOn) => {
              if (this.getDifferingVatRate(addOn.price.vatPercentage, addOn.articleNumber))
                  return value + 1;
              else
                  return value;
          }, result);
      }

      // ACTIVITIES
      if (this.activities) {
          result = this.activities.reduce((value, activity) => {
              if (this.getDifferingVatRate(activity.price.vatPercentage, activity.articleNumber))
                  return value + 1;
              else
                  return value;
          }, result);
      }


      // EVENTS
      if (this.events) {
          result = this.events.reduce((value, event) => {
              let eventValue = value;

              if (this.getDifferingVatRate(event.priceSettings.price.vatPercentage, event.articleNumber))
                  eventValue++;

              eventValue = event.priceSettings.extraOrderRows.reduce((value, orderRow) => {
                  if (this.getDifferingVatRate(orderRow.price.vatPercentage, orderRow.articleNumber))
                      return value + 1;
                  else
                      return value;
              }, eventValue);

              return eventValue;
          }, result);
      }

      return result;
  }


  getDifferingVatRate(vatRate: number, articleNumber: string) {
      if (this.articlesByArticleNumber
      && this.articlesByArticleNumber[articleNumber]
      && !['null', 'undefined'].includes(typeof (this.articlesByArticleNumber[articleNumber].defaultVatRate))
      && this.articlesByArticleNumber[articleNumber].defaultVatRate != vatRate) {
          return this.articlesByArticleNumber[articleNumber].defaultVatRate;
      }
  }


}
