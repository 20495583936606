import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'customer-groups-chooser',
    templateUrl: './customer-groups-chooser.component.html',
    styleUrls: ['./customer-groups-chooser.component.scss']
})

export class CustomerGroupsChooserComponent implements OnInit {
    @Output()
        quantityChanged = new EventEmitter();

    @Output()
        enabledCustomerGroupIdChanged = new EventEmitter();

    @Input()
        value: number = 0;
    @Input()
        min: number = 0;
    @Input()
        max: number = 999;
    @Input()
        availableCustomerGroups: any[];
    @Input()
        customerGroups: any[];
    @Input()
        exclusive: boolean;
    @Input()
        hideUnavailable: boolean;

    initialValues: { [customerGroupId: string]: number } = {};
    enabledCustomerGroupId: string;

    constructor(private translate: TranslateService) {

    }

    isHidden(customerGroupId) {
        return this.exclusive
            && this.hideUnavailable
            && (customerGroupId != this.enabledCustomerGroupId);
    }

    isDisabled(customerGroupId) {
        return this.exclusive
            && (customerGroupId != this.enabledCustomerGroupId && this.totalQuantity() > 0);
    }

    totalQuantity() {
        return this.customerGroups.map(cg => cg.quantity).reduce((sum, val) => sum + val, 0);
    }


    async personsChanged(customerGroup, data) {
        let group = this.customerGroups.find(g => g.customerGroupId == customerGroup.customerGroupId);
        let oldQuantity = group.quantity;
        group.quantity = data.value;
        let total = this.customerGroups.reduce((sum, group) => sum += group.quantity, 0);
        console.log(`Total: ${total} Max: ${this.max} Min: ${this.min}`);
        console.log(this.customerGroups);
        if (total > this.max) {
            group.quantity = oldQuantity;
            data.revert();
        }
        else if ((!this.exclusive || this.hideUnavailable || total < 0) && total < this.min) {
            group.quantity = oldQuantity;
            data.revert();
        }
        else if (this.min && total < this.min) {
            group.quantity = oldQuantity;
            data.revert();
        }
        else {
            this.quantityChanged.emit({
                value: this.customerGroups.reduce((sum, g) => sum + g.quantity, 0),
                revert: data.revert
            });

            if (this.exclusive && this.enabledCustomerGroupId != customerGroup.customerGroupId) {
                this.enabledCustomerGroupId = customerGroup.customerGroupId;
                this.enabledCustomerGroupIdChanged.emit(this.enabledCustomerGroupId);
            }

        }

    }



    async ngOnInit() {
        console.log(`Available customer groups:`);
        console.log(this.availableCustomerGroups);
        for (let customerGroup of this.availableCustomerGroups) {
            let existingGroup = this.customerGroups.find(g => g.customerGroupId == customerGroup.customerGroupId);
            if (!existingGroup) {
                this.customerGroups.push({
                    customerGroupId: customerGroup.customerGroupId,
                    name: customerGroup.name,
                    quantity: 1
                });
            }

            this.initialValues[customerGroup.customerGroupId] = existingGroup ? existingGroup.quantity : 1;
        }

        console.log(`Exclusive:`);
        console.log(this.exclusive);
        if (this.exclusive) {
            let customerGroupsWithQuantity = this.customerGroups.filter(cg => cg.quantity);
            console.log(`Customer groups with quantity:`);
            console.log(customerGroupsWithQuantity);
            if (customerGroupsWithQuantity.length) {
                this.enabledCustomerGroupId = customerGroupsWithQuantity[0].customerGroupId;
                this.enabledCustomerGroupIdChanged.emit(this.enabledCustomerGroupId);
            }
        }

        this.quantityChanged.emit({
            value: this.customerGroups.reduce((sum, g) => sum + g.quantity, 0),
            revert: () => { }
        });
    }
}
