// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .timeslot-table .mdc-checkbox__background {
  width: 25px;
  height: 25px;
}

.timeslot-table .column-toggle,
.timeslot-table .row-toggle {
  padding: 0;
  line-height: 10px;
}
.timeslot-table .column-toggle i,
.timeslot-table .row-toggle i {
  transition: 0.3s ease;
  opacity: 0.4;
  font-size: 14px;
}
.timeslot-table .column-toggle i:hover,
.timeslot-table .row-toggle i:hover {
  opacity: 0.9;
}
.timeslot-table .info {
  font-size: 9px;
  opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/shared/timeslot-table/timeslot-table.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/shared/timeslot-table/timeslot-table.component.scss"],"names":[],"mappings":"AAEI;EACE,WAAA;EACA,YAAA;ACDN;;ADOE;;EAEE,UAAA;EACA,iBAAA;ACJJ;ADMI;;EACE,qBAAA;EACA,YAAA;EACA,eAAA;ACHN;ADKM;;EACE,YAAA;ACFR;ADQE;EACE,cAAA;EACA,YAAA;ACNJ","sourcesContent":["::ng-deep {\r\n  .timeslot-table {\r\n    .mdc-checkbox__background {\r\n      width: 25px;\r\n      height: 25px;\r\n    }\r\n  }\r\n}\r\n\r\n.timeslot-table {\r\n  .column-toggle,\r\n  .row-toggle {\r\n    padding: 0;\r\n    line-height: 10px;\r\n\r\n    i {\r\n      transition: 0.3s ease;\r\n      opacity: 0.4;\r\n      font-size: 14px;\r\n\r\n      &:hover {\r\n        opacity: 0.9;\r\n      }\r\n    }\r\n  }\r\n\r\n\r\n  .info {\r\n    font-size: 9px;\r\n    opacity: 0.5;\r\n  }\r\n}\r\n","::ng-deep .timeslot-table .mdc-checkbox__background {\n  width: 25px;\n  height: 25px;\n}\n\n.timeslot-table .column-toggle,\n.timeslot-table .row-toggle {\n  padding: 0;\n  line-height: 10px;\n}\n.timeslot-table .column-toggle i,\n.timeslot-table .row-toggle i {\n  transition: 0.3s ease;\n  opacity: 0.4;\n  font-size: 14px;\n}\n.timeslot-table .column-toggle i:hover,\n.timeslot-table .row-toggle i:hover {\n  opacity: 0.9;\n}\n.timeslot-table .info {\n  font-size: 9px;\n  opacity: 0.5;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
