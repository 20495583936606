import { Component, OnInit } from '@angular/core';
import { Api } from '../../services/api';
import { Router } from '@angular/router';
import { orderBy } from '../../helpers/utility';
import { Globals } from '../../services/globals';
import { CurrentUser } from '../../services/user';
import { IEvent } from '../../../../../../../../../common/common-interfaces/event';
import { IUser } from '../../interfaces/user';
import moment from 'moment';
import { IUserSettings } from '../../../../../../../../../common/common-interfaces/user-settings';



@Component({
    selector: 'events',
    styleUrls: ['./events.component.scss'],
    templateUrl: './events.component.html'
})

export class EventsComponent implements OnInit {
    events: IEvent[];
    displayedColumns = ['name', 'date', 'participants', 'status', 'edit'];
    userSettings: IUserSettings;
    search: string;
    constructor(private api: Api, private router: Router, public globals: Globals, public currentUser: CurrentUser) {

    }


    async ngOnInit() {
        if (!this.globals.userSettings) {
            this.globals.userSettingsReceived.subscribe(async (settings) => {
                this.userSettings = settings;
                await this.init();
            });
        }
        else {
            this.userSettings = this.globals.userSettings;
            await this.init();
        }

    }

    async init() {
        if (!this.userSettings.selectedEventStatuses) {
            this.userSettings.selectedEventStatuses = [
                'BOOKED',
                'DRAFT',
                'PARTLY_BOOKED',
                'PUBLISHED'
            ];
            this.globals.saveUserSettings();
        }

        await this.fetchEvents();
    }

    async selectedStatusesChanged() {
        await this.fetchEvents();
        this.globals.saveUserSettings();
    }

    async searchTextChanged() {
        if (this.search == '' || this.search.length >= 2) {
            await this.fetchEvents();
        }
    }

    async fetchEvents() {
        let url = `/manage/events/list?search=${this.search ? this.search : ''}&statuses=${this.userSettings.selectedEventStatuses.join('|')}`;

        this.events = await this.api.client().get<IEvent[]>(url);
        this.events.sort((a: any, b: any) => {
            return moment(b.startDate).valueOf() - moment(a.startDate).valueOf();
        });
    }
}
