import AdyenCheckout from '@adyen/adyen-web';
import { Component, Input, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Api } from 'projects/client-app/src/app/services/api';
import { Currency } from 'projects/client-app/src/app/services/currency';
import { Globals } from 'projects/client-app/src/app/services/globals';
import { Utility } from 'projects/client-app/src/app/services/utility';
import { environment } from 'projects/client-app/src/environments/environment';
import { Subscription } from 'rxjs';




@Component({
    selector: 'adyen-invoice-payment',
    templateUrl: './adyen-invoice-payment.component.html',
    styleUrls: ['./adyen-invoice-payment.component.scss']
})

export class AdyenInvoicePaymentComponent implements OnInit {
    stripe: any;
  @Input()
      invoice: any;
  constructor(private api: Api, private router: Router, public globals: Globals, private route: ActivatedRoute, public currency: Currency, public utility: Utility, private zone: NgZone) {

  }

  async addCssTag() {
      let script = document.createElement('link');
      script.setAttribute("rel", "stylesheet");
      script.setAttribute("href", environment.baseUrl + '/apps/public-app/assets/css/adyen.css');
      document.getElementsByTagName("head")[0].appendChild(script);
  }

  async initialize() {
      let result = await this.api.client().get<any>(`/manage/invoices/${this.invoice.id}/third-party/adyen/session`);

      if (result.tokenPaymentSucceeded) {
          this.paymentSucceded();
          return;
      }

      const configuration = {
          environment: environment.production ? 'live' : 'test',
          clientKey: environment.adyenClientKey,
          analytics: {
              enabled: true
          },
          session: result.session,
          onPaymentCompleted: (result, component) => {
              console.info(result, component);

              this.zone.run(() => {
                  this.paymentSucceded();
              });
          },
          onError: (error, component) => {
              console.error(error.name, error.message, error.stack, component);
          }
      };


      const checkout = await AdyenCheckout(configuration);
      const dropinComponent = checkout.create('dropin').mount('#adyen-checkout');

  }

  paymentSucceded() {
      this.invoice.status = 'PAID';
      this.invoice.paymentDate = new Date();
  }

  async ngOnInit() {
      this.addCssTag();
      this.initialize();
  }

  async ngOnDestroy() {

  }

}
