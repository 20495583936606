import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Api } from '../../../../services/api';
import { Globals } from '../../../../services/globals';
import { Utility } from '../../../../services/utility';
import { CurrentUser } from '../../../../services/user';

@Component({
    selector: 'min-or-max-customer-group-rule',
    templateUrl: './min-or-max-customer-group-rule.component.html',
})

export class MinOrMaxCustomerGroupRuleComponent implements OnInit, OnChanges {

    @Input()
        customerRule: any;

    @Input()
        availableCustomerGroups: any[];

    @Output()
        ruleChanged = new EventEmitter();

    data: any;

    constructor(private api: Api, private router: Router, public globals: Globals, private utility: Utility, public currentUser: CurrentUser) {}

    async ngOnInit() {
        console.log(this.customerRule.data);

        if(!this.customerRule.data) {
            this.customerRule.data = {};
        }

        this.data = this.customerRule.data;

        if(!this.data.customerGroupIds) {
            this.data.customerGroupIds = [];
        }

        if(!this.data.value) {
            this.data.value = 0;
        }

    }

    ngOnChanges(changes: SimpleChanges): void {
        this.ruleChanged.emit(this.customerRule);
    }
}
