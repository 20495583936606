// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.booking-payment-settlement-dialog {
  width: 800px;
  max-width: 100%;
  min-height: 200px;
  max-height: 90vh;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/calendar/booking-info-dialog/booking-payment-settlement-dialog/booking-payment-settlement-dialog.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/calendar/booking-info-dialog/booking-payment-settlement-dialog/booking-payment-settlement-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;ACCF","sourcesContent":[".booking-payment-settlement-dialog {\r\n  width: 800px;\r\n  max-width: 100%;\r\n  min-height: 200px;\r\n  max-height: 90vh;\r\n\r\n}\r\n",".booking-payment-settlement-dialog {\n  width: 800px;\n  max-width: 100%;\n  min-height: 200px;\n  max-height: 90vh;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
