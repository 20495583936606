import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { Utility } from '../../../../services/utility';
import { Globals } from '../../../../services/globals';
import { Api } from '../../../../services/api';
import { TranslateService } from '@ngx-translate/core';
import { ICustomerFeedback } from '../../../../interfaces/customer-feedback';

@Component({
    selector: 'feedback-section-report',
    templateUrl: './feedback-section-report.component.html',
    styleUrls: ['./feedback-section-report.component.scss']
})

export class FeedbackSectionReportComponent implements OnInit, OnDestroy {
    @Input()
        feedbackSection: any;
    @Input()
        data: {
        customerFeedback: ICustomerFeedback,
        sectionData: any
    }[];
    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility, private translate: TranslateService) {

    }



    async ngOnInit() {

    }

    async ngOnDestroy() {

    }
}
