import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Api } from '../../services/api';
import { CurrentUser } from '../../services/user';
import { Globals } from '../../services/globals';
import { TranslateService } from '@ngx-translate/core';
import { IClientNotification } from '../../interfaces/client-notification';
import { KeyValue } from '@angular/common';

@Component({
    selector: 'notification-center',
    templateUrl: './notification-center.component.html',
    styleUrls: ['./notification-center.component.scss']
})

export class NotificationCenterComponent implements OnInit, OnDestroy {
    @Output()
        notificationsChanged = new EventEmitter<{ unchecked: number }>();
    @Output()
        onClose = new EventEmitter();
    loading = false;
    latestNotifications: IClientNotification[];
    groupedNotifications: { [tag: string]: { notifications: IClientNotification[] } };
    loadNotificationsInterval;
    expandedGroups = {};

    constructor(private api: Api, private router: Router, public globals: Globals, private route: ActivatedRoute, private currentUser: CurrentUser, private translate: TranslateService) {

    }

    close() {
        this.onClose.emit();
    }


    notificationsHasChanged() {
        let unchecked = this.latestNotifications.reduce((sum, notification) => notification.checked ? sum : sum + 1, 0);
        this.notificationsChanged.emit({ unchecked: unchecked });
    }

    groupExpanded(groupTag) {
        this.expandedGroups[groupTag] = true;
    }

    groupMinimized(groupTag) {
        this.expandedGroups[groupTag] = false;
    }

    groupNotificationsSort(a: KeyValue<number, string>, b: KeyValue<number, string>): number {
        return 0;
    }

    groupNotifications() {
        this.groupedNotifications = {};
        this.groupedNotifications['INFO'] = { notifications: [] };
        this.groupedNotifications['NEW_BOOKING_COMMENT'] = { notifications: [] };
        this.groupedNotifications['NEW_BOOKING'] = { notifications: [] };
        this.groupedNotifications['NEW_BOOKING_ADD_ON'] = { notifications: [] };
        this.groupedNotifications['CHANGED_BOOKING'] = { notifications: [] };
        this.groupedNotifications['CANCELED_BOOKING'] = { notifications: [] };

        this.groupedNotifications['NEW_EVENT_BOOKING_COMMENT'] = { notifications: [] };
        this.groupedNotifications['NEW_EVENT_BOOKING'] = { notifications: [] };
        this.groupedNotifications['NEW_EVENT_BOOKING_ADD_ON'] = { notifications: [] };
        this.groupedNotifications['CANCELED_EVENT_BOOKING'] = { notifications: [] };

        this.groupedNotifications['NEW_FEEDBACK'] = { notifications: [] };

        this.groupedNotifications['NEW_SOLD_GIFTCARD'] = { notifications: [] };

        for (let notification of this.latestNotifications) {
            if (!this.groupedNotifications[notification.tag]) {

                if (typeof this.expandedGroups[notification.tag] == 'undefined')
                    this.expandedGroups[notification.tag] = false;
            }
            if (this.groupedNotifications[notification.tag])
                this.groupedNotifications[notification.tag].notifications.push(notification);
        }
    }

    async load() {
        this.loading = true;
        this.latestNotifications = await this.api.client().get<any[]>(`/notifications/latest`);
        this.groupNotifications();
        this.notificationsHasChanged();
        this.loading = false;
    }

    async ngOnInit() {
        this.load();

        this.loadNotificationsInterval = setInterval(() => {
            console.log('Loading notifications');
            this.load();
        }, 30000);
    }

    ngOnDestroy() {
        if (this.loadNotificationsInterval)
            clearInterval(this.loadNotificationsInterval);
    }
}
