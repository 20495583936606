import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Api } from '../../services/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Globals } from '../../services/globals';
import { IResource } from '../../interfaces/resource';
import { Utility } from '../../services/utility';
import { IEvent, IEventDate, IExtraOrderRow } from '../../../../../../../../../common/common-interfaces/event';
import moment from 'moment';
import { MatTable } from '@angular/material/table';
import { EventDatesSelectorComponent } from './event-dates-selector/event-dates-selector.component';
import { CurrentUser } from '../../services/user';
import { addDurationToTime, minutesFromDuration, subtractDurationFromTime, timeDifferenceInMinutes } from '../../helpers/utility';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { newPrice } from '../../interfaces/price';
import { Currency } from '../../services/currency';
import { IAddOn } from '../../interfaces/add-on';

@Component({
    selector: 'event-administration',
    styleUrls: ['./event-administration.component.scss'],
    templateUrl: './event-administration.component.html'
})

export class EventAdministrationComponent implements OnInit, OnDestroy {
    event: IEvent;
    availableResources: IResource[];
    availableAddOns: IAddOn[];
    availableFeedbackSections: any[];

    associatedResources: string[] = [];

    private paramsSubscription?: Subscription;
    @ViewChild('eventDatesSelector', { static: false })
    eventDatesSelector: EventDatesSelectorComponent;

    expandedEventDates: { [identifier: string]: boolean } = {};
    loadingEventDates: { [identifier: string]: boolean } = {};

    releaseAllEnabled = false;
    bookingAll = false;
    isBooking = false;

    constructor(private api: Api, private router: Router, private route: ActivatedRoute, public globals: Globals, private utility: Utility, public currentUser: CurrentUser, private translate: TranslateService, private snackBar: MatSnackBar, public currency: Currency) {

    }


    associatedResourceChanged(resource, e) {
        console.log(this.associatedResources);
        console.log(resource);
        console.log(e);

        if (this.associatedResources.includes(resource.id)) {
            this.event.associatedResources.push({
                resourceId: resource.id,
                name: resource.name
            });
        } else {
            this.event.associatedResources.splice(this.event.associatedResources.findIndex(r => r.resourceId == resource.id), 1);
        }
    }

    async clone() {
        let result = await this.api.client().post<any>(`/manage/events/${this.event.id}/clone`, {});
        window.location.href = `/client/${this.globals.clientId}/events/${result.newEvent.id}`;
    }

    async save() {


        // Threshold can't be negative or null
        this.event.availableSlotsThreshold = this.event.availableSlotsThreshold ? this.event.availableSlotsThreshold : 0;

        // Check if event should be created or updated
        if (this.event && this.event.id) {
            let result = await this.api.client().patch<any>(`/manage/events/${this.event.id}`, this.event);
            if (result.succeeded) {
                this.event.status = result.event.status;
                this.event.endDate = result.event.endDate;

                this.snackBar.open(this.translate.instant('SAVED'), null, {
                    duration: 1000,
                    horizontalPosition: 'center',
                    panelClass: 'align-center'
                });
            }
        }
        else {
            let result = await this.api.client().put<any>(`/manage/events`, this.event);
            if (result.succeeded) {
                this.router.navigateByUrl(`/${this.globals.clientId}/events/${result.event.id}`);
            }
        }
    }

    async saveEventDate(eventDate: IEventDate) {
        let result = await this.api.client().patch<any>(`/manage/events/${this.event.id}/event-dates`, eventDate);
        if (result.succeeded) {
            this.event.status = result.eventStatus;
        }
        await this.save();
    }

    async publish() {
        let result = await this.api.client().post<any>(`/manage/events/${this.event.id}/publish`, {});
        if (result.succeeded) {
            this.event.status = result.eventStatus;
        }
    }

    async unpublish() {
        let result = await this.api.client().post<any>(`/manage/events/${this.event.id}/unpublish`, {});
        if (result.succeeded) {
            this.event.status = result.eventStatus;
        }
    }

    defaultStartTimeChanged(e) {
        console.log(e);
        if (minutesFromDuration(this.event.defaultStartTime) > minutesFromDuration(this.event.defaultEndTime)) {
            this.event.defaultEndTime = addDurationToTime(this.event.defaultStartTime, '01:00');
        }
    }

    defaultEndTimeChanged(e) {
        console.log(e);
        if (minutesFromDuration(this.event.defaultStartTime) > minutesFromDuration(this.event.defaultEndTime)) {
            this.event.defaultEndTime = addDurationToTime(this.event.defaultStartTime, '01:00');
        }
    }

    isEventDateEditable(eventDate: IEventDate) {
        return this.event.associatedResources.length
            && !eventDate.isBooked
            && this.isEventEditable();
    }

    isEventDateReleaseable(eventDate: IEventDate) {
        return moment().isBefore(eventDate.startDate)
            && this.isEventEditable();
    }

    isEventEditable() {
        return !this.ifEventHasStatus(['FINISHED', 'PUBLISHED']);
    }

    isAnyEventDateLoading() {
        return Object.values(this.loadingEventDates).some(e => e);
    }

    getResourceTimes(resourceId: string) {
        let resource = this.availableResources.find(r => r.id == resourceId);
        let time = '00:00';
        let startTimes = [];
        try {
            while (true) {
                if (time != '24:00')
                    startTimes.push(time);
                time = addDurationToTime(time, resource.timeslotInterval);
            }
        } catch (e) { }
        return startTimes;
    }

    async eventDateTimeChanged(eventDate: IEventDate, e) {
        console.log(e);
        console.log(this);

        if (timeDifferenceInMinutes(eventDate.startTime, eventDate.endTime) <= 0)
            eventDate.endTime = addDurationToTime(eventDate.startTime, '01:00');

        eventDate.startDate = moment(`${eventDate.day} ${eventDate.startTime}`).toDate();
        eventDate.endDate = moment(`${eventDate.day} ${eventDate.endTime}`).toDate();

        for (let eventDateResource of eventDate.resources) {
            let resource = this.availableResources.find(r => r.id == eventDateResource.resourceId);
            eventDateResource.startTime = getResourceStartTime(this.availableResources.find(r => r.id == eventDateResource.resourceId), eventDate.startTime);
            eventDateResource.endTime = getResourceEndTime(this.availableResources.find(r => r.id == eventDateResource.resourceId), eventDate.endTime);

            if (timeDifferenceInMinutes(eventDateResource.startTime, eventDateResource.endTime) <= 0) {
                eventDateResource.endTime = addDurationToTime(eventDateResource.startTime, resource.timeslotInterval);
            }
        }
        await this.saveEventDate(eventDate);
    }

    async eventDateResourceTimeChanged(eventDate: IEventDate, resourceId: string, e) {
        let resource = this.availableResources.find(r => r.id == resourceId);
        let eventDateResource = eventDate.resources.find(r => r.resourceId == resourceId);
        if (timeDifferenceInMinutes(eventDateResource.startTime, eventDateResource.endTime) <= 0) {
            eventDateResource.endTime = addDurationToTime(eventDateResource.startTime, resource.timeslotInterval);
        }
        await this.saveEventDate(eventDate);
    }



    async dateAdded(eventDate: IEventDate) {
        this.event.dates.sort((a, b) => moment(a.startDate).valueOf() - moment(b.startDate).valueOf());
        this.loadingEventDates[eventDate.identifier] = true;
        let result = await this.api.client().put<any>(`/manage/events/${this.event.id}/event-dates/`, eventDate);
        if (result.succeeded) {
            this.loadingEventDates[eventDate.identifier] = false;
            if (this.event.noResourcesRequired) {
                this.event.status = 'BOOKED';
            } else {
                this.event.status = result.eventStatus;
            }
        }
        else {
            this.event.dates.splice(this.event.dates.indexOf(eventDate), 1);
        }
    }


    async dateRemoved(eventDate: IEventDate) {
        this.loadingEventDates[eventDate.identifier] = true;
        let result = await this.api.client().delete<any>(`/manage/events/${this.event.id}/event-dates/${eventDate.identifier}`);
        if (result.succeeded) {
            this.loadingEventDates[eventDate.identifier] = false;
            if (this.event.noResourcesRequired) {
                this.event.status = 'BOOKED';
            } else {
                this.event.status = result.eventStatus;
            }
        }
    }

    updateCalendar() {
        this.eventDatesSelector.update();
    }

    async bookEventDate(eventDate: IEventDate) {
        this.isBooking = true;
        this.loadingEventDates[eventDate.identifier] = true;
        let result = await this.api.client().post<any>(`/manage/events/${this.event.id}/event-dates/${eventDate.identifier}/book`, eventDate);

        if (result.succeeded) {
            eventDate.isBooked = true;
            eventDate.resourceBookingId = result.resourceBookingId;
            this.event.status = result.eventStatus;
            this.updateCalendar();
        }
        else {
            this.snackBar.open(this.translate.instant(result.hint), this.translate.instant('CLOSE'));
        }
        this.loadingEventDates[eventDate.identifier] = false;
        this.isBooking = false;
    }

    async releaseEventDate(eventDate: IEventDate) {
        this.loadingEventDates[eventDate.identifier] = true;
        let result = await this.api.client().post<any>(`/manage/events/${this.event.id}/event-dates/${eventDate.identifier}/release`, {});

        if (result.succeeded) {
            eventDate.isBooked = false;
            delete eventDate.resourceBookingId;
            this.event.status = result.eventStatus;
            this.updateCalendar();
        }
        this.loadingEventDates[eventDate.identifier] = false;
    }

    async bookAllEventDates() {
        this.isBooking = true;
        this.bookingAll = true;
        for (let eventDate of this.event.dates) {
            if (!eventDate.isBooked)
                await this.bookEventDate(eventDate);
        }
        this.bookingAll = false;
        this.isBooking = false;
    }

    async releaseAllEventDates() {
        let result = await this.api.client().post<any>(`/manage/events/${this.event.id}/event-dates/release-all`, {});
        if (result.succeeded) {
            this.event.status = result.event.status;
            this.event.dates = result.event.dates;
        }
    }

    async ngOnInit() {
        this.paramsSubscription = this.route.params.subscribe(async (params) => {
            if (params['id']) {
                this.event = await this.api.client().get<IEvent>(`/manage/events/${params['id']}`);

                this.releaseAllEnabled = window.location.href.includes('#release');

                if (!this.event.color) {
                    this.event.color = this.utility.randomColor();
                }

                if (!this.event.summary) {
                    this.event.summary = ""
                }

                if (!this.event.priceSettings) {
                    this.event.priceSettings = {
                        customerGroupPrices: {},
                        extraOrderRows: [],
                        price: newPrice(),
                    };
                }
                if (!this.event.priceSettings.customerGroupPrices) {
                    this.event.priceSettings.customerGroupPrices = {};
                }

                if (!this.event.extraMedia)
                    this.event.extraMedia = [];

                if (!this.event.occupiedSlots)
                    this.event.occupiedSlots = 0;

                if (!this.event.newBookingEmailSubject)
                    this.event.newBookingEmailSubject = '';

                if (!this.event.newBookingEmailContent)
                    this.event.newBookingEmailContent = '';

                if (!this.event.type)
                    this.event.type = 'EVENT';

                if (!this.event.classSettings)
                    this.event.classSettings = {
                        instructors: []
                    };

                if (!this.event.addOns)
                    this.event.addOns = {};

                if (!this.event.feedbackSections) {
                    this.event.feedbackSections = {};
                }

                if (!this.event.requiredParticipantInfo) {
                    this.event.requiredParticipantInfo = {
                        firstName: false,
                        lastName: false,
                        email: false,
                        phone: false
                    };
                }

                if (!this.event.campaignLabel) {
                    this.event.campaignLabel = {
                        text: '',
                        foregroundColor: '#ffffff',
                        backgroundColor: '#5eaf39'
                    };
                }

                if (typeof (this.event.availableSlotsThreshold) == 'undefined') {
                    this.event.availableSlotsThreshold = 10;
                }

                if (!this.event.internalDescription) {
                    this.event.internalDescription = "";
                }

            }
            else {
                this.event = {
                    type: 'EVENT',
                    availableSlots: 0,
                    occupiedSlots: 0,
                    associatedResources: [],
                    minPeoplePerBooking: 1,
                    maxPeoplePerBooking: 100,
                    internalDescription: "",
                    dates: [],
                    bookingWindow: {
                        openDate: new Date(),
                        closeDate: moment().add(1, 'month').toDate()
                    },
                    defaultStartTime: '12:00',
                    defaultEndTime: '14:00',
                    color: '#ceedef',
                    status: 'DRAFT',
                    priceSettings: {
                        customerGroupPrices: {},
                        extraOrderRows: [],
                        price: newPrice(),
                    },
                    extraMedia: [],
                    newBookingEmailSubject: '',
                    newBookingEmailContent: '',
                    newBookingSmsContent: '',
                    classSettings: {
                        instructors: []
                    },
                    quickCheckoutEnabled: true,
                    addOns: {},
                    addOnViewMode: 'ADD_ONS',
                    feedbackSections: {},
                    collectParticipantInfo: true,
                    requiredParticipantInfo: {
                        firstName: false,
                        lastName: false,
                        email: false,
                        phone: false
                    },
                    campaignLabel: {
                        text: '',
                        foregroundColor: '#ffffff',
                        backgroundColor: '#5eaf39'
                    },
                    summary: "",
                    availableSlotsThreshold: 10,
                    log: []
                };
            }

            if (!this.event.newBookingEmailSubject || this.event.newBookingEmailSubject == '') {
                let text = await this.api.publicClient().get<any>(`/default-texts/by-tag/NEW_EVENT_BOOKING_EMAIL_SUBJECT`);
                this.event.newBookingEmailSubject = text ? text.content : '';
            }
            if (!this.event.newBookingEmailContent || this.event.newBookingEmailContent == '') {
                let text = await this.api.publicClient().get<any>(`/default-texts/by-tag/NEW_EVENT_BOOKING_EMAIL_CONTENT`);
                this.event.newBookingEmailContent = text ? text.content : '';
            }
            if (!this.event.newBookingSmsContent || this.event.newBookingSmsContent == '') {
                let text = await this.api.publicClient().get<any>(`/default-texts/by-tag/NEW_EVENT_BOOKING_SMS_CONTENT`);
                this.event.newBookingSmsContent = text ? text.content : '';
            }

            this.availableResources = await this.api.client().get<IResource[]>(`/manage/resources`);
            this.associatedResources = this.event.associatedResources.map(r => r.resourceId);

            let addOns = await this.api.client().get<IAddOn[]>(`/manage/add-ons`);
            for (let addOn of addOns) {
                if (!this.event.addOns[addOn.id])
                    this.event.addOns[addOn.id] = { enabled: false };
            }
            this.availableAddOns = addOns;


            this.globals.clientSettingsReceived.subscribe(async () => {
                if (this.globals.clientSettings.enableCustomerFeedbackEmail)
                    this.availableFeedbackSections = await this.api.client().get<any[]>(`/manage/feedback-sections`);
            });


        });


    }

    async ngOnDestroy() {
        if (this.paramsSubscription)
            this.paramsSubscription.unsubscribe();
    }

    async noResourcesRequiredChanged() {
        console.log('Changed');
        if (this.event.noResourcesRequired) {
            this.associatedResources = [];
            this.event.associatedResources = [];
            this.event.status = 'BOOKED';
        } else {
            this.event.status = 'DRAFT';
        }
        await this.save();
    }


    removeExtraOrderRow(extraOrderRow: IExtraOrderRow) {
        this.event.priceSettings.extraOrderRows.splice(this.event.priceSettings.extraOrderRows.indexOf(extraOrderRow), 1);
    }

    addExtraOrderRow() {
        this.event.priceSettings.extraOrderRows.push({
            name: '',
            price: newPrice()
        });
    }

    addInstructor() {
        this.event.classSettings.instructors.push({});
    }

    removeInstructor(instructor) {
        this.event.classSettings.instructors.splice(this.event.classSettings.instructors.indexOf(instructor), 1);
    }

    ifEventHasStatus(statuses: string[]) {
        return statuses.includes(this.event.status);
    }


    collectParticipantInfoChanged() {
        if (!this.event.collectParticipantInfo) {
            this.event.requiredParticipantInfo.email = false;
            this.event.requiredParticipantInfo.firstName = false;
            this.event.requiredParticipantInfo.lastName = false;
            this.event.requiredParticipantInfo.phone = false;
        }
    }

}

export const getResourceStartTime = (resource: IResource, startTime: string): string => {
    if (!resource)
        return startTime;
    let resourceStartTime = resource.timeslotInterval;

    while (timeDifferenceInMinutes(resourceStartTime, startTime) > 0) {
        resourceStartTime = addDurationToTime(resourceStartTime, resource.timeslotInterval);
    }

    if (timeDifferenceInMinutes(resourceStartTime, startTime) != 0) {
        resourceStartTime = subtractDurationFromTime(resourceStartTime, resource.timeslotInterval);
    }

    return resourceStartTime;
};

export const getResourceEndTime = (resource: IResource, endTime: string): string => {
    if (!resource)
        return endTime;
    let resourceEndTime = resource.timeslotInterval;

    while (timeDifferenceInMinutes(resourceEndTime, endTime) > 0) {
        resourceEndTime = addDurationToTime(resourceEndTime, resource.timeslotInterval);
    }

    return resourceEndTime;
};

