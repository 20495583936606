import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'button-new',
  templateUrl: './button-new.component.html',
  styleUrls: ['./button-new.component.scss']
})
export class ButtonNewComponent {
    @Input() class : string = ""
    @Input() disabled: boolean;
    @Output() click = new EventEmitter<void>();

    onClick() {
        this.click.emit();
    }
}