import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Globals } from '../../../services/globals';
import { TranslateService } from '@ngx-translate/core';
import { ILogItem } from '../../../../../../../../../../common/common-interfaces/log-item';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Utility } from '../../../services/utility';
import * as moment from 'moment-timezone';

@Component({
    selector: 'log-data-dialog',
    templateUrl: './log-data-dialog.component.html',
})

export class LogDataDialogComponent implements OnInit {

    logItem: ILogItem;
    timeStamp: string = "";
    jsonData: any = {};

    constructor(@Inject(MAT_DIALOG_DATA) public data: ILogItem, private dialog: MatDialog, private dialogRef: MatDialogRef<LogDataDialogComponent>, 
                public utility: Utility,  private globals: Globals, public translate: TranslateService) {
        this.logItem = data;
    }

    ngOnInit(): void {
        this.timeStamp = moment(this.logItem.created).tz(this.globals.clientSettings.timezone).toString();
        this.jsonData = this.formatJsonData(JSON.parse(this.logItem.data));
        console.log(this.jsonData);
    }

    formatJsonData(json: {}): {} {
        let keys = Object.keys(json);
        let formattedJson = {};

        for (let key of keys) {
            let value = json[key];
            
            //if no value just continue to next key
            if(value === undefined) {
                continue;
            }

            // if value is an object recursively process it and continue to next key after its done
            if (typeof value === 'object') {
                formattedJson[key] = this.formatJsonData(value);
                continue;
            }

            // if value is a string parse it if possible
            if (typeof value === 'string'){
                try {
                    let parsedValue = JSON.parse(value);

                    while (typeof parsedValue === 'string' && (parsedValue.startsWith('{') || parsedValue.startsWith('['))) {
                        parsedValue = JSON.parse(parsedValue);
                    }

                    //if the parse value is an object and not null then continue recursing its keys
                    if (typeof parsedValue === 'object' && parsedValue !== undefined) {
                        formattedJson[key] = this.formatJsonData(parsedValue);
                    }
                    else if(parsedValue !== undefined) {
                        formattedJson[key] = parsedValue;
                    } 

                } 
                catch (error) {
                    console.log("Error parsing json data");
                    console.log(error);
                    console.log(key);
                    formattedJson[key] = value;
                }
                continue;
            }

            formattedJson[key] = value;
        }

        return formattedJson;
    }


    closeDialog(): void {
        this.dialogRef.close();
    }
}
