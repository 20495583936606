import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Api } from '../../services/api';
import { CurrentUser } from '../../services/user';
import { Observable, Subscription } from 'rxjs';
import { Globals } from '../../services/globals';
import { IUser } from '../../interfaces/user';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationContentDialogComponent } from '../notification-center/notification-content-dialog/notification-content-dialog.component';
import { SeasonalTheme, Utility } from '../../services/utility';
import { DOCUMENT } from '@angular/common';
import { IUserSettings } from '../../../../../../../../../common/common-interfaces/user-settings';
@Component({
    selector: 'main',
    templateUrl: './main.component.html'
})
export class MainComponent implements OnInit, AfterViewInit {
    user: IUser | null = null;
    clientId?: string;
    private paramsSubscription?: Subscription;
    menuOpened: boolean;
    pinnedNotification: any;
    userSettingsReceived: Observable<any>;

    constructor(private api: Api, private router: Router, public globals: Globals, private route: ActivatedRoute, private currentUser: CurrentUser, private translate: TranslateService,
        private dateAdapter: DateAdapter<Date>, private dialog: MatDialog, private utility: Utility, @Inject(DOCUMENT) private document: Document) {
        this.api = api;
        this.router = router;
    }

    openPinnedNotification() {
        this.dialog.open(NotificationContentDialogComponent, {
            data: { notification: this.pinnedNotification }
        });
    }

    updateTheme(selectedTheme: "DARK" | "LIGHT" | "LSD") {

    }

    toggleMenu() {
        this.menuOpened = !this.menuOpened;
    }
    openMenu() {
        this.menuOpened = true;
    }
    closeMenu() {
        this.menuOpened = false;
    }

    isReportAvailable(reportIdentifier: string) {

        if (reportIdentifier === 'SIMPLIFIED_SALES_REPORT') {
            if (this.globals.clientSettings && this.globals.clientSettings.paymentSettings && this.globals.clientSettings.paymentSettings.simplifiedPaymentMode) {
                reportIdentifier = 'SALES_REPORT';
            }
            else{
                return false;
            }
        }
        else if (reportIdentifier === 'SALES_REPORT') {
            if (this.globals.clientSettings && this.globals.clientSettings.paymentSettings && this.globals.clientSettings.paymentSettings.simplifiedPaymentMode) {
                return false;
            }
        }

        return ['superadmin', 'manager'].includes(this.user.role) || this.user.availableReports[reportIdentifier];
    }

    async logOut(): Promise<void> {
        await this.api.base().post<any>(`/client/log-out`, {});
        window.location.href = '/client/login';
    }

    async ngOnInit(): Promise<void> {
        if ((window as any).CKEDITOR) (window as any).CKEDITOR.config.allowedContent = true;
        this.paramsSubscription = this.route.params.subscribe(async (params) => {
            if (params['clientId']) {
                this.clientId = params['clientId'];
                this.globals.setClientId(this.clientId);
                this.globals.clientSettingsReceived.subscribe((clientSettings) => {
                    this.translate.use(this.globals.clientSettings.language);
                    moment.locale(this.globals.clientSettings.language);

                    console.log(`Language: ${this.globals.clientSettings.language}`);
                    this.dateAdapter.setLocale(this.globals.clientSettings.language);
                    this.dateAdapter.getFirstDayOfWeek = () => {
                        return 1;
                    };
                });
                this.user = await this.currentUser.get();

                this.pinnedNotification = await this.api.client().get<any>(`/notifications/pinned`);
            } else {
                console.log('No clientId');
                window.location.href = '/';
            }

            let accessToken = localStorage.getItem('accessToken');
            if (accessToken) {
                let user = await this.currentUser.get();
                if (user) {
                    // this.router.navigateByUrl('/' + this.globals.clientId);
                } else {
                    this.router.navigateByUrl(this.globals.clientId + '/login');
                }
            } else {
                this.router.navigateByUrl(this.globals.clientId + '/login');
            }


        });
    }

    ngAfterViewInit(): void {
        this.globals.userSettingsReceived.subscribe((userSettings: IUserSettings): void => {
            let useDarkMode = userSettings.useTheme;
            console.log("this");
            this.document.documentElement.setAttribute('data-theme', useDarkMode ? "dark" : "light");
        });
    }
}
