import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, ComponentFactoryResolver, Injector, ComponentRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as  moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { Api } from '../../services/api';
import { Globals } from '../../services/globals';
import { CalendarDateSelectorComponent } from './calendar-date-selector/calendar-date-selector.component';
import { IResource } from '../../interfaces/resource';
import { BookingInfoDialogComponent } from './booking-info-dialog/booking-info-dialog.component';
import { Currency } from '../../services/currency';
import { IBooking } from '../../../../../../src/app/interfaces/booking';
import { BookingSearchDialogComponent } from './booking-search-dialog/booking-search-dialog.component';
import { QuickBookingDialogComponent } from './quick-booking-dialog/quick-booking-dialog.component';
import { Utility } from '../../services/utility';
import { IPackage } from '../../interfaces/package';
import { CheckInCalendarViewComponent } from './check-in-calendar-view/check-in-calendar-view.component';
import { CurrentUser } from '../../services/user';
import { VirtualGiftCardDialogComponent } from './virtual-gift-card-dialog/virtual-gift-card-dialog.component';
import { ResourceBookingInfoDialogComponent } from './resource-booking-info-dialog/resource-booking-info-dialog.component';
import { IResourceBooking, IResourceBookingResource } from 'src/app/interfaces/resource-booking';
import { IEvent, IEventDate } from '../../../../../../../../../common/common-interfaces/event';
import { EventInfoDialogComponent } from './event-info-dialog/event-info-dialog.component';
import { UnknownTextScannedDialogComponent } from './unknown-text-scanned-dialog/unknown-text-scanned-dialog.component';
import { IEventBooking } from '../../../../../../../../../common/common-interfaces/event-booking';
import { EventBookingDialogComponent } from '../event-booking-dialog/event-booking-dialog.component';
import { HcertInfoDialogComponent } from './hcert-info-dialog/hcert-info-dialog.component';
import { HcertScanDialogComponent } from './hcert-scan-dialog/hcert-scan-dialog.component';
import { FirstVisitDialogComponent } from './first-visit-dialog/first-visit-dialog.component';
import { CheckInCalendarViewNewComponent } from './new/check-in-calendar-view-new.component';
import { IRsCountSettings } from '../../../../../../../../../common/common-interfaces/rs-count-settings';

@Component({
    selector: 'check-in-calendar',
    templateUrl: './check-in-calendar.component.html',
    styleUrls: ['./check-in-calendar.component.scss']
})

export class CheckInCalendarComponent implements OnInit {
    window: any = window;

    @ViewChild(CalendarDateSelectorComponent, { static: false })
    calendarDateSelector: ComponentRef<CalendarDateSelectorComponent>;

    @ViewChild('checkInCalendarView', { static: false })
    checkInCalendarView: CheckInCalendarViewComponent;

    @ViewChild('checkInCalendarViewNew', { static: false })
    checkInCalendarViewNew: CheckInCalendarViewNewComponent;

    @ViewChild('checkInListView', { static: false })
    checkInListView: CheckInCalendarViewComponent;

    @ViewChild('dailyNotesAutoSize', { static: false })
    textArea: ElementRef<HTMLTextAreaElement>;

    bookingAutoFetchSuspended = false;
    backToTodayTimeoutHandle = null;
    fetchBookingsIntervalHandle = null;
    dailyNotes: string;
    selectedDate: Date = new Date();
    notificationCenterOpened: boolean = false;
    uncheckedNotifications;
    @ViewChild('notificationButton', { static: false })
    notificationButton: ElementRef<HTMLElement>;
    unpaidInvoice = false;
    unpaidInvoiceSuppressed = false;

    inputBuffer: string = '';
    inputBufferTimeoutHandler;

    hijackedQRScanCallback: any;

    useNewCalendar = this.window.location.hash.includes('new');

    personCount: number = 0;
    checkedIn: number = 0;
    rsCountInterval: any;
    rsCountSettings: IRsCountSettings;

    isFocused: boolean = false;
    @ViewChild('autosize') autosize: ElementRef<HTMLTextAreaElement>;

    constructor(private api: Api, public globals: Globals, private utility: Utility, private translate: TranslateService, private dialog: MatDialog,
        private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector, private currency: Currency, public currentUser: CurrentUser) {

    }

    getTitleYear() {
        return moment(this.selectedDate).format('YYYY');
    }

    getTitleDate() {
        return moment(this.selectedDate).format('ddd DD MMM');
    }

    nextDay() {
        this.selectedDate = moment(this.selectedDate).add(1, 'day').toDate();
        this.updateDate();
    }

    previousDay() {
        this.selectedDate = moment(this.selectedDate).subtract(1, 'day').toDate();
        this.updateDate();
    }

    goToCurrentTime() {
        switch (this.globals.userSettings.checkInView) {
            case 'CALENDAR':
                this.checkInCalendarView.goToCurrentTime();
                break;
            case 'LIST':

                break;
        }
    }

    async updateDate(): Promise<void> {
        this.fetchDailyNotes();
        
        if (this.globals.clientSettings && this.globals.clientSettings.thirdParty &&
            this.globals.clientSettings.thirdParty.rsCountSettings && this.globals.clientSettings.thirdParty.rsCountSettings.enabled) {
            await this.rsCount();
        }
    }


    openSearchDialog() {
        this.dialog.open(BookingSearchDialogComponent, {
            data: {
                openBooking: (data) => {
                    if (data.booking)
                        this.openBookingDialog({ booking: data.booking });
                    else if (data.resourceBooking)
                        this.openResourceBookingDialog({ resourceBooking: data.resourceBooking });
                    else if (data.eventBooking)
                        this.openEventBookingDialog({ eventBooking: data.eventBooking });
                }
            }
        });
    }

    fetchBookings() {
        switch (this.globals.userSettings.checkInView) {
            case 'CALENDAR':
                this.checkInCalendarView.fetchBookings();
                break;
            case 'LIST':
                this.checkInListView.fetchBookings();
                break;
        }
    }

    reRender(refetch?: boolean) {
        switch (this.globals.userSettings.checkInView) {
            case 'CALENDAR':
                this.checkInCalendarView.reRender(refetch);
                break;
            case 'LIST':
                this.checkInListView.reRender(refetch);
                break;
        }
    }

    openQuickBookingDialog() {
        this.dialog.open(QuickBookingDialogComponent, {
            data: {
                selectedDate: this.selectedDate,
                bookingCreated: (booking) => {
                    this.fetchBookings();
                }
            }
        });
    }

    openVirtualGiftCardDialog() {
        this.dialog.open(VirtualGiftCardDialogComponent, {
            data: {

            }
        });
    }

    openDatePickerDialog() {
        this.dialog.open(CalendarDateSelectorComponent, {
            data: {
                selectedDate: this.selectedDate,
                onDateSelected: (date) => {
                    this.selectedDate = date;
                    this.updateDate();
                },
                onGoToToday: () => {
                    this.selectedDate = moment().toDate();
                    this.goToCurrentTime();
                    this.updateDate();
                }
            }
        });
    }

    async fetchDailyNotes() {
        let day = moment(this.selectedDate).format('YYYY-MM-DD');
        this.dailyNotes = await this.api.client().get<any>(`/daily-notes/${day}`);
    }

    async saveDailyNotes() {
        let day = moment(this.selectedDate).format('YYYY-MM-DD');
        await this.api.client().post<any>(`/daily-notes/${day}`, this.dailyNotes);
    }

    changeView(view) {
        this.globals.userSettings.checkInView = view;
        this.globals.saveUserSettings();
    }

    bookingClicked(data: {
        booking?: IBooking,
        resource?: IResource,
        _package?: IPackage,
        resourceBooking?: IResourceBooking,
        resourceBookingResource?: IResourceBookingResource,
        event?: IEvent,
        eventDate?: IEventDate,
        isEventWithoutResource?: boolean
    }) {
        if (data.booking) {
            this.openBookingDialog({ booking: data.booking });
        }
        else if (data.resourceBooking) {
            if (data.event) {
                this.openEventDialog({
                    event: data.event,
                    eventDate: data.eventDate,
                    resourceBooking: data.resourceBooking,
                    resourceBookingResource: data.resourceBookingResource
                });
            }
            else {
                this.openResourceBookingDialog({
                    resourceBooking: data.resourceBooking,
                    resourceBookingResource: data.resourceBookingResource
                });
            }
        } else if (data.isEventWithoutResource) {
            this.openEventDialog({
                event: data.event,
                eventDate: data.eventDate,
                resourceBooking: null,
                resourceBookingResource: null
            });
        }
    }

    suspendBookingAutoFetch() {
        console.log('Auto fetch suspended');
        this.bookingAutoFetchSuspended = true;
    }

    resumeBookingAutoFetch() {
        console.log('Auto fetch resumed');
        this.bookingAutoFetchSuspended = true;
    }

    closeNotificationCenter() {
        this.notificationCenterOpened = false;
    }

    openNotificationCenter() {
        this.notificationCenterOpened = true;
    }

    notificationsChanged(data) {
        if (typeof this.uncheckedNotifications != 'undefined' && this.uncheckedNotifications < data.unchecked) {
            this.notificationButton.nativeElement.classList.add('new-notification');
            setTimeout(() => {
                this.notificationButton.nativeElement.classList.remove('new-notification');
            }, 1000);
        }
        this.uncheckedNotifications = data.unchecked;
    }


    openBookingDialog(data: { booking: IBooking }) {
        this.dialog.open(BookingInfoDialogComponent, {
            autoFocus: false,
            data: Object.assign({}, data, {
                bookingChanged: (refetch?: boolean) => {
                    this.reRender(refetch);
                    console.log('Booking changed');
                },
                bookingCanceled: () => {
                    this.fetchBookings();
                    console.log('Booking canceled');
                },
                hijackQRScan: (callback) => {
                    return this.hijackQRScan(callback);
                },
                releaseQRScanHijack: () => {
                    this.releaseQRScanHijack();
                }
            })
        });
    }

    openResourceBookingDialog(data: { resourceBooking: IResourceBooking, resourceBookingResource?: IResourceBookingResource }) {
        this.dialog.open(ResourceBookingInfoDialogComponent, {
            data: Object.assign({}, data, {
                bookingChanged: (refetch?: boolean) => {
                    this.reRender(refetch);
                    console.log('Resource booking changed');
                },
                bookingCanceled: () => {
                    this.fetchBookings();
                    console.log('Resource booking canceled');
                },
                hijackQRScan: (callback) => {
                    return this.hijackQRScan(callback);
                },
                releaseQRScanHijack: () => {
                    this.releaseQRScanHijack();
                }
            })
        });
    }

    openEventDialog(data: {
        event: IEvent,
        eventDate: IEventDate,
        resourceBooking: IResourceBooking,
        resourceBookingResource?: IResourceBookingResource
    }) {
        this.dialog.open(EventInfoDialogComponent, {
            data: Object.assign({}, data, {
                eventChanged: (refetch?: boolean) => {
                    this.reRender(refetch);
                    console.log('Event changed');
                },
                hijackQRScan: (callback) => {
                    return this.hijackQRScan(callback);
                },
                releaseQRScanHijack: () => {
                    this.releaseQRScanHijack();
                }
            })
        });
    }


    openEventBookingDialog(data: { eventBooking: IEventBooking }) {
        this.dialog.open(EventBookingDialogComponent, {
            data: Object.assign({}, data, {
                eventBookingChanged: (refetch?: boolean) => {
                    this.reRender(refetch);
                    console.log('Event booking changed');
                },
                eventBookingCanceled: () => {
                    this.fetchBookings();
                    console.log('Event booking canceled');
                },
                eventBookingMoved: (eventBooking: IEventBooking, newEvent: IEvent) => {
                    this.fetchBookings();
                    console.log('Event booking moved');
                },
                hijackQRScan: (callback) => {
                    return this.hijackQRScan(callback);
                },
                releaseQRScanHijack: () => {
                    this.releaseQRScanHijack();
                }
            })
        });
    }


    async ngAfterViewInit() {
        let setupUserSettings = userSettings => {
            if (userSettings.checkInView)
                this.changeView(userSettings.checkInView);
            else {
                this.globals.userSettings.checkInView = 'CALENDAR';
                this.globals.saveUserSettings();
            }
        };

        if (this.globals.userSettings)
            setupUserSettings(this.globals.userSettings);
        else
            this.globals.userSettingsReceived.subscribe(setupUserSettings);

        this.updateDate();
        await this.setupAutoEvents();
    }

    backToToday() {
        if (moment(this.selectedDate).format('YYYY-MM-DD') != moment().format('YYYY-MM-DD'))
            this.selectedDate = moment().toDate();
    }

    resetbackToTodayTimeout() {
        console.log(`Reseting back to today timeout`);
        clearTimeout(this.backToTodayTimeoutHandle);
        this.backToTodayTimeoutHandle = setTimeout(() => {
            this.backToToday();
        }, 3000 * 60);
    }



    async setupAutoEvents() {
        console.log(`Setting up auto events`);
        // Go back to today if mouse hasn't moved for three minutes
        const user = await this.currentUser.get()
        if (user && user.settings && !user.settings.disableAutoReset) {
            this.resetbackToTodayTimeout();

            document.body.addEventListener('mousemove', this.resetbackToTodayTimeout);
        }

        // Refetch bookings every 10 seconds if not suppressed
        if (!this.fetchBookingsIntervalHandle) {
            console.log('Creating fetch booking interval');
            this.fetchBookingsIntervalHandle = setInterval(() => {
                if (!this.bookingAutoFetchSuspended) {
                    console.log('Fetching bookings..');
                    this.fetchBookings();
                }
                else {
                    console.log('Booking fetch suspended');
                }
            }, 100000);
        }
        else {
            console.log('Fetch booking interval already exist');
        }


        window.onfocus = this.resumeBookingAutoFetch;
        window.onblur = this.suspendBookingAutoFetch;
    }


    ngOnDestroy(): void {
        console.log(`ngOnDestroy`);
        clearInterval(this.fetchBookingsIntervalHandle);
        clearTimeout(this.backToTodayTimeoutHandle);
        clearInterval(this.rsCountInterval);
    }

    async fetchUnpaidInvoices() {
        let unpaidInvoices = await this.api.client().get<any>(`/notifications/unpaid-invoices`);
        this.unpaidInvoice = unpaidInvoices.unpaid;
    }

    async ngOnInit() {

        if (this.globals.clientSettings)
            await this.fetchUnpaidInvoices();
        else {
            this.globals.clientSettingsReceived.subscribe(async clientSettings => {
                await this.fetchUnpaidInvoices();

                if (this.globals.clientSettings && this.globals.clientSettings.thirdParty &&
                    this.globals.clientSettings.thirdParty.rsCountSettings && this.globals.clientSettings.thirdParty.rsCountSettings.enabled) {

                    this.rsCountSettings = this.globals.clientSettings.thirdParty.rsCountSettings;
                    let res = await this.api.client().get<any>("/third-party/rs-count/count");
                    this.personCount = res.count;
                    this.fetchCheckedInPersons();

                    this.rsCountInterval = setInterval(async () => {
                        await this.rsCount(); //init interval
                    }, (1000 * 60));

                }


            });
        }

        document.addEventListener('keydown', (event) => {
            this.keyDown(event);
        });
        /*
          document.addEventListener('keyup', (event) => {
              this.keyUp(event);
          });
          */

        let user = await this.currentUser.get();
        if ((user.onboardingData && user.onboardingData.firstVisit) || window.location.hash.includes('first-visit')) {
            this.dialog.open(FirstVisitDialogComponent, {
                autoFocus: false,
                data: {}
            });
        }


    }

    async rsCount(): Promise<void> {
        //rsCountInterval
        console.log("Fetching person count from rs-count");

        if (moment().diff(moment(this.selectedDate), "days") == 0) {
            let res = await this.api.client().get<any>("/third-party/rs-count/count");

            this.personCount = res.count;
            await this.fetchCheckedInPersons();

        }
        else {
            this.personCount = 0;
            this.checkedIn = 0;

            let bookings = await this.api.client().get<IBooking[]>(`/bookings/by-day/${moment().format("YYYY-MM-DD")}`);
            for (let booking of bookings) {
                if (booking.hasArrived) {
                    this.checkedIn += booking.persons;
                }
            }

            //add history fetching api call here
        }
    }

    //checked in persons right now
    async fetchCheckedInPersons(): Promise<void> {
        let bookings = await this.api.client().get<IBooking[]>(`/bookings/by-day/${moment().format("YYYY-MM-DD")}`);

        let currentUnixTime = moment("", "YYYY-MM-DD HH:mm:ss").format("x");

        for (let booking of bookings) {
            let bookingStartUnixTime = moment(`${booking.localDay} ${booking.localStartTime}`, "YYYY-MM-DD HH:mm:ss").format("x");
            let bookingEndUnixTime = moment(`${booking.localDay} ${booking.localEndTime}`, "YYYY-MM-DD HH:mm:ss").format("x");

            if (currentUnixTime >= bookingStartUnixTime && currentUnixTime < bookingEndUnixTime) {
                console.log(currentUnixTime);
                console.log(bookingStartUnixTime);
                console.log(bookingEndUnixTime);

                if (booking.hasArrived) {
                    console.log("Checked In Count");
                    this.checkedIn += booking.persons;
                }
            }
        }
    }

    async openHcertScanDialog() {
        this.dialog.open(HcertScanDialogComponent, {});
    }

    hijackQRScan(callback) {
        this.hijackedQRScanCallback = callback;
    }

    releaseQRScanHijack() {
        delete this.hijackedQRScanCallback;
    }

    suppressInvoiceNotice() {
        this.unpaidInvoiceSuppressed = true;
    }

    async textScanned(text: string) {
        console.log('Text scanned:');
        console.log(text);

        // Check if scan is hijacked
        if (this.hijackedQRScanCallback) {
            console.log('Callback hijacked');
            this.hijackedQRScanCallback(text);
            return;
        }

        // Text is URL
        if (text.startsWith('https://')) {

            // URL is booking reference
            let bookingReferenceMatch = text.match(/(.+)\/api\/public\/clients\/(.+)\/bookings\/(.+)\/ticket/);
            if (bookingReferenceMatch && bookingReferenceMatch[2] === this.globals.clientId) {
                let booking = await this.api.client().get<IBooking>(`/bookings/${bookingReferenceMatch[3]}`);
                this.openBookingDialog({ booking: booking });
                return;
            }

            // URL is event booking reference
            let eventBookingReferenceMatch = text.match(/(.+)\/api\/public\/clients\/(.+)\/event-bookings\/(.+)\/ticket/);
            if (eventBookingReferenceMatch && eventBookingReferenceMatch[2] === this.globals.clientId) {
                let eventBooking = await this.api.client().get<IEventBooking>(`/event-bookings/${eventBookingReferenceMatch[3]}`);
                this.openEventBookingDialog({ eventBooking: eventBooking });
                return;
            }
        }
        // Text is booking ID
        else if (text.startsWith('B-')) {
            let booking = await this.api.client().get<IBooking>(`/bookings/${text.replace('B-', '')}`);
            this.openBookingDialog({ booking: booking });
            return;
        }

        // Text is event booking ID
        else if (text.startsWith('EB-')) {
            let eventBooking = await this.api.client().get<IEventBooking>(`/event-bookings/${text.replace('EB-', '')}`);
            this.openEventBookingDialog({ eventBooking: eventBooking });
            return;
        }

        // Text is a physical voucher ID
        else if (text.startsWith('PV-')) {
            this.dialog.open(VirtualGiftCardDialogComponent, {
                data: {
                    code: text
                }
            });
            return;
        }

        // Text is a HCERT
        else if (text.startsWith('HC1:')) {
            this.dialog.open(HcertInfoDialogComponent, {
                data: {
                    qrData: text,
                    hijackQRScan: (text) => this.hijackQRScan(text),
                    releaseQRScanHijack: () => this.releaseQRScanHijack()
                }
            });
            return;
        }


        this.dialog.open(UnknownTextScannedDialogComponent, {
            data: {
                text: text
            }
        });
    }

    async keyDown(e: KeyboardEvent) {
        if (this.inputBufferTimeoutHandler)
            clearTimeout(this.inputBufferTimeoutHandler);

        let code = e.which || e.keyCode;




        console.log('Key down');
        console.log(`Key: ${e.key}`);
        console.log(`Which: ${e.which} (${String.fromCharCode(e.which)})`);
        console.log(`KeyCode: ${e.keyCode} (${String.fromCharCode(e.keyCode)})`);

        if ((code > 47 && code < 58) || // number keys
            code == 32 || // spacebar & return key(s) (if you want to allow carriage returns)
            (code > 64 && code < 91) || // letter keys
            (code > 95 && code < 112) || // numpad keys
            (code > 185 && code < 193) || // ;=,-./` (in order)
            (code > 218 && code < 223)) {   // [\]' (in order))
            // let text = String.fromCharCode(code);
            this.inputBuffer += e.key;

            // this.inputBuffer += e.shiftKey ? text.toUpperCase() : text.toLowerCase();
        }



        this.inputBufferTimeoutHandler = setTimeout(() => {
            if (this.inputBuffer.length >= 5)
                if (!this.window.textScanDisabled) {
                    this.textScanned(this.inputBuffer);
                } else {
                    console.log('Text scan is disabled');
                }
            this.inputBuffer = '';
        }, 50);
    }

    //key up event for the daily notes text area
    dailyNotesKeyUp() {
        this.resetbackToTodayTimeout();
        this.updateDailyNoteSize();
    }

    //updates the size of the daily notes text area
    updateDailyNoteSize() {
        if (this.textArea) {
            this.textArea.nativeElement.style.height = 'auto';
            this.textArea.nativeElement.style.height = (this.textArea.nativeElement.scrollHeight + 20) + 'px';
        }
    }

}


