import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Utility } from '../../../services/utility';
import { Globals } from '../../../services/globals';
import { Api } from '../../../services/api';
import { DateAdapter } from '@angular/material/core';
import { IGiftCard } from 'src/app/interfaces/gift-card';
import { Currency } from '../../../services/currency';
import moment from 'moment';

@Component({
    selector: 'gift-card-report',
    templateUrl: './gift-card-report.component.html',
    styleUrls: ['./gift-card-report.component.scss']
})

export class GiftCardReportComponent implements OnInit, OnDestroy {
    giftCards: IGiftCard[];
    searchData: {
        code?: string,
        receiverName?: string,
        receiverEmail?: string,
        receiverPhone?: string
    } = {};
    loading: boolean = true;

    startDate: any = moment().subtract(1, "month").startOf('day').format('YYYY-MM-DD');
    endDate: any = moment().endOf('day').format('YYYY-MM-DD');

    showExpired: boolean;

    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility, private dateAdapter: DateAdapter<any>, public currency: Currency) {
        this.globals.clientSettingsReceived.subscribe(() => {
            this.dateAdapter.setLocale(`${this.globals.clientSettings.language}-${this.globals.clientSettings.country.toUpperCase()}`);
            this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
        });
    }

    print() {
        window.print();
    }

    async fetchLatestGiftCards() {
        this.loading = true;
        this.giftCards = await this.api.client().get(`/gift-cards/latest/50`);
        this.loading = false;
    }

    async searchGiftCards() {
        this.loading = true;
        this.giftCards = await this.api.client().post(`/gift-cards/search`, this.searchData);
        this.loading = false;
    }

    async fetchGiftCards() {
        if (this.searchData.code
            || this.searchData.receiverEmail
            || this.searchData.receiverName
            || this.searchData.receiverPhone) {
            this.searchGiftCards();
        }
        else {
            this.fetchGiftCardsForDates();
        }
    }

    getGiftCardUrl(giftCard) {
        if (giftCard.shortUrl)
            return giftCard.shortUrl.url;
        else
            return `${this.globals.baseUrl}/api/public/clients/${this.globals.clientId}/gift-cards/${giftCard.id}/view`;
    }

    async giftCardDetails(giftCard) {
        window.open(`${this.globals.baseUrl}/client/${this.globals.clientId}/gift-cards/${giftCard.id}`, "_blank");
    }

    async ngOnInit() {
        this.fetchGiftCards();
    }

    async ngOnDestroy() {

    }

    async fetchGiftCardsForDates() {
        let query = `startDate=${moment(this.startDate).format("YYYY-MM-DD")}&endDate=${moment(this.endDate).format("YYYY-MM-DD")}`;

        if(typeof (this.showExpired) !== 'undefined') {
            query += `&showExpired=${this.showExpired}`;
        }

        this.loading = true;
        this.giftCards = await this.api.client().get(`/gift-cards/dates?${query}`);
        this.loading = false;
    }

    updateVoucherReport() {
        this.fetchGiftCardsForDates();
    }

    toggleShowExpired() {
        if (typeof (this.showExpired) == 'undefined')
            this.showExpired = true;
        else if (this.showExpired)
            this.showExpired = false;
        else
            delete this.showExpired;

        this.updateVoucherReport();
    }

    getFilterOptionClass(value) {
        return {
            none: typeof (value) == 'undefined',
            'green-text': value === false,
            'red-text': value === true
        };
    }

    isExpired(giftCard: any) {
        return moment().isAfter(giftCard.expirationDate);
    }

}
