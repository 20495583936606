import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../../services/globals';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

@Component({
    selector: 'date-time-picker',
    templateUrl: './date-time-picker.component.html',
    styleUrls: ['./date-time-picker.component.scss']
})

export class DateTimePickerComponent implements OnInit, OnDestroy {
    @Input()
        value: Date;

    @Input()
        label: string;

    @Input()
        step: number;

    @Output()
        changed: EventEmitter<Date> = new EventEmitter<Date>();

    date: Date;

    time: string;


    constructor(private router: Router, private route: ActivatedRoute, private globals: Globals, private translate: TranslateService) {

    }


    async ngOnInit() {
        if(!this.step)
            this.step = 5;
        let dateTime = moment(this.value);
        this.date = dateTime.toDate();
        this.time = dateTime.format('HH:mm');
    }

    dateTimeChanged() {
        let value = moment(`${moment(this.date).format('YYYY-MM-DD')} ${this.time}`).toDate();
        console.log(value);
        this.changed.emit(value);
    }

    timeChanged() {
        let value = moment(`${moment(this.date).format('YYYY-MM-DD')} ${this.time}`).toDate();
        console.log(value);
        this.changed.emit(value);
    }


    async ngOnDestroy() {

    }
}
