import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CurrentUser } from '../../services/user';
import { TranslateService } from '@ngx-translate/core';
import moment, { duration } from 'moment';
import { ILogItem } from '../../../../../../../../../common/common-interfaces/log-item';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { LogDataDialogComponent } from './log-data-dialog/log-data-dialog.component';

@Component({
    selector: 'log-viewer',
    templateUrl: './log-viewer.component.html',

})

export class LogViewerComponent implements OnInit {
    @Input() log: any;

    displayedColumns = ['created', 'message'];

    constructor(private router: Router, private route: ActivatedRoute, public currentUser: CurrentUser,
        public translate: TranslateService, public snackBar: MatSnackBar, private matDialog: MatDialog) { }

    getLog(): ILogItem {
        if (this.log) {
            this.log.sort((a: any, b: any) => {
                return moment(b.created).valueOf() - moment(a.created).valueOf();
            });
        }
        return this.log;
    }

    ngOnInit(): void {
        if (this.currentUser.user && this.currentUser.user.role == 'superadmin') {
            this.displayedColumns = ['superadmin', 'created', 'message', 'data'];
        }
    }

    isObjectEmpty(json: string): boolean {
        if (!json) {
            return;
        }

        try {
            let keys = Object.keys(JSON.parse(json));
            if (!keys.length) {
                return true;
            }
        } catch (e) {
            return false;
        }

        return false;
    }


    openPreviousData(logItem: ILogItem): void {
        console.log(logItem);
        if (!logItem.data) {
            return;
        }

        if (this.isObjectEmpty(logItem.data)) {
            return;
        }

        this.matDialog.open(LogDataDialogComponent, {
            data: logItem
        });

    }
}
