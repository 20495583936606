import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Api } from '../../services/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, from } from 'rxjs';
import { Globals } from '../../services/globals';

import { GenerateTimeslots, CloneTimeslotsFromRole } from '../../helpers/timeslot-generator';
import { MatTable } from '@angular/material/table';
import { IPackage } from '../../interfaces/package';
import { IActivity } from '../../interfaces/activity';
import { TranslateService } from '@ngx-translate/core';
import { Utility } from '../../services/utility';
import { newPrice } from '../../interfaces/price';
import moment from 'moment';
import { addDuration, getStandardColor } from '../../helpers/utility';
import { IAddOn } from '../../interfaces/add-on';
import { CurrentUser } from '../../services/user';
import { IPromoCode } from '../../interfaces/promo-code';
import { isEmpty } from 'lodash';
import { ICustomerGroup } from 'src/app/interfaces/customer-group';

@Component({
  selector: 'package-administration',
  templateUrl: './package-administration.component.html',
  styleUrls: ['./package-administration.component.scss']
})

export class PackageAdministrationComponent implements OnInit, OnDestroy {

  package: IPackage | null = null;
  availableActivities: IActivity[];
  availableAddOns: IAddOn[];
  availablePromoCodes: IPromoCode[];
  selectedTimeslotInterval = null;
  availableFeedbackSections: any[];
  timeslotTable: any = null;
  displayedActivityColumns = ['name', 'quantity', 'remove'];
  displayedExtraActivityColumns = ['order', 'name', 'slots', 'allowBefore', 'allowDuring', 'allowAfter', 'remove'];

  availableCustomerGroups: any[] = [];

  private paramsSubscription?: Subscription;
  @ViewChild('associatedActivities', { static: false }) associatedActivitiesTable: MatTable<any>;
  @ViewChild('extraActivities', { static: false }) extraActivitiesTable: MatTable<any>;
  @ViewChild('tabGroup', { static: false }) tabGroup;

  constructor(private api: Api, private router: Router, private route: ActivatedRoute, public globals: Globals, private utility: Utility, public currentUser: CurrentUser) {

  }


  resetInterval() {
    delete this.package.timeslotInterval;
    delete this.selectedTimeslotInterval;
    delete this.package.timeslots;
  }


  async generateTimeslots(role) {
    await GenerateTimeslots(role,
      this.package.timeslotInterval || this.selectedTimeslotInterval || '',
      this.package,
      false, 0);
  }



  async enabledTimeslotSchemeChanged(role) {
    if (this.package.enabledTimeslotSchemes[role] && !this.package.timeslots[role]) {
      this.package.timeslots[role] = await CloneTimeslotsFromRole(this.package.timeslots, 'public');
      //this.generateTimeslots(role);
    }
  }

  async clone() {
    let result = await this.api.client().post<any>(`/manage/packages/${this.package.id}/clone`, {});
    window.location.href = `/client/${this.globals.clientId}/packages/${result.newPackage.id}`;
  }


  async addActivity(activity) {
    this.package.associatedActivities.push({
      activityId: activity.id,
      name: activity.name,
      quantity: 1
    });

    this.associatedActivitiesTable.renderRows();
  }

  async imageChanged(image) {
    this.package.image = image;

    this.associatedActivitiesTable.renderRows();
  }

  async removeActivity(activity) {
    this.package.associatedActivities.splice(this.package.associatedActivities.indexOf(activity), 1);
    this.associatedActivitiesTable.renderRows();
  }


  async addExtraActivity(activity) {
    this.package.extraActivities.push({
      activityId: activity.id,
      name: activity.name,
      slots: 1,
      allowBefore: true,
      allowDuring: true,
      allowAfter: true,
      summary: activity.summary ? activity.summary : ""
    });

    this.extraActivitiesTable.renderRows();
  }

  async removeExtraActivity(extraActivity) {
    this.package.extraActivities.splice(this.package.extraActivities.indexOf(extraActivity), 1);
    this.extraActivitiesTable.renderRows();
  }

  moveExtraActivityUp(extraActivity) {
    let index = this.package.extraActivities.indexOf(extraActivity);
    if (index > 0) {
      this.package.extraActivities.splice(index - 1, 0, this.package.extraActivities.splice(index, 1)[0]);
      this.extraActivitiesTable.renderRows();
    }
  }

  moveExtraActivityDown(extraActivity) {
    let index = this.package.extraActivities.indexOf(extraActivity);
    if (index < this.package.extraActivities.length - 1) {
      this.package.extraActivities.splice(index + 1, 0, this.package.extraActivities.splice(index, 1)[0]);
      this.extraActivitiesTable.renderRows();
    }
  }
  /*
    removeExtraOrderRow(extraOrderRow) {
      this.package.extraOrderRows.splice(this.package.extraOrderRows.indexOf(extraOrderRow), 1);
    }

    addExtraOrderRow() {
      this.package.extraOrderRows.push({
        name: '',
        price: newPrice()
      });
    }
    */
  /*
    getTotalPrice() {
      return this.package.price.withVat + this.package.extraOrderRows.reduce((sum, row) => sum + row.price.withVat, 0);
    }
  */
  async extraActivityQuantityChanged(extraActivity) {

  }


  async save() {


    console.log("Summary", this.package.summary)


    // Check if package should be created or updated
    if (this.package && this.package.id) {
      console.log(this.package);
      let result = await this.api.client().patch<any>(`/manage/packages/${this.package.id}`, this.package);
    }
    else {
      let result = await this.api.client().put<any>(`/manage/packages`, this.package);
      if (result.succeeded) {
        this.router.navigateByUrl(`/${this.globals.clientId}/packages/${result.package.id}`);
      }
    }
  }

  async ngOnInit() {
    this.paramsSubscription = this.route.params.subscribe(async (params) => {
      if (params['id']) {
        this.package = await this.api.client().get<IPackage>(`/manage/packages/${params['id']}`);

        if (this.package.customTimeslotPeriods) {
          for (let timeslotPeriod of this.package.customTimeslotPeriods) {
            timeslotPeriod.startDate = moment(timeslotPeriod.startDate).toDate();
            timeslotPeriod.endDate = moment(timeslotPeriod.endDate).toDate();
          }
        }


        if (!this.package.type) {
          this.package.type = 'NONE';
        }

        if (!this.package.enabledTimeslotSchemes) {
          this.package.enabledTimeslotSchemes = { public: true };
        }
        if (!this.package.allocationSettings) {
          this.package.allocationSettings = { single: {}, multiple: {}, all: {} };
        }

        if (!this.package.minPeoplePerBooking) {
          this.package.minPeoplePerBooking = 1;
        }
        if (!this.package.maxPeoplePerBooking) {
          this.package.maxPeoplePerBooking = 100;
        }

        if (!this.package.activitySequences) {
          this.package.activitySequences = [];
        }

        if (!this.package.restrictTimeslots) {
          this.package.restrictTimeslots = true;
        }

        if (!this.package.color) {
          this.package.color = this.utility.randomColor();
        }

        if (!this.package.foresightMode) {
          this.package.foresightMode = 'NONE';
        }

        if (!this.package.foresightMinutes) {
          this.package.foresightMinutes = 60;
        }

        if (!this.package.extraActivities) {
          this.package.extraActivities = [];
        }


        if (!this.package.priceType) {
          this.package.priceType = 'PER_PERSON';
        }

        if (!this.package.enabledAddOnIds) {
          this.package.enabledAddOnIds = {};
        }

        if (!this.package.priceSettings || !this.package.priceSettings.length) {
          this.package.priceSettings = [{
            name: '',
            color: getStandardColor(0),
            customerGroupPrices: (this.package as any).customerGroupPrices || {},
            extraOrderRows: (this.package as any).extraOrderRows || [],
            price: (this.package as any).price || newPrice(),
            inactive: false
          }];
        }

        if (this.package.priceSettings[0] && !this.package.priceSettings[0].customerGroupPrices) {
          this.package.priceSettings[0].customerGroupPrices = {};
        }

        for (let priceSettings of this.package.priceSettings) {
          if (!priceSettings.customerGroupPrices) {
            priceSettings.customerGroupPrices = {};
          }
        }

        if (!this.package.feedbackSections) {
          this.package.feedbackSections = {};
        }

        if (!this.package.paymentRequiredDateMode) {
          this.package.paymentRequiredDateMode = 'AFTER_BOOKING';
          this.package.paymentRequiredMinutes = 60;
        }

        if (!this.package.campaignLabel) {
          this.package.campaignLabel = {
            text: '',
            foregroundColor: '#ffffff',
            backgroundColor: '#5eaf39'
          };
        }

        if (!this.package.displayTimeAdjustments || isEmpty(this.package.displayTimeAdjustments)) {
          this.package.displayTimeAdjustments = {
            adjustEndTime: false,
            endTimeAdjustment: 0,
          };
        }

        if (!this.package.enabledAddOnConditions) {
          this.package.enabledAddOnConditions = {};
        }

        if (!this.package.autoApplyPromoCodeIds) {
          this.package.autoApplyPromoCodeIds = [];
        }

        if (!this.package.customerGroupRules) {
          this.package.customerGroupRules = [];
        }

        for (let addOnId in this.package.enabledAddOnIds) {
          if (!this.package.enabledAddOnConditions[addOnId]) {
            this.package.enabledAddOnConditions[addOnId] = {
              isConditional: false,
              customText: '',
              hideUnavailable: false,
              requiredExtraActivityIds: [],
              useCustomText: false
            };
          }
        }


        if (this.globals.clientSettings.enableBookingReminderEmail) {
          this.package.enableBookingReminderEmail = true;
        }

        if (this.globals.clientSettings.enableBookingReminderSms) {
          this.package.enableBookingReminderSms = true;
        }

        if (!this.package.reservationAmountSettings) {
          this.package.reservationAmountSettings = {
            enabled: false,
            type: "FIXED",
            reservationAmount: 0,
            reservationPercent: 0,
            paymentMode: "FULL",
            recurringPaymentEnabled: false
          };
        }

        if (!this.package.reservationAmountSettings.reservationGracePeriodDateMode) {
          this.package.reservationAmountSettings.reservationGracePeriodDateMode = 'AFTER_BOOKING';
          this.package.reservationAmountSettings.reservationGracePeriodMinutes = 60;
        }

        if (!this.package.summary) this.package.summary = "";

        this.availableActivities = await this.api.client().get<IActivity[]>(`/manage/activities`);

        this.availableAddOns = await this.api.client().get<IAddOn[]>(`/manage/add-ons`);

        if (this.globals.clientSettings.enableCustomerFeedbackEmail)
          this.availableFeedbackSections = await this.api.client().get<any[]>(`/manage/feedback-sections`);

        this.availablePromoCodes = await this.api.client().get<IPromoCode[]>(`/manage/promo-codes?hideAutoGenerated=true`);
      }
      else {
        this.package = {
          duration: '00:00',
          timeslots: {},
          priceType: 'PER_PERSON',
          type: null,
          summary: "",
          enabledTimeslotSchemes: { public: true },
          associatedActivities: [],
          activityAllocationType: 'SINGLE',
          allocationSettings: { single: {}, multiple: {}, all: {} },
          minPeoplePerBooking: 1,
          maxPeoplePerBooking: 100,
          activitySequences: [],
          extraActivities: [],
          enabledAddOnIds: {},
          enabledAddOnConditions: {},
          restrictTimeslots: true,
          color: this.utility.randomColor(),
          foresightMode: 'NONE',
          foresightMinutes: 60,
          priceSettings: [{
            name: '',
            color: getStandardColor(0),
            customerGroupPrices: {},
            extraOrderRows: [],
            price: newPrice(),
            inactive: false
          }],
          feedbackSections: {},
          lockToCustomerGroup: false,
          lockExtraActivitiesToCustomerGroup: false,
          paymentRequired: false,
          paymentRequiredDateMode: 'AFTER_BOOKING',
          paymentRequiredMinutes: 60,
          restrictPaidCancelOrChange: false,
          isDayPass: false,
          campaignLabel: {
            text: '',
            foregroundColor: '#ffffff',
            backgroundColor: '#5eaf39'
          },
          autoApplyPromoCodeIds: [],
          displayTimeAdjustments: {
            adjustEndTime: false,
            endTimeAdjustment: 0,
          },
          enableCustomerGroupRules: false,
          customerGroupRules: [],
          extraTerms: [],
        };
      }

      //fetch customerGroups for customerGroupRules
      this.availableCustomerGroups = await this.api.client().get('/manage/customer-groups');
      this.availableCustomerGroups = this.availableCustomerGroups.filter(cg => !cg.disabled);
    });

  }

  addOnEnabledChanged(addOn: IAddOn) {
    if (!this.package.enabledAddOnConditions[addOn.id]) {
      this.package.enabledAddOnConditions[addOn.id] = {
        customText: '',
        isConditional: false,
        requiredExtraActivityIds: [],
        useCustomText: false,
        hideUnavailable: false
      };
    }
  }

  async ngOnDestroy() {
    if (this.paramsSubscription)
      this.paramsSubscription.unsubscribe();
  }
}
