import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ICustomerFeedback } from '../../../../../interfaces/customer-feedback';
import { Api } from '../../../../../services/api';
import { Globals } from '../../../../../services/globals';
import { Utility } from '../../../../../services/utility';


@Component({
    selector: 'text-feedback-section-report',
    templateUrl: './text-feedback-section-report.component.html',
    styleUrls: ['./text-feedback-section-report.component.scss']
})

export class TextFeedbackSectionReportComponent implements OnInit, OnDestroy, OnChanges {
    @Input()
        feedbackSection: any;
    @Input()
        data: {
        customerFeedback: ICustomerFeedback,
        sectionData: any
    }[];
    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility, private translate: TranslateService) {

    }

    async fetchCustomerFeedbackBooking(customerFeedback) {
        customerFeedback.booking = await this.api.client().get<any>(`/bookings/${customerFeedback.bookingId}`);
    }

    async fetchCustomerFeedbackEventBooking(customerFeedback) {
        customerFeedback.eventBooking = await this.api.client().get<any>(`/event-bookings/${customerFeedback.eventBookingId}`);
    }

    ngOnChanges(changes: SimpleChanges): void {

    }

    async ngOnInit() {

    }

    async ngOnDestroy() {

    }
}
