import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import * as  moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Api } from '../../../services/api';
import { DomSanitizer } from '@angular/platform-browser';
import { IBooking, IBookingAddOn } from '../../../../../../../src/app/interfaces/booking';
import { Globals } from '../../../services/globals';
import { CurrentUser } from '../../../services/user';
import { Currency } from '../../../services/currency';
import { IResourceBooking, IResourceBookingResource } from 'src/app/interfaces/resource-booking';
import { RebookResourceBookingInfoDialogComponent } from './rebook-resource-booking-info-dialog/rebook-resource-booking-info-dialog.component';
import { QuickBookingDialogComponent } from '../quick-booking-dialog/quick-booking-dialog.component';
import { ICustomer } from '../../../../../../../src/app/interfaces/booking-base';

@Component({
    selector: 'resource-booking-info-dialog',
    templateUrl: './resource-booking-info-dialog.component.html',
    styleUrls: ['./resource-booking-info-dialog.component.scss']
})

export class ResourceBookingInfoDialogComponent implements OnInit {
    public resourceBooking: IResourceBooking;
    public resourceBookingResource: IResourceBookingResource;

    public bookingChanged;
    public bookingCanceled;

    @ViewChild('tabGroup', { static: false })
    tabGroup: MatTabGroup;

    @ViewChild('staffCommentTextarea', { static: false })
    textArea: ElementRef<HTMLTextAreaElement>;
    public refetchRequired: boolean;
    public checkingIn = false;
    public showPaymentText = false;
    public showCancelSureText = false;
    public sendingToCashRegister = false;
    public finishingOrAbortingCashRegisterTransaction = false;

    waitForCashRegisterInterval;
    loading = false;

    customerEditMode;
    originalEditCustomerJSON: string;
    editCustomer: ICustomer;
    editCustomerIsDirty: boolean;
    savingCustomerInfo = false;

    notChangeable = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog, private dialogRef: MatDialogRef<ResourceBookingInfoDialogComponent>, private translate: TranslateService, private api: Api, private sanitizer: DomSanitizer, public globals: Globals, public currentUser: CurrentUser, private currency: Currency) {
        this.resourceBooking = data.resourceBooking;
        this.resourceBookingResource = data.resourceBookingResource;

        this.bookingChanged = data.bookingChanged;
        this.bookingCanceled = data.bookingCanceled;

        this.dialogRef.backdropClick().subscribe(() => {
            this.close();
        });

        if (this.resourceBooking.waitingForCashRegister) {
            this.waitForCashRegister();
        }
    }

    cancelBooking() {
        this.bookingCanceled();
        this.dialogRef.close();
    }

    metadataHasChanged() {
        this.refetchRequired = true;
    }



    async ngAfterViewInit() {

    }

    async rebook() {
        this.dialog.open(RebookResourceBookingInfoDialogComponent, {
            data: {
                proceed: async (data) => {
                    this.loading = true;

                    await this.cancel(true);
                    this.close();

                    this.dialog.open(QuickBookingDialogComponent, {
                        data: {
                            resourceBookingToRebook: this.resourceBooking,
                            bookingCreated: (booking) => {
                                this.bookingChanged(true);
                            }
                        }
                    });
                },
                cancel: (data) => {

                }
            }
        });
    }

    async updateBookingObject(data: { resourceBooking: IResourceBooking, refetch?: boolean }) {
        this.resourceBooking.price = data.resourceBooking.price;
        this.resourceBooking.log = data.resourceBooking.log;
        this.resourceBooking.persons = data.resourceBooking.persons;
        this.resourceBooking.orderRows = data.resourceBooking.orderRows;
        this.resourceBooking.isPaid = data.resourceBooking.isPaid;
        this.resourceBooking.hasArrived = data.resourceBooking.hasArrived;

        this.resourceBooking.waitingForCashRegister = data.resourceBooking.waitingForCashRegister;
        this.resourceBooking.cashRegisterTransaction = data.resourceBooking.cashRegisterTransaction;

        if (data.refetch)
            this.refetchRequired = true;

        this.bookingChanged();
    }


    async updateStaffComment() {
        await this.api.client().put<any>(`/resource-bookings/${this.resourceBooking.id}/staff-comment`, { staffComment: this.resourceBooking.staffComment });
        this.bookingChanged();
        if (this.textArea) {
            this.textArea.nativeElement.style.height = 'auto';
            this.textArea.nativeElement.style.height = (this.textArea.nativeElement.scrollHeight) + 'px';

            if (this.resourceBooking.staffComment == "") {
                this.textArea.nativeElement.style.height = "24px";
            }
        }
    }



    updateStaffCommentSize() {
        if (this.textArea) {
            this.textArea.nativeElement.style.height = 'auto';
            this.textArea.nativeElement.style.height = (this.textArea.nativeElement.scrollHeight) + 'px';

            if (this.resourceBooking.staffComment == "") {
                this.textArea.nativeElement.style.height = "24px";
            }
        }
    }


    backToCheckIn() {
        this.tabGroup.selectedIndex = 0;
    }


    async checkIn() {
        if (!this.checkingIn) {

            // Booking is pre-paid and has not changed
            if (this.resourceBooking.isPaid) {
                await this.finishCheckIn();
            }
            // Booking is not pre-paid and not changed
            else {
                if (!this.showPaymentText) {
                    this.showPaymentText = true;
                    return;
                }
                else {
                    this.checkingIn = true;
                    await this.finishCheckIn();
                    return;
                }
            }

            this.checkingIn = false;
        }
    }

    async finishCheckIn() {
        let result = await this.api.client().post<any>(`/resource-bookings/${this.resourceBooking.id}/check-in`, {});
        if (result.succeeded) {
            let resourceBooking = await this.api.client().get<IResourceBooking>(`/resource-bookings/${this.resourceBooking.id}`);
            this.resourceBooking.hasArrived = true;
            this.resourceBooking.arrivalDate = resourceBooking.arrivalDate;
            this.resourceBooking.isPaid = resourceBooking.isPaid;
            this.resourceBooking.log = resourceBooking.log;
            this.checkingIn = false;
            this.bookingChanged();
        } else {

        }
    }

    async sendToCashRegister() {
        if (!this.sendingToCashRegister) {
            this.sendingToCashRegister = true;

            let result = await this.api.client().post<any>(`/resource-bookings/${this.resourceBooking.id}/cash-register/create-transaction`, {});

            if (result.succeeded) {
                this.resourceBooking.waitingForCashRegister = result.waitingForCashRegister;
                if (this.resourceBooking.waitingForCashRegister) {
                    this.waitForCashRegister();
                }
                else {
                    await this.finishCashRegisterTransaction();
                }
            }

            this.sendingToCashRegister = false;
        }
    }

    waitForCashRegister() {
        this.waitForCashRegisterInterval = setInterval(async () => {
            let resourceBooking = await this.api.client().get<IResourceBooking>(`/resource-bookings/${this.resourceBooking.id}`);
            if (!resourceBooking.waitingForCashRegister) {
                this.updateBookingObject({ resourceBooking: resourceBooking, refetch: true });
                clearInterval(this.waitForCashRegisterInterval);
                delete this.waitForCashRegisterInterval;
            }
        }, 3000);
    }

    async cancel(skipCheck?: boolean) {
        if (!this.showCancelSureText && !skipCheck) {
            this.showCancelSureText = true;
        }
        else {
            this.checkingIn = true;
            let result = await this.api.publicClient().post<any>(`/resource-bookings/${this.resourceBooking.id}/cancel`, {});
            if (result.succeeded) {
                let resourceBooking = await this.api.client().get<IBooking>(`/resource-bookings/${this.resourceBooking.id}`);
                this.resourceBooking.isCanceled = resourceBooking.isCanceled;
                this.resourceBooking.cancelDate = resourceBooking.cancelDate;
                this.resourceBooking.log = resourceBooking.log;
                this.cancelBooking();
            }
        }
    }

    getCheckInButtonText() {
        if (this.showPaymentText)
            return this.translate.instant('HAVE_YOU_BEEN_PAID');
        else
            return this.translate.instant('CHECK_IN_BUTTON');
    }

    getCancelButtonText() {
        return this.showCancelSureText ? this.translate.instant('ARE_YOU_SURE') : this.translate.instant('CANCEL_BOOKING');
    }


    async close() {
        if (this.editCustomerIsDirty)
            await this.saveCustomerEdit();
        if (this.refetchRequired)
            this.bookingChanged(true);
        this.dialogRef.close();
    }


    async finishCashRegisterTransaction() {
        this.finishingOrAbortingCashRegisterTransaction = true;
        let result = await this.api.client().post<any>(`/resource-bookings/${this.resourceBooking.id}/cash-register/finish-transaction`, {});
        if (result.succeeded) {
            this.updateBookingObject({ resourceBooking: result.resourceBooking, refetch: true });
            clearInterval(this.waitForCashRegisterInterval);
            delete this.waitForCashRegisterInterval;
        }
        this.finishingOrAbortingCashRegisterTransaction = false;
    }

    async abortCashRegisterTransaction() {
        this.finishingOrAbortingCashRegisterTransaction = true;
        let result = await this.api.client().post<any>(`/resource-bookings/${this.resourceBooking.id}/cash-register/abort-transaction`, {});
        if (result.succeeded) {
            this.updateBookingObject({ resourceBooking: result.resourceBooking, refetch: true });
            clearInterval(this.waitForCashRegisterInterval);
            delete this.waitForCashRegisterInterval;
        }
        this.finishingOrAbortingCashRegisterTransaction = false;
    }

    async startCustomerEdit() {
        this.editCustomer = Object.assign({}, this.resourceBooking.customer) as ICustomer;
        this.originalEditCustomerJSON = JSON.stringify(this.editCustomer);
        this.customerEditMode = true;
    }

    async cancelCustomerEdit() {
        this.customerEditMode = false;
    }

    async customerEditChanged() {
        if (JSON.stringify(this.editCustomer) != this.originalEditCustomerJSON) {
            this.editCustomerIsDirty = true;
            this.refetchRequired = true;
        }
        else {
            this.editCustomerIsDirty = false;
        }
    }

    async saveCustomerEdit() {
        this.savingCustomerInfo = true;
        let result = await this.api.client().post<any>(`/resource-bookings/${this.resourceBooking.id}/change/customer-info`, this.editCustomer);
        if (result.succeeded) {
            this.resourceBooking.customer = result.booking.customer;
        }
        this.savingCustomerInfo = false;
        this.customerEditMode = false;
    }

    ngOnDestroy() {
        if (this.waitForCashRegisterInterval)
            clearInterval(this.waitForCashRegisterInterval);
    }

    async ngOnInit() {

        if (this.resourceBooking.gameIntegrationData) {
            if (this.resourceBooking.gameIntegrationData.isVbsBooking
                && this.resourceBooking.gameIntegrationData.vbsData.origin == 'VBS'
                && !this.globals.clientSettings.gameIntegrationSettings.vbsSettings.allowResourceBookingCancellation) {
                this.notChangeable = true;
            }

            if (this.resourceBooking.gameIntegrationData.isBowlitBooking
                && this.resourceBooking.gameIntegrationData.bowlitData.origin == 'BOWLIT'
                && !this.globals.clientSettings.gameIntegrationSettings.bowlitSettings.allowResourceBookingCancellation) {
                this.notChangeable = true;
            }
        }

        if (!this.notChangeable)
            this.startCustomerEdit();
    }
}
