// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.line-through {
  opacity: 0.5;
}

.internal-description {
  font-style: italic;
  opacity: 0.7;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/events/events.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/events/events.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;ACCJ;;ADEA;EACI,kBAAA;EACA,YAAA;ACCJ","sourcesContent":[".line-through{\r\n    opacity: .5;\r\n}\r\n\r\n.internal-description {\r\n    font-style: italic;\r\n    opacity: 0.7;\r\n  }",".line-through {\n  opacity: 0.5;\n}\n\n.internal-description {\n  font-style: italic;\n  opacity: 0.7;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
