// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sales-report-table .sales-table {
  font-size: 12px;
}
.sales-report-table .vat-table {
  font-size: 12px;
}
.sales-report-table .gift-card-table {
  font-size: 12px;
}
.sales-report-table .total-table {
  font-size: 12px;
}
.sales-report-table h2 {
  margin-left: 10px;
}
.sales-report-table .dark-column {
  background: rgba(0, 0, 0, 0.02);
}
@media print {
  .sales-report-table .sales-table,
  .sales-report-table .vat-table,
  .sales-report-table .gift-card-table {
    font-size: 3mm;
  }
  .sales-report-table .sales-table tr td,
  .sales-report-table .vat-table tr td,
  .sales-report-table .gift-card-table tr td {
    padding: 1mm;
  }
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/reports/sales-simplified/simplified-sales-report-table/simplified-sales-report-table.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/reports/sales-simplified/simplified-sales-report-table/simplified-sales-report-table.component.scss"],"names":[],"mappings":"AACI;EACE,eAAA;ACAN;ADGI;EACE,eAAA;ACDN;ADII;EACE,eAAA;ACFN;ADKI;EACE,eAAA;ACHN;ADMI;EACE,iBAAA;ACJN;ADOI;EACE,+BAAA;ACLN;ADQI;EACE;;;IAGE,cAAA;ECNN;EDQM;;;IACE,YAAA;ECJR;AACF","sourcesContent":[".sales-report-table {\r\n    .sales-table {\r\n      font-size: 12px;\r\n    }\r\n  \r\n    .vat-table {\r\n      font-size: 12px;\r\n    }\r\n  \r\n    .gift-card-table {\r\n      font-size: 12px;\r\n    }\r\n  \r\n    .total-table {\r\n      font-size: 12px;\r\n    }\r\n  \r\n    h2 {\r\n      margin-left: 10px;\r\n    }\r\n  \r\n    .dark-column {\r\n      background: rgb(0 0 0 / 2%);\r\n    }\r\n  \r\n    @media print {\r\n      .sales-table,\r\n      .vat-table,\r\n      .gift-card-table {\r\n        font-size: 3mm;\r\n  \r\n        tr td {\r\n          padding: 1mm;\r\n        }\r\n      }\r\n    }\r\n  }\r\n  ",".sales-report-table .sales-table {\n  font-size: 12px;\n}\n.sales-report-table .vat-table {\n  font-size: 12px;\n}\n.sales-report-table .gift-card-table {\n  font-size: 12px;\n}\n.sales-report-table .total-table {\n  font-size: 12px;\n}\n.sales-report-table h2 {\n  margin-left: 10px;\n}\n.sales-report-table .dark-column {\n  background: rgba(0, 0, 0, 0.02);\n}\n@media print {\n  .sales-report-table .sales-table,\n  .sales-report-table .vat-table,\n  .sales-report-table .gift-card-table {\n    font-size: 3mm;\n  }\n  .sales-report-table .sales-table tr td,\n  .sales-report-table .vat-table tr td,\n  .sales-report-table .gift-card-table tr td {\n    padding: 1mm;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
