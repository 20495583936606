import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Utility } from '../../../services/utility';
import { Globals } from '../../../services/globals';
import { Api } from '../../../services/api';
import { IBooking, IBookingAddOn } from '../../../../../../../src/app/interfaces/booking';
import { DateAdapter } from '@angular/material/core';
import { IPackage } from '../../../interfaces/package';
import { IResourceBooking } from '../../../../../../../src/app/interfaces/resource-booking';
import { IBookingBase } from '../../../../../../../src/app/interfaces/booking-base';
import { getFirstTimeslot, getLastTimeslot } from '../../../../../../../../../../common/common-helpers/utility';
import { IAddOn } from '../../../interfaces/add-on';
import { minutesFromDuration } from '../../../helpers/utility';

interface IBookingData {
    created: Date,
    startDate: Date,
    booking?: IBooking,
    bookingBase: IBookingBase,
    resourceBooking?: IResourceBooking
}

interface IResourceData {
    name: string,
    resourceId: string,
    startTime: string,
    endTime: string,
    persons: number,
    booking?: IBooking,
    bookingBase: IBookingBase,
    resourceBooking?: IResourceBooking,
    addOns?: IBookingAddOn[]
}

@Component({
    selector: 'resource-day-report',
    templateUrl: './resource-day-report.component.html',
    styleUrls: ['./resource-day-report.component.scss']
})
export class ResourceDayReportComponent implements OnInit, OnDestroy {
    loading: boolean = true;
    availableResources: any[];
    availableAddOns: any[];
    birthdayInfo: boolean;
    day: string = moment().format('YYYY-MM-DD');
    selectedDate: Date = moment().toDate();
    bookingData: IBookingData[];
    resourceData: IResourceData[];

    columnNames = ['START_TIME', 'END_TIME', 'RESOURCE', 'BOOKING_NUMBER', 'NAME', 'PERSONS', 'ADD_ONS', 'CUSTOMER_COMMENT', 'STAFF_COMMENT', 'BIRTHDAY'];
    summary: {
        addOnQuantities: { [addOnId: string]: number }
    };

    constructor(private api: Api, private router: Router, private route: ActivatedRoute, public globals: Globals, public utility: Utility, private dateAdapter: DateAdapter<any>) {
        this.globals.clientSettingsReceived.subscribe(() => {
            this.dateAdapter.setLocale(`${this.globals.clientSettings.language}-${this.globals.clientSettings.country.toUpperCase()}`);
            this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
        });
    }

    print() {
        window.print();
    }

    getFilterOptionClass(value, reverse?) {
        return {
            none: typeof (value) == 'undefined',
            'green-text': reverse ? value === false : value,
            'red-text': reverse ? value === true : value === false
        };
    }

    toggleBirthdayInfo() {
        if (typeof (this.birthdayInfo) == 'undefined')
            this.birthdayInfo = true;
        else if (this.birthdayInfo)
            this.birthdayInfo = false;
        else
            delete this.birthdayInfo;
        this.fetchBookings();
    }



    async fetchBookings() {
        this.loading = true;
        this.bookingData = [];
        this.resourceData = [];
        this.summary = {
            addOnQuantities: {}
        };

        let addOnIds = {};
        this.globals.userSettings.resourceDayReportSettings.selectedAddOnIds.forEach(id => addOnIds[id] = true);

        let resourceAllocations = await this.api.client().get<any[]>(`/resource-allocations/by-day/${this.day}`);
        let resourceAllocationsById: { [id: string]: any } = {};
        for (let resourceAllocation of resourceAllocations) {
            resourceAllocationsById[resourceAllocation._id] = resourceAllocation;
        }

        let bookings = await this.api.client().get<IBooking[]>(`/bookings/by-day/${this.day}`);
        for (let booking of bookings) {
            for (let _package of booking.packages) {
                let resourcesInPackage: {
                    [resourceId: string]: {
                        startTime: string,
                        endTime: string,
                        name: string,
                        resourceId: string
                    }
                } = {};
                for (let activity of _package.activities) {


                    for (let resource of activity.resources) {
                        if (this.globals.userSettings.resourceDayReportSettings.selectedResourceIds.includes(resource.resourceId)) {
                            if (resourceAllocationsById[resource.resourceAllocationId]) {
                                if (resourcesInPackage[resource.resourceId]) {
                                    resourcesInPackage[resource.resourceId].startTime = getFirstTimeslot(resourcesInPackage[resource.resourceId].startTime, resourceAllocationsById[resource.resourceAllocationId].startTime);
                                    resourcesInPackage[resource.resourceId].endTime = getLastTimeslot(resourcesInPackage[resource.resourceId].endTime, resourceAllocationsById[resource.resourceAllocationId].endTime);
                                } else {
                                    resourcesInPackage[resource.resourceId] = {
                                        name: resource.name,
                                        resourceId: resource.resourceId,
                                        startTime: resourceAllocationsById[resource.resourceAllocationId].startTime,
                                        endTime: resourceAllocationsById[resource.resourceAllocationId].endTime,
                                    };
                                }
                            }
                        }
                    }
                }

                for (let resourceId in resourcesInPackage) {
                    if (this.globals.userSettings.resourceDayReportSettings.selectedAddOnIds.length == 0 || _package.addOns.some(a => addOnIds[a.addOnId])) {
                        this.resourceData.push({
                            name: resourcesInPackage[resourceId].name,
                            startTime: resourcesInPackage[resourceId].startTime,
                            endTime: resourcesInPackage[resourceId].endTime,
                            resourceId: resourcesInPackage[resourceId].resourceId,
                            persons: _package.persons,
                            bookingBase: booking,
                            booking: booking,
                            addOns: _package.addOns
                        });
                    }
                }

                for (let addOn of _package.addOns) {
                    if (!this.summary.addOnQuantities[addOn.addOnId])
                        this.summary.addOnQuantities[addOn.addOnId] = 0;

                    if (this.globals.userSettings.resourceDayReportSettings.selectedAddOnIds.length == 0
                        || this.globals.userSettings.resourceDayReportSettings.selectedAddOnIds.includes(addOn.addOnId))
                        this.summary.addOnQuantities[addOn.addOnId] += addOn.quantity;
                }

            }
        }

        let resourceBookings = await this.api.client().get<IResourceBooking[]>(`/resource-bookings/by-day/${this.day}`);
        if (resourceBookings) {
            for (let resourceBooking of resourceBookings) {
                for (let resource of resourceBooking.resources) {
                    if (this.globals.userSettings.resourceDayReportSettings.selectedResourceIds.includes(resource.resourceId)) {
                        if (this.globals.userSettings.resourceDayReportSettings.selectedAddOnIds.length == 0) {
                            this.resourceData.push({
                                name: resource.name,
                                startTime: resource.startTime,
                                endTime: resource.endTime,
                                resourceId: resource.resourceId,
                                persons: resourceBooking.persons,
                                bookingBase: resourceBooking,
                                resourceBooking: resourceBooking,
                                addOns: []
                            });
                        }
                    }
                }
            }
        }

        this.resourceData.sort((x, y) => minutesFromDuration(x.startTime) - minutesFromDuration(y.startTime));

        await this.globals.saveUserSettings();
        this.loading = false;
    }

    async dateChanged() {
        this.day = moment(this.selectedDate).format('YYYY-MM-DD');
        this.fetchBookings();
    }

    async saveUserSettings() {
        await this.globals.saveUserSettings();
    }

    async ngOnInit() {
        this.globals.userSettingsReceived.subscribe(userSettings => {
            if (!userSettings.resourceDayReportSettings) {
                userSettings.resourceDayReportSettings = {
                    selectedAddOnIds: [],
                    selectedResourceIds: [],
                    visibleColumns: this.columnNames.reduce((value, current) => { value[current] = true; return value; }, {})
                };
            }

            if (!userSettings.resourceDayReportSettings.visibleColumns) {
                userSettings.resourceDayReportSettings.visibleColumns = this.columnNames.reduce((value, current) => { value[current] = true; return value; }, {});
            }
        });


        this.availableResources = await this.api.client().get(`/resources`);
        this.availableAddOns = await this.api.client().get(`/add-ons`);
        this.fetchBookings();
    }

    async ngOnDestroy() {

    }
}
