export interface IPrice {
    withVat: number;
    withoutVat: number;
    vat: number;
    vatPercentage: number;
}

export interface IPriceSummary {
    vatCharges: { [percentage: number]: number }
    withVat: number;
    withoutVat: number;
    vat: number;
}

export const newPrice = (amount?: number) => {
    return {
        withVat: amount ? amount : 0,
        withoutVat: amount ? amount : 0,
        vat: 0,
        vatPercentage: 0
    };
};

export const newPriceSummary = (): IPriceSummary => {
    return {
        vat: 0,
        vatCharges: {},
        withVat: 0,
        withoutVat: 0
    };
};