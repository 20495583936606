import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Utility } from '../../../services/utility';
import { Globals } from '../../../services/globals';
import { Api } from '../../../services/api';
import { Currency } from '../../../services/currency';
import { CurrentUser } from '../../../services/user';
import { DateAdapter } from '@angular/material/core';


@Component({
    selector: 'sales-report',
    templateUrl: './sales-report.component.html',
    styleUrls: ['./sales-report.component.scss']
})

export class SalesReportComponent implements OnInit, OnDestroy {
    startDate: Date = moment().startOf('day').subtract(1, 'day').toDate();
    endDate: Date = moment().endOf('day').toDate();
    searchMode: 'created' | 'startDate' | 'paymentDate' = 'startDate';
    displayMode: 'chart' | 'table' = 'table';
    loading: boolean = true;
    user: any;

    @ViewChild('salesReportTable')
        salesReportTable: any;

    @ViewChild('salesReportChart')
        salesReportChart: any;

    constructor(private api: Api, public currentUser: CurrentUser, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility, public currency: Currency, private dateAdapter: DateAdapter<any>) {
        this.globals.clientSettingsReceived.subscribe(() => {
            this.dateAdapter.setLocale(`${this.globals.clientSettings.language}-${this.globals.clientSettings.country.toUpperCase()}`);
            this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
        });
    }

    changeDisplayMode(displayMode) {
        this.displayMode = displayMode;
    }

    changeSearchMode(searchMode) {
        this.searchMode = searchMode;
    }

    print() {
        window.print();
    }


    updateReports() {
        console.log("reloading...");

        console.log(this.salesReportTable);
        

        if(this.displayMode == 'table') {
            this.salesReportTable.reload();
        }

        else if(this.displayMode == 'chart') {
            this.salesReportChart.reload();
        }

    }

    async dateChanged() {

    }

    async ngOnInit() {
        this.user = await this.currentUser.get();
    }

    async ngOnDestroy() {

    }
}
