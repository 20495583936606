export const getFullWeekDayFromShortString = (shortString: string) => {
    switch (shortString) {
    case 'MON':
        return 'MONDAY';
    case 'TUE':
        return 'TUESDAY';
    case 'WED':
        return 'WEDNESDAY';
    case 'THU':
        return 'THURSDAY';
    case 'FRI':
        return 'FRIDAY';
    case 'SAT':
        return 'SATURDAY';
    case 'SUN':
        return 'SUNDAY';
    }
};