import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Api } from '../../services/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Globals } from '../../services/globals';
import { MatTableModule, MatTable } from '@angular/material/table';
import { IBookingForm } from '../../interfaces/booking-form';
import { IPackage } from '../../interfaces/package';
import { IAddOn } from '../../interfaces/add-on';
import { IEvent } from '../../../../../../../../../common/common-interfaces/event';
import { orderBy } from '../../helpers/utility';
import { CurrentUser } from '../../services/user';
import { IQRCodePoster } from '../../../../../../../../../common/common-interfaces/qr-code';
import { PaymentProviderName } from '../../../../../../../../../common/common-interfaces/payment-info';
import { Utility } from '../../services/utility';
import { FormControl } from '@angular/forms';
import { C } from '@angular/cdk/keycodes';

@Component({
    selector: 'booking-form-administration',
    templateUrl: './booking-form-administration.component.html'
})

export class BookingFormAdministrationComponent implements OnInit, OnDestroy {

    bookingForm: IBookingForm | null = null;
    availablePackages: IPackage[];
    availableAddOns: IAddOn[];
    availableCustomerGroups: any[];
    availableEvents: IEvent[];
    availablePaymentProviders: PaymentProviderName[] = [];


    displayedPackageColumns = ['order', 'name', 'remove'];
    displayedCustomerGroupColumns = ['order', 'name', 'preSelected', 'remove'];
    displayedEventColumns = ['order', 'name', 'remove'];

    private paramsSubscription?: Subscription;
  @ViewChild('associatedPackages', { static: false }) associatedPackagesTable: MatTable<any>;
  @ViewChild('customerGroups', { static: false }) customerGroupsTable: MatTable<any>;
  @ViewChild('associatedEvents', { static: false }) associatedEventsTable: MatTable<any>;
  constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private currentUser: CurrentUser, public utility: Utility) {

  }

  async addPackage(_package) {
      if (!this.bookingForm.associatedPackages.some(p => p.packageId == _package.id)) {
          this.bookingForm.associatedPackages.push({
              packageId: _package.id,
              name: _package.name
          });
      }

      this.associatedPackagesTable.renderRows();
  }

  async removePackage(_package) {
      this.bookingForm.associatedPackages.splice(this.bookingForm.associatedPackages.indexOf(_package), 1);
      this.associatedPackagesTable.renderRows();
  }

  movePackageUp(_package) {
      let index = this.bookingForm.associatedPackages.indexOf(_package);
      if (index > 0) {
          this.bookingForm.associatedPackages.splice(index - 1, 0, this.bookingForm.associatedPackages.splice(index, 1)[0]);
          this.associatedPackagesTable.renderRows();
      }
  }

  movePackageDown(_package) {
      let index = this.bookingForm.associatedPackages.indexOf(_package);
      if (index < this.bookingForm.associatedPackages.length - 1) {
          this.bookingForm.associatedPackages.splice(index + 1, 0, this.bookingForm.associatedPackages.splice(index, 1)[0]);
          this.associatedPackagesTable.renderRows();
      }
  }



  async addCustomerGroup(customerGroup) {
      this.bookingForm.customerGroups.push({
          customerGroupId: customerGroup.id,
          name: customerGroup.name,
          preSelectedPeople: 0
      });

      this.customerGroupsTable.renderRows();
  }

  async removeCustomerGroup(customerGroup) {
      this.bookingForm.customerGroups.splice(this.bookingForm.customerGroups.indexOf(customerGroup), 1);
      this.customerGroupsTable.renderRows();
  }

  moveCustomerGroupUp(customerGroup) {
      let index = this.bookingForm.customerGroups.indexOf(customerGroup);
      if (index > 0) {
          this.bookingForm.customerGroups.splice(index - 1, 0, this.bookingForm.customerGroups.splice(index, 1)[0]);
          this.customerGroupsTable.renderRows();
      }
  }

  moveCustomerGroupDown(customerGroup) {
      let index = this.bookingForm.customerGroups.indexOf(customerGroup);
      if (index < this.bookingForm.customerGroups.length - 1) {
          this.bookingForm.customerGroups.splice(index + 1, 0, this.bookingForm.customerGroups.splice(index, 1)[0]);
          this.customerGroupsTable.renderRows();
      }
  }



  async addEvent(event) {
      if (!this.bookingForm.associatedEvents.some(e => e.eventId == event.id)) {
          this.bookingForm.associatedEvents.push({
              eventId: event.id,
              name: event.name
          });
      }

      this.associatedEventsTable.renderRows();
  }

  async removeEvent(event) {
      this.bookingForm.associatedEvents.splice(this.bookingForm.associatedEvents.indexOf(event), 1);
      this.associatedEventsTable.renderRows();
  }

  moveEventUp(event) {
      let index = this.bookingForm.associatedEvents.indexOf(event);
      if (index > 0) {
          this.bookingForm.associatedEvents.splice(index - 1, 0, this.bookingForm.associatedEvents.splice(index, 1)[0]);
          this.associatedEventsTable.renderRows();
      }
  }

  moveEventDown(event) {
      let index = this.bookingForm.associatedEvents.indexOf(event);
      if (index < this.bookingForm.associatedEvents.length - 1) {
          this.bookingForm.associatedEvents.splice(index + 1, 0, this.bookingForm.associatedEvents.splice(index, 1)[0]);
          this.associatedEventsTable.renderRows();
      }
  }



  async save() {
      // Check if booking form should be created or updated
      if (this.bookingForm && this.bookingForm.id) {
          let result = await this.api.client().patch<any>(`/manage/booking-forms/${this.bookingForm.id}`, this.bookingForm);
      }
      else {
          let result = await this.api.client().put<any>(`/manage/booking-forms`, this.bookingForm);
          if (result.succeeded) {
              this.router.navigateByUrl(`/${this.globals.clientId}/booking-forms/${result.bookingForm.id}`);
          }
      }
  }

  async ngOnInit() {
      this.paramsSubscription = this.route.params.subscribe(async (params) => {
          if (params['id']) {
              this.bookingForm = await this.api.client().get<IBookingForm>(`/manage/booking-forms/${params['id']}`);


              if (!this.bookingForm.preSelectedPeople && this.bookingForm.preSelectedPeople != 0) {
                  this.bookingForm.usePreSelectedPeople = false;
                  this.bookingForm.preSelectedPeople = 0;
              }

              if(!this.bookingForm.showFullBookedSlots){
                  this.bookingForm.showFullBookedSlots = false;
              }

              if (!this.bookingForm.design) {
                  this.bookingForm.design = 'NEW';
              }

              if (!this.bookingForm.associatedPackages) {
                  this.bookingForm.associatedPackages = [];
              }

              if (!this.bookingForm.associatedEvents) {
                  this.bookingForm.associatedEvents = [];
              }

              if (!this.bookingForm.texts) {
                  this.bookingForm.texts = {};
              }

              if (!this.bookingForm.customerGroups) {
                  this.bookingForm.customerGroups = [];
              }

              if (!this.bookingForm.eventViewType) {
                  this.bookingForm.eventViewType = 'CARD';
              }

              if (!this.bookingForm.strictMonths){
                this.bookingForm.strictMonths = false;
              }
              if (!this.bookingForm.eventViewTypeSettings) {
                  this.bookingForm.eventViewTypeSettings = {};
              }

              if (!this.bookingForm.eventViewTypeSettings.monthlyCalendar) {
                  this.bookingForm.eventViewTypeSettings.monthlyCalendar = {
                      showAllEventDates: false
                  };
              }

              if (!this.bookingForm.designSettings) {
                  this.bookingForm.designSettings = {
                      dropIn: {
                          viewAddOns: false
                      }
                  };
              }

              if (!this.bookingForm.shortUrl) {
                  let result = await this.api.client().post<any>(`/manage/booking-forms/${this.bookingForm.id}/shorten-url`, {});
                  if (result.succeeded) {
                      this.bookingForm.shortUrl = result.shortUrl;
                  }
              }

              this.availableCustomerGroups = await this.api.client().get<any[]>(`/manage/customer-groups`);
              for (let customerGroup of this.availableCustomerGroups) {
                  let cg = this.bookingForm.customerGroups.find(cg => cg.customerGroupId == customerGroup.id);
                  if (cg)
                      cg.name = customerGroup.name;
              }

              let packages = await this.api.client().get<IPackage[]>(`/manage/packages`);
              this.availablePackages = packages.filter(p => !this.bookingForm.associatedPackages.some(ap => ap.packageId == p.id));
              for (let _package of packages) {
                  let p = this.bookingForm.associatedPackages.find(p => p.packageId == _package.id);
                  console.log(p);
                  if (p)
                      p.name = _package.name;
              }

              this.availableAddOns = await this.api.client().get<IAddOn[]>(`/manage/add-ons`);
              this.availableEvents = (await this.api.client().get<IEvent[]>('/manage/events/available')).filter(e => !this.bookingForm.associatedEvents.some(ae => ae.eventId == e.id));
              this.availableEvents.sort(orderBy('name').asc);

              if (this.globals.clientSettings.paymentSettings) {
                  if (this.globals.clientSettings.paymentSettings.paymentProvider)
                      this.availablePaymentProviders.push(this.globals.clientSettings.paymentSettings.paymentProvider);
                  if (this.globals.clientSettings.paymentSettings.secondaryPaymentProvider)
                      this.availablePaymentProviders.push(this.globals.clientSettings.paymentSettings.secondaryPaymentProvider);
                  if (this.globals.clientSettings.paymentSettings.tertiaryPaymentProvider)
                      this.availablePaymentProviders.push(this.globals.clientSettings.paymentSettings.tertiaryPaymentProvider);
              }
          }
          else {
              this.bookingForm = {
                  associatedPackages: [],
                  associatedEvents: [],
                  addOnViewMode: 'ADD_ONS',
                  hideUnavailablePackages: false,
                  texts: {},
                  useCustomerGroups: false,
                  useExclusiveCustomerGroups: false,
                  hidePersonChooser: false,
                  showPackagePrice: true,
                  alwaysViewPackageInfo: false,
                  customerGroups: [],
                  designSettings: {
                      dropIn: {
                          viewAddOns: false,
                      }
                  },

                  usePreSelectedPeople: false,
                  preSelectedPeople: 0,
                  showFullBookedSlots: false,
                  strictMonths: false,
              };
          }
      });

  }

  async posterCreated(poster: IQRCodePoster) {
      this.bookingForm.qrCodePosterId = poster.id;
      await this.save();
  }

  async ngOnDestroy() {
      if (this.paramsSubscription)
          this.paramsSubscription.unsubscribe();
  }
}
