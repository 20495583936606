import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Api } from 'projects/client-app/src/app/services/api';
import { Currency } from 'projects/client-app/src/app/services/currency';
import { Globals } from 'projects/client-app/src/app/services/globals';
import { Utility } from 'projects/client-app/src/app/services/utility';
import { environment } from 'projects/client-app/src/environments/environment';
import { Subscription } from 'rxjs';




@Component({
    selector: 'stripe-invoice-payment',
    templateUrl: './stripe-invoice-payment.component.html',
    styleUrls: ['./stripe-invoice-payment.component.scss']
})

export class StripeInvoicePaymentComponent implements OnInit {
    stripe: any;
    @Input()
        invoice: any;
    constructor(private api: Api, private router: Router, public globals: Globals, private route: ActivatedRoute, public currency: Currency, public utility: Utility) {

    }


    async initialize() {
        this.stripe = (window as any).Stripe(environment.stripePublicKey);

        let session = await this.api.client().get<any>(`/manage/invoices/${this.invoice.id}/third-party/stripe/session`);
        let result = this.stripe.redirectToCheckout({
            sessionId: session.sessionId
        });
    }

    async ngOnInit() {
        var script = document.createElement('script');
        script.onload = () => {
            this.initialize();
        };

        script.src = 'https://js.stripe.com/v3/';

        document.head.appendChild(script);
    }

    async ngOnDestroy() {

    }

}
