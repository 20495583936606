import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { Globals } from '../../../../services/globals';
import { Api } from '../../../../services/api';
import { Utility } from '../../../../services/utility';
import { IActivity } from '../../../../interfaces/activity';
import { ISelectedExtraActivity } from '../../../../interfaces/quick-booking-form-state';
import { ExtraActivityHelper } from 'src/app/services/extra-activity-helper';
import { Currency } from '../../../../services/currency';


@Component({
    selector: 'quick-booking-extra-activities',
    templateUrl: './quick-booking-extra-activities.component.html',
    styleUrls: ['./quick-booking-extra-activities.component.scss']
})

export class QuickBookingExtraActivitiesComponent implements OnInit, OnChanges {


    @Input()
        activitiesTimeslots: any;
    @Input()
        selectedPackage: any;
    @Input()
        selectedTime: string;
    @Input()
        selectedDate: string;
    @Input()
        selectedPersons: number;
    @Input()
        selectedExtraActivities: ISelectedExtraActivity[];
    @Input()
        selectedCustomerGroups: { [customerGroupId: string]: { name: string, quantity: number, customerGroupId: string } };
    @Input()
        selectedCustomerGroupId: string;
    constructor(private api: Api, private globals: Globals, private utility: Utility, private translate: TranslateService, private dialog: MatDialog, private currency: Currency, private extraActivityHelper: ExtraActivityHelper) {

    }

    addExtraActivity(extraActivity, time, type) {
        console.log(`${extraActivity.name} ${time}`);
        this.selectedExtraActivities.push({
            name: extraActivity.name,
            slots: extraActivity.slots,
            activityId: extraActivity.activityId,
            time: time,
            type: type
        });
    }

    isAvailable(extraActivity, when) {
        if (extraActivity.availableSlots
            && extraActivity.availableSlots[when]) {

            if (extraActivity.availableSlots[when].hasBookings) {
                if (extraActivity.availableSlots[when].customerGroupIds) {
                    // As of now, only allow packages with customer group lock enabled
                    // Actually, no
                    /*
                    if (!this.selectedPackage.lockToCustomerGroup
                        || !this.selectedPackage.lockExtraActivitiesToCustomerGroup)
                        return false;
                        */
                    return extraActivity.availableSlots[when].customerGroupIds[this.selectedCustomerGroupId];
                }
                else {
                    //return false;
                }
            }

            return this.selectedPersons >= extraActivity.availableSlots[when].minPeoplePerBooking
                && this.selectedPersons <= extraActivity.availableSlots[when].maxPeoplePerBooking;
        }
        /*
        return extraActivity.availableSlots[when]
            && this.selectedPersons >= extraActivity.availableSlots[when].minPeoplePerBooking
            && this.selectedPersons <= extraActivity.availableSlots[when].maxPeoplePerBooking
    */
    }

    removeExtraActivity(extraActivity) {
        let a = this.selectedExtraActivities.find(a => a.activityId == extraActivity.activityId);
        this.selectedExtraActivities.splice(this.selectedExtraActivities.indexOf(a), 1);
    }

    getActivityPrice(activity: IActivity, extraActivity) {
        if (activity.priceType == 'PER_PERSON') {
            return (activity.price.withVat * extraActivity.slots) * this.selectedPersons;
        }
        else {
            return (activity.price.withVat * extraActivity.slots);
        }
    }

    getSelectedExtraActivityTime(extraActivity) {
        let selectedActivity = this.selectedExtraActivities.find(a => a.activityId == extraActivity.activityId);
        if (selectedActivity)
            return selectedActivity.time;
    }

    getExtraActivityTimeslots(extraActivity) {
        return this.extraActivityHelper.getExtraActivityTimeslots(extraActivity,
            this.activitiesTimeslots,
            this.selectedPackage,
            this.selectedTime,
            this.selectedDate,
            this.selectedPersons);
    }


    public updateExtraActivityTimeslots(): void {
        for (let extraActivity of this.selectedPackage.extraActivities) {
            extraActivity.availableSlots = this.getExtraActivityTimeslots(extraActivity);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        for (let extraActivity of this.selectedPackage.extraActivities) {
            extraActivity.availableSlots = this.getExtraActivityTimeslots(extraActivity);
        }
    }

    async ngOnInit() {
        for (let extraActivity of this.selectedPackage.extraActivities) {
            extraActivity.availableSlots = this.getExtraActivityTimeslots(extraActivity);
        }
    }
}
