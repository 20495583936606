import { Component, OnInit, EventEmitter, ViewChild, Inject, Input } from '@angular/core';
import { Utility } from '../../../services/utility';
import { DomSanitizer } from '@angular/platform-browser';
import { IEventBooking } from '../../../../../../../../../../common/common-interfaces/event-booking';
import { Globals } from '../../../services/globals';


@Component({
    selector: 'booking-search-dialog-event-booking',
    templateUrl: './booking-search-dialog-event-booking.component.html',
    styleUrls: ['./booking-search-dialog-event-booking.component.scss']
})

export class BookingSearchDialogEventBookingComponent implements OnInit {
    @Input()
    eventBooking: IEventBooking;
    constructor(private utility: Utility, private sanitizer: DomSanitizer, public globals: Globals) {

    }

    getIconHtml() {
        return this.sanitizer.bypassSecurityTrustHtml(this.utility.getBookingIconsHtml({ eventBooking: this.eventBooking }));
    }

    async ngAfterViewInit() {

    }



    async ngOnInit() {

    }
}
