import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Globals } from './globals';

@Injectable()
export class Api {
    constructor(private http: HttpClient, private globals: Globals) {

    }

    public requester = (baseUrl: string = '') => {
        let http = this.http;
        return {
            get: function <T>(url: string) {
                return http.get(`${baseUrl}${url}`, {
                    withCredentials: true
                }).toPromise() as Promise<T>;
            },
            post: function <T>(url: string, data: any) {
                return http.post(`${baseUrl}${url}`, data, {
                    withCredentials: true
                }).toPromise() as Promise<T>;
            },
            put: function <T>(url: string, data: any) {
                return http.put(`${baseUrl}${url}`, data, {
                    withCredentials: true
                }).toPromise() as Promise<T>;
            },
            patch: function <T>(url: string, data: any) {
                return http.patch(`${baseUrl}${url}`, data, {
                    withCredentials: true
                }).toPromise() as Promise<T>;
            },
            delete: function <T>(url: string) {
                return http.delete(`${baseUrl}${url}`, {
                    withCredentials: true
                }).toPromise() as Promise<T>;
            }
        };
    };

    private baseUrlRequester = (baseUrl: string) => {
        return this.requester(`${environment.baseUrl}${baseUrl}`);
    };

    public base = () => {
        return this.baseUrlRequester('/api');
    };

    public clientUrl = (url = '') => {
        return `/api/client/${this.globals.clientId}${url}`;
    };

    public publicClientUrl = (url = '') => {
        return `/api/public/clients/${this.globals.clientId}${url}`;
    };

    public client = () => {
        return this.baseUrlRequester(this.clientUrl());
    };

    public publicClient = () => {
        return this.baseUrlRequester(this.publicClientUrl());
    };


}
