import { Component, OnInit, EventEmitter, ViewChild, Inject, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Api } from '../../../services/api';
import * as  moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { DCCObject, getVaccineText } from '../../../../../../../../../../common/common-interfaces/hcert';


@Component({
    selector: 'hcert-info-dialog',
    templateUrl: './hcert-info-dialog.component.html',
    styleUrls: ['./hcert-info-dialog.component.scss']
})

export class HcertInfoDialogComponent implements OnInit {
    qrData: string;
    dccData: DCCObject;

    covidVaccineResult: {
        date: string,
        doses: number,
        dosesRequired: number,
        issuer: string,
        productName: string
    };

    covidTestResult: {
        date: string,
        issuer: string,
        tester: string
    };

    covidRecoveryResult: {
        validFrom: string,
        validTo: string,
        issuer: string
    };

    invalid: boolean = false;

    identityNumber: string;
    scannedIdentityNumber: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<HcertInfoDialogComponent>, private api: Api, translate: TranslateService) {
        if (data) {
            this.qrData = data.qrData;
        }

        this.dialogRef.backdropClick().subscribe(async () => {
            await this.close();
        });
    }

    idCardScanned(text: string) {
        console.log('ID Card scanned:');
        console.log(text);

        this.scannedIdentityNumber = this.getIdentityNumber(text);


        // Identity is matching
        if (this.identityNumber == this.scannedIdentityNumber) {
            if (this.data.releaseQRScanHijack) {
                this.data.releaseQRScanHijack();
            }
        }
    }

    getIdentityNumber(text: string) {
        if (!text)
            return '-';
        while (text.includes('-'))
            text = text.replace('-', '');

        if (text.length == 12)
            text = text.substr(2);

        if (text.length == 10)
            text = text.substr(0, 6);

        if (text.length == 8)
            text = text.substr(2);
        return text;
    }

    async ngOnInit() {
        if (this.qrData) {
            this.dccData = await this.api.client().post<any>(`/hcert/decode`, { data: this.qrData });

            if (this.dccData) {
                this.identityNumber = this.getIdentityNumber(this.dccData.dob);
                if (this.dccData.v) {
                    for (let v of this.dccData.v) {
                        switch (v.tg) {
                        // COVID-19
                        case '840539006':
                            this.covidVaccineResult = {
                                date: v.dt,
                                doses: v.dn,
                                dosesRequired: v.sd,
                                issuer: v.is,
                                productName: getVaccineText(v.mp)
                            };
                            break;
                        }
                    }
                }

                if (this.dccData.t) {
                    for (let t of this.dccData.t) {
                        switch (t.tg) {
                        // COVID-19
                        case '840539006':
                            this.covidTestResult = {
                                date: moment(t.sc).format('YYYY-MM-DD HH:mm'),
                                issuer: t.is,
                                tester: t.tc
                            };
                            break;
                        }
                    }
                }


                if (this.dccData.r) {
                    for (let r of this.dccData.r) {
                        switch (r.tg) {
                        // COVID-19
                        case '840539006':
                            this.covidRecoveryResult = {
                                validFrom: moment(r.df).format('YYYY-MM-DD HH:mm'),
                                validTo: moment(r.du).format('YYYY-MM-DD HH:mm'),
                                issuer: r.is
                            };
                            break;
                        }
                    }
                }

                if (!this.covidTestResult && !this.covidVaccineResult && !this.covidRecoveryResult) {
                    this.invalid = true;
                }
            } else {
                this.invalid = true;
            }
        }

        if (this.data.hijackQRScan) {
            console.log('Hijacking QR scan');
            this.data.hijackQRScan((text) => this.idCardScanned(text));
        }
    }

    close() {
        if (this.data.releaseQRScanHijack) {
            this.data.releaseQRScanHijack();
        }
        this.dialogRef.close();
    }

    async ngAfterViewInit() {

    }

}
