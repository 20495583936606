import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';


@Component({
    selector: 'face-detector',
    templateUrl: './face-detector.component.html',
    styleUrls: ['./face-detector.component.scss']

})

export class FaceDetectorComponent implements OnInit {
    @ViewChild('inputVideo', { static: false })
        inputVideo: ElementRef<HTMLVideoElement>;
    faceApi = (window as any).faceapi;
    availableDescriptors = [];
    labelName: string;
    currentPerson: string;
    currentDescriptor;
    faceMatcher;
    constructor(private router: Router, private route: ActivatedRoute) {

    }

    loadDescriptors() {
        this.availableDescriptors = [];
        let faces = localStorage.getItem('faces');
        if (faces) {
            let facesArray = JSON.parse(faces);
            for (let face of facesArray) {
                console.log(face);
                this.availableDescriptors.push(
                    new this.faceApi.LabeledFaceDescriptors(
                        face.label,
                        [new Float32Array(face.descriptor)]
                    )
                );
            }
        }
        this.faceMatcher = new this.faceApi.FaceMatcher(this.availableDescriptors);
    }

    async saveDescriptor() {
        let facesArray = [];
        let faces = localStorage.getItem('faces');
        if (faces)
            facesArray = JSON.parse(faces);

        facesArray.push({
            label: this.labelName,
            descriptor: Object.values(this.currentDescriptor)
        });
        localStorage.setItem('faces', JSON.stringify(facesArray));
        this.loadDescriptors();
    }


    async ngOnInit() {
        await this.faceApi.nets.ssdMobilenetv1.loadFromUri('/apps/public-app/client-app/assets/models');
        await this.faceApi.nets.faceLandmark68Net.loadFromUri('/apps/public-app/client-app/assets/models');
        await this.faceApi.nets.faceRecognitionNet.loadFromUri('/apps/public-app/client-app/assets/models')
        // await this.faceApi.loadFaceRecognitionModel('/apps/public-app/client-app/assets/models');
        (navigator as any).getUserMedia(
            { video: {} },
            stream => this.inputVideo.nativeElement.srcObject = stream,
            err => console.error(err)
        );


        setInterval(async () => {
            let result = await this.faceApi.detectSingleFace(this.inputVideo.nativeElement).withFaceLandmarks().withFaceDescriptor();
            if (result && result.descriptor) {
                this.currentDescriptor = result.descriptor;
                let bestMatch = this.faceMatcher.findBestMatch(this.currentDescriptor);
                console.log(bestMatch);
                this.currentPerson = bestMatch.toString();
            }

            console.log(result);
        }, 1000);

        this.loadDescriptors();
    }
}
