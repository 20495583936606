// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icons {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/calendar/booking-icons/booking-icons.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/calendar/booking-icons/booking-icons.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;ACCJ","sourcesContent":[".icons{\r\n    display: flex;\r\n    align-items: center;\r\n}",".icons {\n  display: flex;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
