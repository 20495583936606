import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, inject } from "@angular/core";
import { Api } from "../../services/api";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { Globals } from "../../services/globals";
import { IAddOn } from "../../interfaces/add-on";
import { IPackage } from "../../interfaces/package";
import { MatSnackBar, MatSnackBarModule } from "@angular/material/snack-bar";
import { v4 as uuidv4 } from "uuid";
import { add } from "lodash";


@Component({
    selector: "add-on-administration",
    templateUrl: "./add-on-administration.component.html",
    styleUrls: ["./add-on-administration.component.scss"]
})
export class AddOnAdministrationComponent implements OnInit, OnDestroy {
    addOn: IAddOn | null = null;
    availablePackages: IPackage[];
    associatedPackageIds: { [packageId: string]: boolean } = {};
    private paramsSubscription?: Subscription;
    private _snackBar = inject(MatSnackBar);
    @ViewChild("tabGroup", { static: false }) tabGroup;
    constructor(
        private api: Api,
        private router: Router,
        private route: ActivatedRoute,
        private globals: Globals
    ) { }

    async imageChanged(image) {
        this.addOn.image = image;
    }

    async save() {
        // Check if addOn should be created or updated
        if (this.addOn && this.addOn.id) {
            let result = await this.api.client().patch<any>(`/manage/add-ons/${this.addOn.id}`, {
                associatedPackageIds: this.associatedPackageIds,
                addOn: this.addOn
            });
        } else {
            let result = await this.api
                .client()
                .put<any>(`/manage/add-ons`, { associatedPackageIds: this.associatedPackageIds, addOn: this.addOn });
            if (result.succeeded) {
                this.router.navigateByUrl(`/${this.globals.clientId}/add-ons/${result.addOn.id}`);
            }
        }

        this._snackBar.open("Saved", "Ok", { duration: 1000 });
    }

    async ngOnInit() {
        this.paramsSubscription = this.route.params.subscribe(async (params) => {
            if (params["id"]) {
                this.addOn = await this.api.client().get<IAddOn>(`/manage/add-ons/${params["id"]}`);

                this.availablePackages = await this.api.client().get<IPackage[]>("/manage/packages");
                for (let _package of this.availablePackages) {
                    this.associatedPackageIds[_package.id] =
                        _package.enabledAddOnIds && _package.enabledAddOnIds[this.addOn.id];
                }

                if (!this.addOn.summary) this.addOn.summary = "";

                if (!this.addOn.price) {
                    this.addOn.price = {
                        withVat: 0,
                        withoutVat: 0,
                        vat: 0,
                        vatPercentage: 0
                    };
                }

                if (this.addOn && !this.addOn.campaignLabel) {
                    console.log("campaignLabel is undefined, adding label");
                    const baseLabel = { text: "", foregroundColor: "#ffffff", backgroundColor: "#5eaf39" };
                    this.addOn.campaignLabel = baseLabel;
                    console.log("campaignLabel added", this.addOn.campaignLabel);
                }

                if (!this.addOn.customQuantity) this.addOn.customQuantity = 1;

                if (!this.addOn.minimumQuantity) this.addOn.minimumQuantity = 0;
            } else {
                this.addOn = {
                    price: {
                        withVat: 0,
                        withoutVat: 0,
                        vat: 0,
                        vatPercentage: 0
                    },
                    summary: "",
                    lockQuantity: false,
                    customQuantity: 1,
                    useCustomQuantity: false,
                    useMinimumQuantity: false,
                    minimumQuantity: 0,
                    campaignLabel: { text: "", foregroundColor: "#ffffff", backgroundColor: "#5eaf39" }
                };
            }
        });
    }

    async ngOnDestroy() {
        if (this.paramsSubscription) this.paramsSubscription.unsubscribe();
    }
}
