// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-list .reports .report a {
  color: var(--text-base);
  font-size: 24px;
}
.report-list .reports .report a mat-icon {
  vertical-align: sub;
  font-size: 28px;
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/reports/report-list.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/reports/report-list.component.scss"],"names":[],"mappings":"AAGM;EACE,uBAAA;EACA,eAAA;ACFR;ADIQ;EACI,mBAAA;EACA,eAAA;EACA,iBAAA;ACFZ","sourcesContent":[".report-list {\r\n  .reports {\r\n    .report {\r\n      a {\r\n        color: var(--text-base);\r\n        font-size: 24px;\r\n\r\n        mat-icon{\r\n            vertical-align: sub;\r\n            font-size: 28px;\r\n            margin-right: 5px;\r\n        }\r\n      }\r\n    }\r\n  }\r\n}\r\n",".report-list .reports .report a {\n  color: var(--text-base);\n  font-size: 24px;\n}\n.report-list .reports .report a mat-icon {\n  vertical-align: sub;\n  font-size: 28px;\n  margin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
