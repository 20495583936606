import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ICustomerFeedback } from '../../../../../interfaces/customer-feedback';
import { Api } from '../../../../../services/api';
import { Globals } from '../../../../../services/globals';
import { Utility } from '../../../../../services/utility';


@Component({
    selector: 'smiley-feedback-section-report',
    templateUrl: './smiley-feedback-section-report.component.html'
})

export class SmileyFeedbackSectionReportComponent implements OnInit, OnDestroy, OnChanges {
    @Input()
        feedbackSection: any;
    @Input()
        data: {
        customerFeedback: ICustomerFeedback,
        sectionData: any
    }[];
    pointsChartData: { date: Date, points: number }[] = null;
    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility, private translate: TranslateService) {

    }

    async updateData() {
        this.pointsChartData = [];
        for (let data of this.data) {
            this.pointsChartData.push({
                date: data.customerFeedback.created,
                points: data.sectionData
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateData();
    }

    async ngOnInit() {
        this.updateData();
    }

    async ngOnDestroy() {

    }
}
