import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Utility } from '../../../services/utility';
import { Globals } from '../../../services/globals';
import { Api } from '../../../services/api';
import { Currency } from '../../../services/currency';
import { CurrentUser } from '../../../services/user';
import { DateAdapter } from '@angular/material/core';
import { IZReport } from '../../../../../../../../../../common/common-interfaces/reports/z-report';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'z--report',
    templateUrl: './z-report.component.html',
    styleUrls: ['./z-report.component.scss']
})

export class ZReportComponent implements OnInit, OnDestroy {
    day: string = moment().format('YYYY-MM-DD');
    selectedDate: Date = moment().toDate();
    loading: boolean = true;
    user: any;
    zReport: IZReport;
    constructor(private api: Api, public currentUser: CurrentUser, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility, public currency: Currency, private dateAdapter: DateAdapter<any>, private translate: TranslateService) {
        this.globals.clientSettingsReceived.subscribe(() => {
            this.dateAdapter.setLocale(`${this.globals.clientSettings.language}-${this.globals.clientSettings.country.toUpperCase()}`);
            this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
        });

    }

    async dateChanged() {
        this.day = moment(this.selectedDate).format('YYYY-MM-DD');
        await this.fetchData();
    }


    print() {
        window.print();
    }

    async ngOnInit() {
        this.user = await this.currentUser.get();
        await this.fetchData();
    }

    async fetchData(){
        delete this.zReport;
        this.zReport = await this.api.client().get<any>(`/reports/z-report/${this.day}/data`);
    }

    getNotAccurateText(){
        return this.translate.instant('SALES_DEBT_NOT_ACCURATE').replace('#DATE', this.day);
    }

    async ngOnDestroy() {

    }
}
